
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../../../atoms/Input/Input.vue';
import Search from '../../../../../atoms/Search/Search.vue';
import Dropdown from '../../../../../atoms/Dropdown/Dropdown.vue';
import MultiSelectDropdown from '../../../../../molecules/Multi-SelectDropdown/MultiSelectDropdown.vue';
import TextArea from '../../../../../atoms/Text-Area/Text-Area.vue';
import Checkbox from '../../../../../atoms/Checkbox/Checkbox.vue';
import Button from '../../../../../atoms/Button/Button.vue';
import Tooltip from '../../../../../atoms/Tooltip/Tooltip.vue';
import Modal from '../../../../../atoms/Modal/Modal.vue';
import RecommendedImages from '../../../../../molecules/Recommended-Images/Recommended-Images.vue';
import DatePicker from '../../../../../molecules/DatePicker/DatePicker.vue';
import SingleSelectInputSuggestion from '../../../../../molecules/Single-Select-Input-Suggestion/Single-Select-Input-Suggestion.vue';
import LocationSelector from '../../../../../molecules/Locations-Selector/Location-Selector.vue';
import LanguageSelector from '../../../../../molecules/Language-Selector/Language-Selector.vue';
import Carousel from '../../../../../molecules/Carousel/Carousel.vue';
import ImageUploader from '../../../../../organisms/Image-Uploader/Image-Uploader.vue';
import { FormBuilder, FormControl } from '../../../../../../utilities/Forms';
import { Validators } from '../../../../../../utilities/Validators/Validators';
import { dateFormatterLocalized, timeFormatterLocalized } from '../../../../../../utilities/Functions/formatting';
import { IDropdownOption } from '../../../../../../utilities/Interfaces/form.interfaces';
import Loader from '../../../../../atoms/Loader/Loader.vue';
import datepicker from 'vue3-datepicker';
import FileUploader from '../../../../../molecules/File-Uploader/File-Uploader.vue';
import Chip from '../../../../../atoms/Chip/Chip.vue';
import Attachment from '../../../../../atoms/Attachment-Downloader/Attachment-Downloader.vue';
import MultiSelectInput from '@/atomic/molecules/Multi-SelectInput/MultiSelectInput.vue';
import { Role } from '@/enums/Role';
import { AbortHandler } from '@/services/AbortHandler';
import ItemSelector from '@/atomic/molecules/Item-Selector/Item-Selector.vue';
import ContextVariables from '@/atomic/molecules/Context-Variables/Context-Variables.vue';

export default defineComponent({
  emits: {
    hasChangesMade: Boolean,
  },
  components: {
    'arc-input': Input,
    'arc-search': Search,
    'arc-dropdown': Dropdown,
    'arc-multi-select-dropdown': MultiSelectDropdown,
    'arc-text-area': TextArea,
    'arc-checkbox': Checkbox,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
    'arc-recommended-images': RecommendedImages,
    'arc-image-uploader': ImageUploader,
    'arc-language-selector': LanguageSelector,
    'arc-date-picker': DatePicker,
    'arc-carousel': Carousel,
    'arc-location-selector': LocationSelector,
    'arc-loader': Loader,
    'arc-single-select-input-suggestion': SingleSelectInputSuggestion,
    'arc-file-uploader': FileUploader,
    'arc-chip': Chip,
    'arc-attachment': Attachment,
    datepicker,
    'arc-multi-select-input': MultiSelectInput,
    'arc-item-selector': ItemSelector,
    'arc-context-variables': ContextVariables,
  },

  computed: {
    ...mapGetters([
      'modalData',
      'user',
      'access',
      'permissions',
      'companyOptions',
      'userOptions',
      'brandOptions',
      'loadingBrands',
      'mallOptions',
      'loadingMalls',
      'contactTypes',
    ]),
    updateDisabled(): boolean {
      return this.status !== 'New';
    },
    selectCompanyPlaceholder(): string {
      return 'Select';
    },
    containerType(): string {
      if (this.isSysAdmin()) {
        return 'admin-container';
      } else if (this.isMallAdmin()) {
        return 'mall-admin-container';
      } else {
        return 'user-container';
      }
    },
    filteredBrands(): any {
      let brands = this.brandOptions;

      // filter search params
      if (this.searchFilter !== '') {
        let searchResults: any[] = [];
        brands.forEach((brand: any) => {
          if (brand.description.toLowerCase().includes(this.searchFilter.toLowerCase())) {
            searchResults.push(brand);
          }
        });
        brands = searchResults;
      }
      brands = brands?.sort((a: any, b: any) =>
        a.description.localeCompare(b.description, undefined, { numeric: true }),
      );
      return brands;
    },
    filteredBrandsAvailable(): any {
      return this.filteredBrands.filter((brand: any) => !this.selectedBrandIds?.includes(brand?.value));
    },
    filteredBrandsSelected(): any {
      return this.filteredBrands.filter((brand: any) => this.selectedBrandIds?.includes(brand?.value));
    },
    filteredMalls(): any {
      let malls = this.mallOptions;

      // filter search params
      if (this.searchFilter !== '') {
        let searchResults: any[] = [];
        malls.forEach((mall: any) => {
          if (mall.description.toLowerCase().includes(this.searchFilter.toLowerCase())) {
            searchResults.push(mall);
          }
        });
        malls = searchResults;
      }
      malls = malls?.sort((a: any, b: any) =>
        a.description.localeCompare(b.description, undefined, { numeric: true }),
      );
      return malls;
    },
    filteredMallsAvailable(): any {
      return this.filteredMalls.filter((mall: any) => !this.selectedMallIds?.includes(mall?.value));
    },
    filteredMallsSelected(): any {
      return this.filteredMalls.filter((mall: any) => this.selectedMallIds?.includes(mall?.value));
    },
  },

  watch: {
    companyOptions: {
      deep: true,
      handler() {
        this.companyOptions.forEach((item: IDropdownOption) => {
          if (
            this.formData?.controls?.company?.value
              ? this.formData?.controls?.company?.value === item?.value
              : this.modalData?.row?.companyId === item?.value
          ) {
            this.updateCompany(item);
          }
        });
      },
    },
    userOptions: {
      deep: true,
      handler() {
        this.userOptions.forEach((item: IDropdownOption) => {
          if (this.formData?.controls?.user?.value && this.formData?.controls?.user?.value === item?.value) {
            this.updateUser(item);
          }
        });
      },
    },
    contactTypes: {
      deep: true,
      handler() {
        this.formData.controls.contactTypes.value = this.contactTypes.filter((contactType: IDropdownOption) =>
          this.formData?.controls?.contactTypes?.value?.includes(contactType.value),
        );
      },
    },
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
  },

  async beforeUnmount(): Promise<void> {
    const controller = AbortHandler.getInstance();
    controller.voidControllers();

    this.fetchEmailCommunications();
  },

  async mounted(): Promise<void> {
    if (this.modalData?.emailCommunicationId) {
      this.emailCommunication = await this.fetchEmailCommunication({ emailCommunicationId: this.modalData?.emailCommunicationId });
      this.status = 'Edit';
      this.contextVariables = await this.getContextVariables({ context: 'Email Communication' });
      this.setForm();
      this.loaded = true;
    } else {
      this.status = 'New';
      this.contextVariables = await this.getContextVariables({ context: 'Email Communication' });
      this.setForm();
      this.loaded = true;
    }
    if (this.permissions?.superAdminAccess) {
      await this.loadUsers({ admins: true });
    }
  },
  data(): {
    status: string;
    changesMade: boolean;
    promptOpen: boolean;
    promptType: string;
    saving: boolean;
    companyValue: IDropdownOption | null;
    userValue: IDropdownOption | null;
    typeValue: IDropdownOption | null;
    selectedBrandIds: string[];
    selectedMallIds: string[];
    customSearchValue: IDropdownOption | null;
    customSearchOptions: IDropdownOption[] | [];
    searchLocationsFilter: string;
    searchFilter: string;
    formData: any;
    loaded: boolean;
    attemptedSubmit: boolean;
    initialLoad: boolean;
    typeOptions: IDropdownOption[];
    contextVariables: any[];
    emailCommunication: any;
  } {
    return {
      status: 'New',
      changesMade: false,
      promptOpen: false,
      promptType: 'save',
      saving: false,
      formData: null,
      loaded: false,
      companyValue: null,
      userValue: null,
      typeValue: null,
      selectedBrandIds: [],
      selectedMallIds: [],
      customSearchValue: null,
      customSearchOptions: [],
      searchLocationsFilter: '',
      searchFilter: '',
      attemptedSubmit: false,
      initialLoad: true,
      typeOptions: [
        { value: 'Brands', description: 'Brands' },
        { value: 'Malls', description: 'Malls' },
      ],
      contextVariables: [],
      emailCommunication: null,
    };
  },

  //
  // ███╗   ███╗███████╗████████╗██╗  ██╗ ██████╗ ██████╗ ███████╗
  // ████╗ ████║██╔════╝╚══██╔══╝██║  ██║██╔═══██╗██╔══██╗██╔════╝
  // ██╔████╔██║█████╗     ██║   ███████║██║   ██║██║  ██║███████╗
  // ██║╚██╔╝██║██╔══╝     ██║   ██╔══██║██║   ██║██║  ██║╚════██║
  // ██║ ╚═╝ ██║███████╗   ██║   ██║  ██║╚██████╔╝██████╔╝███████║
  // ╚═╝     ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝
  //
  methods: {
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateClosingModal',
      'loadCompanies',
      'loadUsers',
      'loadBrands',
      'updateModalData',
      'updateModalType',
      'openModal',
      'updateModalComponent',
      'fetchBrandById',
      'fetchEmailCommunications',
      'fetchEmailCommunication',
      'saveEmailCommunication',
      'loadMallOptions',
      'loadContactTypes',
      'getContextVariables',
    ]),

    //
    // ███████╗███████╗████████╗    ███████╗ ██████╗ ██████╗ ███╗   ███╗
    // ██╔════╝██╔════╝╚══██╔══╝    ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
    // ███████╗█████╗     ██║       █████╗  ██║   ██║██████╔╝██╔████╔██║
    // ╚════██║██╔══╝     ██║       ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
    // ███████║███████╗   ██║       ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
    // ╚══════╝╚══════╝   ╚═╝       ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
    //
    setForm(): void {
      // initializers
      let company = this.permissions?.superAdminAccess ? '' : this.user?.companyId;
      let user = this.permissions?.superAdminAccess ? '' : this.user?.userId;
      let type = this.permissions?.superAdminAccess ? '' : '';
      let subject = '';
      let body = '';
      let brandIds: string[] = [];
      let mallIds: string[] = [];
      let contactTypeIds: string[] = [];
      let createdBy = this.user?.userId;

      // set values if editing existing campaign
      if (this.emailCommunication) {
        company = this.emailCommunication?.companyId;
        user = this.emailCommunication?.fromUserId;
        type = this.emailCommunication?.brands.length > 0 ? 'Brands' : 'Malls';
        subject = this.emailCommunication?.subject;
        body = this.emailCommunication?.body;
        this.emailCommunication?.brands.forEach((brand: any) => {
          brandIds.push(brand.brandId);
        });
        this.selectedBrandIds = brandIds;
        this.emailCommunication?.malls.forEach((mall: any) => {
          mallIds.push(mall.mallId);
        });
        this.selectedMallIds = mallIds;
        this.emailCommunication?.contactTypes.forEach((contactType: any) => {
          contactTypeIds.push(contactType.contactTypeId);
        });
        createdBy = this.emailCommunication?.createdBy;
      }

      // set form based on user type
      this.formData = new FormBuilder({
        company: new FormControl(company),
        user: new FormControl(user),
        type: new FormControl(type),
        brands: new FormControl(brandIds),
        malls: new FormControl(mallIds),
        contactTypes: new FormControl(contactTypeIds),
        subject: new FormControl(subject, [Validators.required]),
        body: new FormControl(body, [Validators.required]),
      });

      setTimeout(async () => {
        if (this.isSysAdmin()) {
          this.loadCompanies();
          this.formData.controls.user.validators = [Validators.required];
          this.formData.controls.type.validators = [Validators.required];
        }
        if (this.isMallAdmin()) {
          await this.loadUsers({ userId: this.user.userId });
          this.typeOptions = [
            { value: 'Brands', description: 'Brands' },
          ];
        }
        if (this.isBrandAdminOrUser()) {
          await this.loadUsers({ userId: this.user.userId });
          this.typeOptions = [
            { value: 'Malls', description: 'Malls' },
          ];
        }
        if (this.emailCommunication) {
          this.updateType({ value: type, description: type });
        }
      }, 0);

      setTimeout(() => {
        this.initialLoad = false;
      }, 3000);
    },
    async updateCompany(value: IDropdownOption, clicked?: boolean) {
      this.companyValue = value;
      this.formData.controls.company.value = this.companyValue.value;
      await this.loadUsers({ companyId: value.value });
      if (clicked) {
        this.setChangesMade('UPDATE COMPANY');
        this.userValue = null;
        this.formData.controls.user.value = '';
        this.validateControl('company');
        if (this.typeValue?.value === 'Brands') {
          await this.loadBrands({ companyId: value.value });
        } else if (this.typeValue?.value === 'Malls') {
          this.loadMallOptions({ companyId: this.formData.controls.company.value });
        }
      }
    },
    clearCompany(value: any) {
      if (value === '') {
        this.companyValue = null;
        this.formData.controls.company.value = null;
        this.setChangesMade('CLEAR COMPANY');
        this.validateControl('company');
      }
    },
    updateUser(value: IDropdownOption, fromForm = false) {
      this.userValue = value;
      this.formData.controls.user.value = this.userValue.value;
      if (fromForm) {
        this.setChangesMade('USER');
        this.validateControl('user');
      }
    },
    async updateType(value: IDropdownOption, fromForm = false) {
      this.typeValue = value;
      this.formData.controls.type.value = this.typeValue.value;
      if (this.typeValue?.value === 'Brands') {
        if (this.permissions?.superAdminAccess && !this.formData.controls.company.value) {
          await this.loadBrands();
        } else {
          await this.loadBrands({ companyId: this.formData.controls.company.value });
        }
      } else if (this.typeValue?.value === 'Malls') {
        if (this.permissions?.superAdminAccess && !this.formData.controls.company.value) {
          await this.loadMallOptions();
        } else {
          await this.loadMallOptions({ companyId: this.formData.controls.company.value });
        }
        await this.loadContactTypes('all');
      }
      if (fromForm) {
        this.formData.controls.body.value = '';
        this.setChangesMade('USER');
        this.validateControl('user');
      }
    },
    setChangesMade(type?: string) {
      this.changesMade = true;
    },
    updateSearchLocationsFilter(value: string) {
      this.searchFilter = value;
    },
    validateControl(controlName: string): void {
      this.setChangesMade('VALIDATE CONTROL: ' + controlName);
    },
    closePrompt(): void {
      this.promptOpen = false;
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
    },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    async save(): Promise<void> {
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors) {
        const payload = {
          companyId: this.formData.controls.company.value ?? '',
          fromUserId: this.formData.controls.user.value,
          brandIds: this.formData.controls.brands.value.map((brand: any) => brand.value),
          mallIds: this.formData.controls.malls.value.map((mall: any) => mall.value),
          contactTypeIds: this.formData.controls.contactTypes.value.map((contactType: any) => contactType.value),
          subject: this.formData.controls.subject.value,
          body: this.formData.controls.body.value,
          createdBy: this.user.userId,
        };

        this.saving = true;
        try {
          this.attemptedSubmit = true;

          await this.saveEmailCommunication(payload);

          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: `Email Communication saved successfully!`,
            type: 'success',
            duration: 5000,
          });
          this.changesMade = false;
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        } catch (err) {
          this.saving = false;
          this.$notify({
            title: 'ERROR',
            text: `Unable to save Email Communication!`,
            type: 'error',
            duration: 5000,
          });
        }
      }
    },
    async submitPrompt(): Promise<void> {
      this.formData.markAllAsTouched();
      this.validateControl('headline');
      this.validateControl('description');
      this.validateControl('twitterDesc');
      this.validateControl('instagramDesc');
      this.validateControl('facebookDesc');
      this.attemptedSubmit = true;
      if (!this.formData.hasErrors) {
        this.openPrompt('submit');
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Email Communication form is incomplete or invalid!',
          type: 'warn',
          duration: 11000,
        });
      }
    },
    isSysAdmin(): boolean {
      return this.permissions?.superAdminAccess;
    },
    isMallAdmin(): boolean {
      return this.user.role?.roleId === Role.MallAdmin;
    },
    isBrandAdminOrUser(): boolean {
      return this.user.role?.roleId === Role.BrandAdmin || this.user.role?.roleId === Role.BrandUser;
    },
    updateSearchMallsFilter(value: string) {
      this.searchFilter = value;
    },
    mallInfo(mall: any): string {
      return `${mall.description}`;
    },
    mallAdded(value: any): void {
      this.formData.controls.malls.value.push(value);
      this.selectedMallIds.push(value.value);
      this.formData.controls.malls.value.sort((a: any, b: any) =>
        a.description.toString().localeCompare(b.description.toString(), undefined, { numeric: true }),
      );
      this.setChangesMade('MALL ADDED');
    },
    mallRemoved(value: any): void {
      const index = this.formData.controls.malls.value.findIndex((item: any) => item.value === value.value);
      this.formData.controls.malls.value.splice(index, 1);
      const idx = this.selectedMallIds.indexOf(value.value || value.mallId);
      if (idx > -1) {
        this.selectedMallIds.splice(idx, 1);
      }
      this.setChangesMade('MALL REMOVED');
    },
    addAllMalls(): void {
      this.filteredMallsAvailable.forEach((mall: any) => {
        this.mallAdded(mall);
      });
    },
    removeAllMalls(): void {
      this.filteredMallsSelected.forEach((mall: any) => {
        this.mallRemoved(mall);
      });
    },
    updateSearchBrandsFilter(value: string) {
      this.searchFilter = value;
    },
    brandInfo(brand: any): string {
      return `${brand.description}`;
    },
    brandAdded(value: any): void {
      this.formData.controls.brands.value.push(value);
      this.selectedBrandIds.push(value.value);
      this.formData.controls.brands.value.sort((a: any, b: any) =>
        a.description.toString().localeCompare(b.description.toString(), undefined, { numeric: true }),
      );
      this.setChangesMade('MALL ADDED');
    },
    brandRemoved(value: any): void {
      const index = this.formData.controls.brands.value.findIndex((item: any) => item.value === value.value);
      this.formData.controls.brands.value.splice(index, 1);
      const idx = this.selectedBrandIds.indexOf(value.value || value.brandId);
      if (idx > -1) {
        this.selectedBrandIds.splice(idx, 1);
      }
      this.setChangesMade('MALL REMOVED');
    },
    addAllBrands(): void {
      this.filteredBrandsAvailable.forEach((brand: any) => {
        this.brandAdded(brand);
      });
    },
    removeAllBrands(): void {
      this.filteredBrandsSelected.forEach((brand: any) => {
        this.brandRemoved(brand);
      });
    },
    contactTypesUpdated(value: any): void {
      this.formData.controls.contactTypes.value = value;
      this.setChangesMade();
    },
    mapContextVariables(typeValue: string): string[] {
      return this.contextVariables?.find((el: any) => el.subContext === typeValue)?.variables.split(',');
    },
    updateHtml(input: any): void {
      this.formData.controls.body.value = input.target.innerHTML;
    },
  },
});
