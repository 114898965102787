
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';

import Modal from './atomic/atoms/Modal/Modal.vue';
import SelectCreateModal from './atomic/organisms/Modals/Select/Select.vue';
import MarketingCampaignModal from './atomic/organisms/Modals/Communications/Campaigns/Marketing/Marketing.vue';
import RecruitmentCampaignModal from './atomic/organisms/Modals/Communications/Campaigns/Recruitment/Recruitment.vue';
import MallMemoModal from './atomic/organisms/Modals/Communications/Memos/Mall/Mall.vue';
import StoreMemoModal from './atomic/organisms/Modals/Communications/Memos/Store/Store.vue';
import EmailCommunicationModal from './atomic/organisms/Modals/Communications/Emails/Emails/Emails.vue';
import ApprovalModal from './atomic/organisms/Modals/Communications/Details/Approval/Approval.vue';
import ApprovalReportsModal from './atomic/organisms/Modals/Communications/Details/Approval-Reports/Approval-Reports.vue';
import ApprovalContactsModal from './atomic/organisms/Modals/Communications/Details/Approval-Contacts/Approval-Contacts.vue';
import CompanyModal from './atomic/organisms/Modals/Entities/Company/Company.vue';
import BrandModal from './atomic/organisms/Modals/Entities/Brand/Brand.vue';
import MallModal from './atomic/organisms/Modals/Entities/Mall/Mall.vue';
import StoreModal from './atomic/organisms/Modals/Entities/Store/Store.vue';
import UserModal from './atomic/organisms/Modals/Entities/User/User.vue';
import EmailModal from './atomic/organisms/Modals/Entities/Email/Email.vue';
import ContactModal from './atomic/organisms/Modals/Entities/Contact/Contact.vue';
import TagModal from './atomic/organisms/Modals/Entities/Tag/Tag.vue';
import NotificationModal from './atomic/organisms/Modals/Entities/Notification/Notification.vue';
import CountryModal from './atomic/organisms/Modals/Entities/Country/Country.vue';
import ProvinceModal from './atomic/organisms/Modals/Entities/Province/Province.vue';
import LanguageModal from './atomic/organisms/Modals/Entities/Language/Language.vue';
import Loader from './atomic/atoms/Loader/Loader.vue';
import ImagePreview from './atomic/organisms/Modals/General/Image-Preview/Image-Preview.vue';
// import SocketService from './services/SocketService';
import MallDetailsReview from './views/Review/MallDetailsReview/MallDetailsReview.vue';
import SocketService from './services/SocketService';
import { SocketUtility } from './utilities/Sockets/SocketUtility';

interface Route {
  fullPath: string;
  path: string;
  params: unknown;
  meta: unknown;
  hash: string;
  matched: unknown;
  name: string;
  redirectedFrom: string;
}

export default defineComponent({
  components: {
    'arc-modal': Modal,
    'arc-select-create-modal': SelectCreateModal,
    'arc-marketing-campaign-modal': MarketingCampaignModal,
    'arc-recruitment-campaign-modal': RecruitmentCampaignModal,
    'arc-mall-memo-modal': MallMemoModal,
    'arc-store-memo-modal': StoreMemoModal,
    'arc-email-communication-modal': EmailCommunicationModal,
    'arc-approval-modal': ApprovalModal,
    'arc-approval-reports-modal': ApprovalReportsModal,
    'arc-approval-contacts-modal': ApprovalContactsModal,
    'arc-company-modal': CompanyModal,
    'arc-brand-modal': BrandModal,
    'arc-mall-modal': MallModal,
    'arc-mall-change-request-modal': MallDetailsReview,
    'arc-store-modal': StoreModal,
    'arc-user-modal': UserModal,
    'arc-email-modal': EmailModal,
    'arc-contact-modal': ContactModal,
    'arc-tag-modal': TagModal,
    'arc-notification-modal': NotificationModal,
    'arc-country-modal': CountryModal,
    'arc-province-modal': ProvinceModal,
    'arc-language-modal': LanguageModal,
    'arc-image-preview-modal': ImagePreview,
    'arc-loader': Loader,
  },
  data(): {
    hasUnsavedChanges: boolean;
  } {
    return {
      hasUnsavedChanges: false,
    };
  },
  async mounted(): Promise<void> {
    this.checkUser();

    // initialize window width in state
    this.updateWindowWidth(window.innerWidth);

    // set resize listener to update window fridge
    window.addEventListener('resize', () => {
      this.updateWindowWidth(window.innerWidth);
    });

    // get announcements
    // to-do: update this with retrieval from endpoint
    this.updateAnnouncements([
      {
        index: 0,
        title: 'Announcements',
        description: 'Indoor shopping closed due to covid',
        icon: 'info',
      },
      {
        index: 1,
        title: 'Announcements',
        description: 'Covid restrictions lifted in London, ON',
        icon: 'info',
      },
      {
        index: 2,
        title: 'Announcements',
        description: 'Santa visiting malls in Ontario',
        icon: 'info',
      },
    ]);

    // update active announcement
    if (this.announcements?.length > 0) {
      this.updateActiveAnnouncement(this.announcements[0]);
    }

    // get tips
    // to-do: update this with retrieval from endpoint
    this.updateTips([
      {
        index: 0,
        title: 'Tips',
        description: 'If you experience any difficulties please either contact your Engagement Agents account manager, or call (416) 577-7326',
        icon: 'info',
      },
      {
        index: 1,
        title: 'Tips',
        description: 'Select one or more days to quick start creating a communication',
        icon: 'info',
      },
      {
        index: 2,
        title: 'Tips',
        description: "Click on an event to modify it's details",
        icon: 'info',
      },
      {
        index: 3,
        title: 'Tips',
        description: "Click and drag an event to modify it's place in the timeline",
        icon: 'info',
      },
      {
        index: 4,
        title: 'Tips',
        description: "Click and drag the end of an event to modify it's end date",
        icon: 'info',
      },
    ]);

    // decide whether to cycle through announcements or tips
    if (this.announcements.length > 0) {
      this.cycleAnnouncements();
    } else {
      this.cycleTips();
    }
  },

  computed: {
    ...mapGetters([
      'modalOpen',
      'modalType',
      'modalComponent',
      'announcements',
      'activeAnnouncement',
      'tips',
      'activeTip',
      'isLoading',
      'user',
      'forceLogout',
      'marketingCampaigns',
      'recruitmentCampaigns',
      'mallMemos',
      'storeMemos',
      'loadingMarketingCampaigns',
      'loadingRecruitmentCampaigns',
      'loadingMallMemos',
      'loadingStoreMemos',
    ]),
  },

  methods: {
    handleChangesMade: function(val: boolean) {
      this.hasUnsavedChanges = val;
    },
    ...mapActions([
      'updateWindowWidth',
      'updateCurrentRoute',
      'openModal',
      'closeModal',
      'clearMetadata',
      'updateModalType',
      'updateModalComponent',
      'updateModalData',
      'updateAnnouncements',
      'updateTips',
      'updateActiveAnnouncement',
      'updateActiveTip',
      'fetchCommunications',
      'setLoading',
      'updateCurrentUser',
      'fetchUser',
      'updateForceLogout',
      'fetchMarketingCampaigns',
      'fetchRecruitmentCampaigns',
      'fetchMallMemos',
      'fetchStoreMemos',
      'refreshAllCommunications',
      'fetchRecruitmentCampaignsReport',
      'fetchMalls',
      'fetchStores',
      'fetchCompanies',
      'fetchBrands',
      'fetchUsers',
      'fetchEmailConnections',
    ]),

    async checkUser() {
      if (window.location.href.includes('/review/') || window.location.href.includes('/maintenance') || window.location.href.includes('/r/')) {
        return;
      }

      if (window.location.href.includes('/communications-mall/') || window.location.href.includes('/cm/')) {
        return;
      }

      if (!this.user) {
        if (window.localStorage.getItem('userId') && document.cookie.includes('jwt')) {
          const socket = new SocketService();
          SocketUtility.setSocket(socket.imageUploadSocket);
          await this.fetchUser(window.localStorage.getItem('userId'));
        } else {
          window.localStorage.removeItem('userId');
          if (!window.location.href.includes('/login') && window.location.pathname !== '/') {
            this.$router.push(`/login?reroute=${window.location.href}`);
          }
        }
      } else {
        const w: any = window;
        w.pagesense = w?.pagesense || [];
        w?.pagesense.push(['identifyUser', this.user?.email]);
      }
    },

    // sets the modal type & size
    setModalComponent($event: string) {
      this.updateModalComponent($event);
      this.updateModalType('large');
    },

    // closes the modal
    close() {
      this.hasUnsavedChanges = false;
      if (this.modalComponent !== 'country' && this.modalComponent !== 'province' && this.modalComponent !== 'language') {
        this.clearMetadata();
      }
      this.closeModal();
    },

    // starts cycling through the announcements
    cycleAnnouncements() {
      setInterval(() => {
        let nextAnnouncement;
        if (this.activeAnnouncement.index === this.announcements.length - 1) {
          nextAnnouncement = this.announcements[0];
        } else {
          nextAnnouncement = this.announcements[this.activeAnnouncement.index + 1];
        }
        this.updateActiveAnnouncement(nextAnnouncement);
      }, 7000);
    },

    // starts cycling through the announcements
    cycleTips() {
      setInterval(() => {
        let nextTip;
        if (this.activeTip.index === this.tips.length - 1) {
          nextTip = this.tips[0];
        } else {
          nextTip = this.tips[this.activeTip.index + 1];
        }
        this.updateActiveTip(nextTip);
      }, 7000);
    },
  },

  watch: {
    $route(to: Route): void {
      this.updateCurrentRoute(to.fullPath);
    },
    user: {
      deep: true,
      handler(user: any) {
        const w: any = window;
        w.pagesense = w?.pagesense || [];
        w?.pagesense.push(['identifyUser', this.user?.email]);
      },
    },
    forceLogout: {
      deep: true,
      handler() {
        if (this.forceLogout) {
          // new SocketService().disconnectWithUser(window.localStorage.getItem('userId') as string);
          window.localStorage.removeItem('userId');
          this.$router.push('/login');
          this.updateForceLogout(false);
        }
      },
    },
    marketingCampaigns: {
      deep: true,
      handler() {
        if (
          this.marketingCampaigns &&
          this.recruitmentCampaigns &&
          this.mallMemos &&
          this.storeMemos &&
          !this.loadingMarketingCampaigns &&
          !this.loadingRecruitmentCampaigns &&
          !this.loadingMallMemos &&
          !this.loadingStoreMemos
        ) {
          this.refreshAllCommunications();
        }
      },
    },
    recruitmentCampaigns: {
      deep: true,
      handler() {
        if (
          this.marketingCampaigns &&
          this.recruitmentCampaigns &&
          this.mallMemos &&
          this.storeMemos &&
          !this.loadingMarketingCampaigns &&
          !this.loadingRecruitmentCampaigns &&
          !this.loadingMallMemos &&
          !this.loadingStoreMemos
        ) {
          this.refreshAllCommunications();
        }
      },
    },
    mallMemos: {
      deep: true,
      handler() {
        if (
          this.marketingCampaigns &&
          this.recruitmentCampaigns &&
          this.mallMemos &&
          this.storeMemos &&
          !this.loadingMarketingCampaigns &&
          !this.loadingRecruitmentCampaigns &&
          !this.loadingMallMemos &&
          !this.loadingStoreMemos
        ) {
          this.refreshAllCommunications();
        }
      },
    },
    storeMemos: {
      deep: true,
      handler() {
        if (
          this.marketingCampaigns &&
          this.recruitmentCampaigns &&
          this.mallMemos &&
          this.storeMemos &&
          !this.loadingMarketingCampaigns &&
          !this.loadingRecruitmentCampaigns &&
          !this.loadingMallMemos &&
          !this.loadingStoreMemos
        ) {
          this.refreshAllCommunications();
        }
      },
    },
  },
});
