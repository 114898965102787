
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../../atoms/Input/Input.vue';
import Dropdown from '../../../../atoms/Dropdown/Dropdown.vue';
import MultiSelectDropdown from '../../../../molecules/Multi-SelectDropdown/MultiSelectDropdown.vue';
import TextArea from '../../../../atoms/Text-Area/Text-Area.vue';
import Button from '../../../../atoms/Button/Button.vue';
import Tooltip from '../../../../atoms/Tooltip/Tooltip.vue';
import Modal from '../../../../atoms/Modal/Modal.vue';
import ImageUploader from '../../../../organisms/Image-Uploader/Image-Uploader.vue';
import { FormBuilder, FormControl } from '../../../../../utilities/Forms';
import { Validators } from '../../../../../utilities/Validators/Validators';
import jimp from 'jimp';
import Jimp from 'jimp';
import { IDropdownOption } from '../../../../../utilities/Interfaces/form.interfaces';
import { ICategory } from '../../../../../../../../interfaces/src/v1/Misc/Category';
import SingleSelectInputSuggestion from '../../../../molecules/Single-Select-Input-Suggestion/Single-Select-Input-Suggestion.vue';
import MultiSelectInput from '../../../../molecules/Multi-SelectInput/MultiSelectInput.vue';
import { Status } from '@/enums/Status';
import NavTabs from '@/atomic/atoms/Nav-Tabs/Nav-Tabs.vue';
import { Tabs } from '@/utilities/Types/navigation.types';
import Divider from '@/atomic/atoms/Divider/Divider.vue';
import Checkbox from '@/atomic/atoms/Checkbox/Checkbox.vue';
import { dateFormatterLocalized } from '@/utilities/Functions/formatting';
import ContextVariables from '@/atomic/molecules/Context-Variables/Context-Variables.vue';

interface IUploadedImage {
  url: string;
}

export default defineComponent({
  emits: {
    hasChangesMade: Boolean,
  },
  components: {
    'arc-input': Input,
    'arc-dropdown': Dropdown,
    'arc-multi-select-dropdown': MultiSelectDropdown,
    'arc-single-select-input-suggestion': SingleSelectInputSuggestion,
    'arc-multi-select-input': MultiSelectInput,
    'arc-text-area': TextArea,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
    'arc-image-uploader': ImageUploader,
    'arc-nav-tabs': NavTabs,
    'arc-divider': Divider,
    'arc-checkbox': Checkbox,
    'arc-context-variables': ContextVariables,
  },

  computed: {
    ...mapGetters([
      'modalData',
      'user',
      'access',
      'permissions',
      'brandCategories',
      'brandStatuses',
      'companyOptions',
      'metaEmailTemplates',
      'userOptions',
      'ccUsersOptions',
      'bccUsersOptions',
    ]),
    containerType(): string {
      return this.permissions?.superAdminAccess ? 'admin-container' : 'user-container';
    },
    updateDisabled(): boolean {
      return (
        this.permissions &&
        this.modalData?.row &&
        !this.permissions?.canUpdateAllBrands &&
        (!this.permissions?.canUpdateOwnedBrands ||
          (this.permissions?.canUpdateOwnedBrands && this.user?.userId !== this.modalData?.row?.createdByUserId))
      );
    },
    deleteDisabled(): boolean {
      return (
        this.permissions &&
        this.modalData?.row &&
        !this.permissions?.canDeleteAllBrands &&
        (!this.permissions?.canDeleteOwnedBrands ||
          (this.permissions?.canDeleteOwnedBrands && this.user?.userId !== this.modalData?.row?.createdByUserId))
      );
    },
    tabs(): Tabs {
      return [
        {
          title: 'GENERAL',
          activeTabFlag: 'general',
        },
        {
          title: 'EMAIL TEMPLATES',
          activeTabFlag: 'email-templates',
        },
      ];
    },
  },

  async beforeUnmount(): Promise<void> {
    this.fetchBrands();
  },

  async mounted(): Promise<void> {
    if (this.modalData?.brandId) {
      this.modalData.row = await this.fetchBrandById(this.modalData?.brandId);
      this.loadCcUsers({ companyId: this.modalData.row.companyId });
      this.loadBccUsers({ companyId: this.modalData.row.companyId });
      this.getEmailTemplates('brand');
      this.contextVariables = await this.getContextVariables({ context: 'Brand Email Template' });
    }
    if (this.modalData?.tab) {
      this.activeTabFlag = this.modalData.tab;
    }
    this.loadBrandStatuses();
    this.loadBrandCategories();
    this.loadCompanies();
    this.setForm();
    this.loaded = true;
  },
  data(): {
    status: string;
    changesMade: boolean;
    formValid: boolean;
    promptOpen: boolean;
    promptType: string;
    submitting: boolean;
    updating: boolean;
    saving: boolean;
    expiring: boolean;
    deleting: boolean;
    companyValue: IDropdownOption | null;
    statusValue: IDropdownOption | null;
    categoryValues: IDropdownOption[];
    formData: any;
    loaded: boolean;
    brandLogo: IUploadedImage[];
    brandLogoUploading: boolean;
    alternateBrandLogo: IUploadedImage[];
    alternateBrandLogoUploading: boolean;
    coverPhoto: IUploadedImage[];
    coverPhotoUploading: boolean;
    allowShowOptions: boolean;
    backgroundBlurry: boolean;
    appleBusinessImageId: string;
    activeTabFlag: string;
    emailTemplates: any[];
    yesNoOptions: IDropdownOption[];
    onboardingValue: IDropdownOption;
    googleBusinessValue: IDropdownOption;
    appleBusinessValue: IDropdownOption;
    followUpEmailValue: IDropdownOption;
    channelPostDateValue: IDropdownOption;
    onboardingUserValue: IDropdownOption | null;
    onboardingSendEmail: boolean;
    onboardingEmailSent: string | null;
    contextVariables: any[];
  } {
    return {
      status: 'Draft',
      changesMade: false,
      formValid: true,
      promptOpen: false,
      promptType: 'save',
      submitting: false,
      updating: false,
      saving: false,
      expiring: false,
      deleting: false,
      formData: null,
      loaded: false,
      companyValue: null,
      statusValue: null,
      categoryValues: [],
      brandLogo: [],
      brandLogoUploading: false,
      alternateBrandLogo: [],
      alternateBrandLogoUploading: false,
      coverPhoto: [],
      coverPhotoUploading: false,
      allowShowOptions: false,
      backgroundBlurry: true,
      appleBusinessImageId: '',
      activeTabFlag: 'general',
      emailTemplates: [],
      yesNoOptions: [
        { value: true, description: 'Yes' },
        { value: false, description: 'No' },
      ],
      onboardingValue: { value: true, description: 'Yes' },
      googleBusinessValue: { value: false, description: 'No' },
      appleBusinessValue: { value: false, description: 'No' },
      followUpEmailValue: { value: false, description: 'No' },
      channelPostDateValue: { value: false, description: 'No' },
      onboardingUserValue: null,
      onboardingSendEmail: false,
      onboardingEmailSent: null,
      contextVariables: [],
    };
  },

  methods: {
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateClosingModal',
      'loadCompanies',
      'loadBrandCategories',
      'loadBrandStatuses',
      'saveBrand',
      'deleteBrand',
      'updateBrand',
      'uploadImage',
      'uploadImageToApple',
      'fetchBrandById',
      'fetchBrands',
      'saveBrandEmailTemplate',
      'getEmailTemplates',
      'loadUsers',
      'loadCcUsers',
      'loadBccUsers',
      'getContextVariables',
    ]),
    setForm(): void {
      // initializers
      let company = '';
      let categories: string[] = [];
      let description = '';
      let longDescription = '';
      let twitterDescription = '';
      let facebookDescription = '';
      let instagramDescription = '';
      let status = '';
      let website = '';
      let shortDescription = '';
      let backgroundColour = '';
      let googleBusinessAccountId = '';
      let appleBusinessBusinessId = '';
      let onboarding = true;
      let googleBusiness = false;
      let appleBusiness = false;
      let followUpEmail = false;
      let channelPostDate = false;
      // set values if editing existing campaign
      if (this.modalData?.row) {
        company = this.modalData?.row?.companyId;
        description = this.modalData?.row?.description;
        longDescription = this.modalData?.row?.longDescription;
        twitterDescription = this.modalData?.row?.twitterDescription;
        facebookDescription = this.modalData?.row?.facebookDescription;
        instagramDescription = this.modalData?.row?.instagramDescription;
        status = this.modalData?.row?.statusId;
        website = this.modalData?.row?.website ?? '';
        shortDescription = this.modalData?.row?.shortDescription ?? '';
        backgroundColour = this.modalData?.row?.backgroundColour;
        onboarding = this.modalData?.row?.onboarding;
        googleBusiness = this.modalData?.row?.googleBusiness;
        appleBusiness = this.modalData?.row?.appleBusiness;
        followUpEmail = this.modalData?.row?.followUpEmail;
        channelPostDate = this.modalData?.row?.channelPostDate;
        this.backgroundBlurry = this.modalData?.row?.backgroundBlurry ?? true;
        this.modalData?.row?.categories.forEach((category: ICategory) => {
          categories.push(category?.categoryId.toString());
        });
        this.brandLogo = [{ url: this.modalData?.row?.logoUrl }];
        this.alternateBrandLogo = [{ url: this.modalData?.row?.alternateLogoUrl }];
        this.coverPhoto = [{ url: this.modalData?.row?.coverPhotoUrl }];
        googleBusinessAccountId = this.modalData?.row?.googleBusinessAccountId ?? '';
        appleBusinessBusinessId = this.modalData?.row?.appleBusinessBusinessId ?? '';
        this.onboardingEmailSent = this.formatDateLocalized(this.modalData?.row?.onboardingEmailSent) ?? null;

        this.yesNoOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.onboarding === item?.value) {
            this.onboardingValue = item;
          }
          if (this.modalData?.row?.googleBusiness === item?.value) {
            this.googleBusinessValue = item;
          }
          if (this.modalData?.row?.appleBusiness === item?.value) {
            this.appleBusinessValue = item;
          }
          if (this.modalData?.row?.followUpEmail === item?.value) {
            this.followUpEmailValue = item;
          }
          if (this.modalData?.row?.channelPostDate === item?.value) {
            this.channelPostDateValue = item;
          }
        });
      }
      // set form based on user type
      this.formData = new FormBuilder({
        company: new FormControl(company, [Validators.required]),
        categories: new FormControl(categories),
        description: new FormControl(description, [Validators.required]),
        longDescription: new FormControl(longDescription),
        facebookDescription: new FormControl(facebookDescription),
        twitterDescription: new FormControl(twitterDescription),
        instagramDescription: new FormControl(instagramDescription),
        status: new FormControl(status, [Validators.required]),
        website: new FormControl(website),
        shortDescription: new FormControl(shortDescription),
        backgroundColour: new FormControl(backgroundColour),
        googleBusinessAccountId: new FormControl(googleBusinessAccountId),
        appleBusinessBusinessId: new FormControl(appleBusinessBusinessId),
        onboarding: new FormControl(onboarding),
        googleBusiness: new FormControl(googleBusiness),
        appleBusiness: new FormControl(appleBusiness),
        followUpEmail: new FormControl(followUpEmail),
        channelPostDate: new FormControl(channelPostDate),
        onboardingUser: new FormControl(''),
        onboardingSendEmail: new FormControl(false),
      });
    },
    async brandLogoFileChange(images: Blob[]): Promise<void> {
      const image = images[0];
      this.brandLogo = [];
      this.loadingHandler({
        type: 'brand-logo',
        overRide: true,
        overRideValue: true,
      });

      try {
        const url = await this.uploadImage(image);
        this.brandLogo.push({ url });
        this.setChangesMade();
        this.loadingHandler({
          type: 'brand-logo',
          overRide: true,
          overRideValue: false,
        });
      } catch (err) {
        this.loadingHandler({
          type: 'brand-logo',
          overRide: true,
          overRideValue: false,
        });
      }
    },
    async alternateBrandLogoFileChange(images: Blob[]): Promise<void> {
      const image = images[0];
      this.alternateBrandLogo = [];
      this.loadingHandler({
        type: 'alt-brand-logo',
        overRide: true,
        overRideValue: true,
      });

      try {
        const url = await this.uploadImage(image);
        this.alternateBrandLogo.push({ url });
        this.setChangesMade();
        this.loadingHandler({
          type: 'alt-brand-logo',
          overRide: true,
          overRideValue: false,
        });
      } catch (err) {
        this.loadingHandler({
          type: 'alt-brand-logo',
          overRide: true,
          overRideValue: false,
        });
      }
    },
    async coverPhotoFileChange(images: Blob[]): Promise<void> {
      const image = images[0];
      this.coverPhoto = [];
      this.loadingHandler({
        type: 'cover-photo',
        overRide: true,
        overRideValue: true,
      });

      try {
        const url = await this.uploadImage(image);
        this.coverPhoto.push({ url });
        // todo readd if we want to send to apple
        // this.appleBusinessImageId = await this.uploadImageToApple({
        //   image,
        //   appleBusinessBusinessId: this.formData?.controls?.appleBusinessBusinessId?.value,
        //   appleBusinessLocationId: '',
        // });
        this.setChangesMade();
        this.loadingHandler({
          type: 'cover-photo',
          overRide: true,
          overRideValue: false,
        });
      } catch (err) {
        this.loadingHandler({
          type: 'cover-photo',
          overRide: true,
          overRideValue: false,
        });
      }
    },
    loadingHandler({
      type,
      overRide,
      overRideValue,
    }: {
      type: 'brand-logo' | 'alt-brand-logo' | 'cover-photo';
      overRide: boolean;
      overRideValue: boolean;
    }): boolean {
      if (type === 'brand-logo') {
        if (overRide) {
          return (this.brandLogoUploading = overRideValue);
        }
        return (this.brandLogoUploading = !this.brandLogoUploading);
      } else if (type === 'alt-brand-logo') {
        if (overRide) {
          return (this.alternateBrandLogoUploading = overRideValue);
        }
        return (this.alternateBrandLogoUploading = !this.alternateBrandLogoUploading);
      } else if (type === 'cover-photo') {
        if (overRide) {
          return (this.coverPhotoUploading = overRideValue);
        }
        return (this.coverPhotoUploading = !this.coverPhotoUploading);
      }
      return false;
    },
    async readFile(file: Blob): Promise<Jimp> {
      return new Promise((resolve) => {
        const fileReader: FileReader = new FileReader();
        fileReader.onloadend = async (event): Promise<any> => {
          const image: Jimp = await jimp.read(event?.target?.result as string);
          return resolve(image);
        };
        fileReader.readAsDataURL(file);
      });
    },
    categoriesUpdated(value: any): void {
      this.categoryValues = value;
      this.formData.controls.categories.value = this.categoryValues?.map((el: { value: unknown; description: string }) => el.value);
      this.setChangesMade();
    },
    updateCompany(value: IDropdownOption) {
      this.companyValue = value;
      this.formData.controls.company.value = this.companyValue.value;
      this.setChangesMade();
      this.validateControl('company');
    },
    clearCompany(value: any) {
      if (value === '') {
        this.companyValue = null;
        this.formData.controls.company.value = null;
        this.setChangesMade();
        this.validateControl('company');
      }
    },
    updateStatus(value: IDropdownOption) {
      this.statusValue = value;
      this.formData.controls.status.value = this.statusValue.value;
      this.setChangesMade();
    },
    updateOnboarding(value: IDropdownOption) {
      this.onboardingValue = value;
      this.formData.controls.onboarding.value = this.onboardingValue.value;
      if (!this.onboardingValue.value) {
        this.loadUsers({ brandId: this.modalData?.row?.brandId });
        this.formData.controls.onboardingUser.validators = [Validators.required];
        this.formData.controls.onboardingSendEmail.validators = [Validators.required];
      } else {
        this.onboardingUserValue = null;
        this.formData.controls.onboardingUser.value = '';
        this.formData.controls.onboardingUser.validators = [];
        this.formData.controls.onboardingSendEmail.validators = [];
      }
      this.setChangesMade();
      this.validateControl('onboarding');
    },
    updateGoogleBusiness(value: IDropdownOption) {
      this.googleBusinessValue = value;
      this.formData.controls.googleBusiness.value = this.googleBusinessValue.value;
      this.setChangesMade();
      this.validateControl('googleBusiness');
    },
    updateAppleBusiness(value: IDropdownOption) {
      this.appleBusinessValue = value;
      this.formData.controls.appleBusiness.value = this.appleBusinessValue.value;
      this.setChangesMade();
      this.validateControl('appleBusiness');
    },
    updateFollowUpEmail(value: IDropdownOption) {
      this.followUpEmailValue = value;
      this.formData.controls.followUpEmail.value = this.followUpEmailValue.value;
      this.setChangesMade();
      this.validateControl('followUpEmail');
    },
    updateChannelPostDate(value: IDropdownOption) {
      this.channelPostDateValue = value;
      this.formData.controls.channelPostDate.value = this.channelPostDateValue.value;
      this.setChangesMade();
      this.validateControl('channelPostDate');
    },
    updateOnboardingUser(value: IDropdownOption) {
      this.onboardingUserValue = value;
      this.formData.controls.onboardingUser.value = this.onboardingUserValue.value;
      this.setChangesMade();
      this.validateControl('onboardingUser');
    },
    toggleOnboardingSendEmail() {
      this.onboardingSendEmail = !this.onboardingSendEmail;
      this.formData.controls.onboardingSendEmail.value = !this.formData.controls.onboardingSendEmail.value;
      this.setChangesMade();
      this.validateControl('onboardingSendEmail');
    },
    setChangesMade() {
      this.changesMade = true;
      if (this.formData.controls.longDescription.value?.length > 1000) {
        this.formData.errors.longDescription.message = 'Max Limit 1000';
        this.formData.errors.longDescription.status = 'error';
      } else {
        this.formData.errors.longDescription.message = '';
        this.formData.errors.longDescription.status = '';
      }

      if (this.formData.controls.shortDescription.value?.length > 250) {
        this.formData.errors.shortDescription.message = 'Max Limit 250';
        this.formData.errors.shortDescription.status = 'error';
      } else {
        this.formData.errors.shortDescription.message = '';
        this.formData.errors.shortDescription.status = '';
      }

      if (this.formData.controls.website.value?.length > 0 && !this.isValidUrl(this.formData.controls.website.value)) {
        this.formData.errors.website.message = 'Invalid Url';
        this.formData.errors.website.status = 'error';
      } else {
        this.formData.errors.website.message = '';
        this.formData.errors.website.status = '';
      }
    },
    validateControl(controlName: string): void {
      setTimeout(() => {
        this.formData.controls[controlName].validate();
        if (!this.changesMade) {
          this.setChangesMade();
        }
      }, 0);
    },
    isValidUrl(urlString: string): boolean {
      if (!urlString) return true;
      let urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
          '(\\#[-a-z\\d_]*)?$',
        'i',
      ); // validate fragment locator
      return !!urlPattern.test(urlString);
    },
    closePrompt(): void {
      this.promptOpen = false;
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
    },
    deletePrompt(): void {
      this.openPrompt('delete');
    },
    async delete(): Promise<void> {
      this.deleting = true;
      this.promptOpen = false;

      try {
        await this.deleteBrand(this.modalData?.row?.brandId);
        this.deleting = false;
        this.promptOpen = false;
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
          this.$notify({
            title: 'SUCCESS',
            text: 'Brand deleted successfully!',
            type: 'success',
            duration: 5000,
          });
        }, 500);
      } catch (err) {
        this.deleting = false;
        this.promptOpen = false;
        this.$notify({
          title: 'ERROR',
          text: 'Unable to delete brand.',
          type: 'error',
          duration: 5000,
        });
      }
    },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    formHasNoErrors(): boolean {
      return (
        !this.formData.hasErrors &&
        this.formData?.controls?.longDescription?.value?.length <= 1000 &&
        this.formData?.controls?.shortDescription?.value?.length <= 250 &&
        this.isValidUrl(this.formData.controls.website.value)
      );
    },
    async save(): Promise<void> {
      if (!this.permissions?.superAdminAccess) {
        this.formData.controls.company.value = this.user?.companyId;
      }
      this.formData.markAllAsTouched();
      if (this.formHasNoErrors()) {
        let logoUrl = '';
        if (this.brandLogo && this.brandLogo.length > 0) {
          logoUrl = this.brandLogo[0].url;
        }

        let alternateLogoUrl = '';
        if (this.alternateBrandLogo && this.alternateBrandLogo.length > 0) {
          alternateLogoUrl = this.alternateBrandLogo[0].url;
        }

        let coverPhotoUrl = '';
        if (this.coverPhoto && this.coverPhoto.length > 0) {
          coverPhotoUrl = this.coverPhoto[0].url;
        }

        let payload = {
          description: this.formData?.controls?.description?.value,
          longDescription: this.formData?.controls?.longDescription?.value,
          statusId: Status.Active,
          companyId: this.formData?.controls?.company?.value,
          categoryIds: this.formData?.controls?.categories?.value,
          logoUrl,
          facebookDescription: this.formData?.controls?.facebookDescription?.value,
          twitterDescription: this.formData?.controls?.twitterDescription?.value,
          instagramDescription: this.formData?.controls?.instagramDescription?.value,
          backgroundBlurry: this.backgroundBlurry,
          alternateLogoUrl,
          coverPhotoUrl,
          website: this.formData?.controls?.website?.value,
          shortDescription: this.formData?.controls?.shortDescription?.value,
          backgroundColour: this.formData?.controls?.backgroundColour?.value,
          googleBusinessAccountId: this.formData?.controls?.googleBusinessAccountId?.value,
          appleBusinessBusinessId: this.formData?.controls?.appleBusinessBusinessId?.value,
          onboarding: this.formData?.controls?.onboarding?.value,
          googleBusiness: this.formData?.controls?.googleBusiness?.value,
          appleBusiness: this.formData?.controls?.appleBusiness?.value,
          followUpEmail: this.formData?.controls?.followUpEmail?.value,
          channelPostDate: this.formData?.controls?.channelPostDate?.value,
        };

        this.saving = true;

        try {
          await this.saveBrand(payload);
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: 'Brand saved successfully!',
            type: 'success',
            duration: 5000,
          });
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          this.$notify({
            title: 'ERROR',
            text: 'Unable to save Brand.',
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Brand form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async update(): Promise<void> {
      if (!this.permissions?.superAdminAccess) {
        this.formData.controls.company.value = this.user?.companyId;
      }
      this.formData.markAllAsTouched();
      if (this.formHasNoErrors()) {
        let logoUrl = '';
        if (this.brandLogo && this.brandLogo.length > 0) {
          logoUrl = this.brandLogo[0].url;
        }

        let alternateLogoUrl = '';
        if (this.alternateBrandLogo && this.alternateBrandLogo.length > 0) {
          alternateLogoUrl = this.alternateBrandLogo[0].url;
        }

        let coverPhotoUrl = '';
        if (this.coverPhoto && this.coverPhoto.length > 0) {
          coverPhotoUrl = this.coverPhoto[0].url;
        }

        let payload = {
          description: this.formData?.controls?.description?.value,
          longDescription: this.formData?.controls?.longDescription?.value,
          statusId: this.formData?.controls?.status?.value,
          companyId: this.formData?.controls?.company?.value,
          categoryIds: this.formData?.controls?.categories?.value,
          logoUrl,
          facebookDescription: this.formData?.controls?.facebookDescription?.value,
          twitterDescription: this.formData?.controls?.twitterDescription?.value,
          instagramDescription: this.formData?.controls?.instagramDescription?.value,
          backgroundBlurry: this.backgroundBlurry,
          alternateLogoUrl,
          coverPhotoUrl,
          website: this.formData?.controls?.website?.value,
          shortDescription: this.formData?.controls?.shortDescription?.value,
          backgroundColour: this.formData?.controls?.backgroundColour?.value,
          googleBusinessAccountId: this.formData?.controls?.googleBusinessAccountId?.value,
          appleBusinessBusinessId: this.formData?.controls?.appleBusinessBusinessId?.value,
          appleBusinessImageId: this.appleBusinessImageId,
          onboarding: this.formData?.controls?.onboarding?.value,
          googleBusiness: this.formData?.controls?.googleBusiness?.value,
          appleBusiness: this.formData?.controls?.appleBusiness?.value,
          onboardingUser: this.formData?.controls?.onboardingUser?.value,
          followUpEmail: this.formData?.controls?.followUpEmail?.value,
          channelPostDate: this.formData?.controls?.channelPostDate?.value,
        };

        this.saving = true;

        try {
          await this.updateBrand({ payload, brandId: this.modalData?.row?.brandId });
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: 'Brand updated successfully!',
            type: 'success',
            duration: 5000,
          });
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          this.$notify({
            title: 'ERROR',
            text: 'Unable to update Brand.',
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Brand form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    tabClicked(tab: string): void {
      this.activeTabFlag = tab;
    },
    updateHtml(input: any, reminderTypeId: number): void {
      this.emailTemplates.find((template) => template.reminderTypeId === reminderTypeId).rawHtml = input.target.innerHTML;
    },
    async updateTemplate(reminderTypeId: number): Promise<void> {
      const emailTemplate = this.emailTemplates.find((template) => template.reminderTypeId === reminderTypeId);
      const payload = {
        brandId: this.modalData?.row?.brandId,
        reminderTypeId,
        emailTemplate: emailTemplate.rawHtml,
        cc: emailTemplate.ccUsersValues?.map((item: IDropdownOption) => item.value).join(','),
        bcc: emailTemplate.bccUsersValues?.map((item: IDropdownOption) => item.value).join(','),
      };
      try {
        await this.saveBrandEmailTemplate(payload);
        this.saving = false;
        this.$notify({
          title: 'SUCCESS',
          text: 'Brand Email Template saved successfully!',
          type: 'success',
          duration: 5000,
        });
      } catch (err) {
        this.$notify({
          title: 'ERROR',
          text: 'Unable to save Brand Email Template.',
          type: 'error',
          duration: 5000,
        });
      }
    },
    ccUsersUpdated(value: any, reminderTypeId: number): void {
      this.emailTemplates.find((template) => template.reminderTypeId === reminderTypeId).ccUsersValues = value;
      this.setChangesMade();
    },
    bccUsersUpdated(value: any, reminderTypeId: number): void {
      this.emailTemplates.find((template) => template.reminderTypeId === reminderTypeId).bccUsersValues = value;
      this.setChangesMade();
    },
    formatDateLocalized(date: string): string {
      return dateFormatterLocalized(date);
    },
    mapContextVariables(emailTemplateTitle: string): string[] {
      return this.contextVariables?.find((el: any) => el.subContext === emailTemplateTitle)?.variables.split(',');
    },
  },
  watch: {
    brandStatuses: {
      deep: true,
      handler() {
        this.brandStatuses.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.statusId === item?.value) {
            this.statusValue = item;
          }
        });
      },
    },
    companyOptions: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.allowShowOptions = true;
        }, 250);
        this.companyOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.companyId === item?.value) {
            this.companyValue = item;
          }
        });
      },
    },
    brandCategories: {
      deep: true,
      handler() {
        this.brandCategories.forEach((item: IDropdownOption) => {
          this.modalData?.row?.categories.forEach((category: ICategory) => {
            if (item.value === category.categoryId) {
              this.categoryValues.push(item);
            }
          });
        });
      },
    },
    metaEmailTemplates: {
      deep: true,
      handler() {
        const brandEmailTemplates = this.modalData?.row?.emailTemplates;
        this.metaEmailTemplates.forEach((emailTemplate: any) => {
          const brandEmailTemplate = brandEmailTemplates?.find((template: any) => template.reminderTypeId === emailTemplate.reminderTypeId);
          if (brandEmailTemplate) {
            this.emailTemplates.push({
              reminderTypeId: emailTemplate.reminderTypeId,
              title: emailTemplate.reminderType.description,
              variables: emailTemplate.variables,
              rawHtml: brandEmailTemplate.emailTemplate, // brand specific info
            });
          } else {
            this.emailTemplates.push({
              reminderTypeId: emailTemplate.reminderTypeId,
              title: emailTemplate.reminderType.description,
              variables: emailTemplate.variables,
              rawHtml: emailTemplate.emailTemplate,
            });
          }
        });
      },
    },
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
    backgroundBlurry: {
      deep: true,
      handler() {
        this.setChangesMade();
      },
    },
    ccUsersOptions: {
      deep: true,
      handler() {
        this.modalData?.row?.ccUsers?.forEach((user: any) => {
          const ccUserValue = this.ccUsersOptions.find((u: any) => u.value === user.userId);
          this.ccUsersOptions.push(ccUserValue);
        });
      },
    },
    bccUsersOptions: {
      deep: true,
      handler() {
        this.modalData?.row?.bccUsers?.forEach((user: any) => {
          const bccUserValue = this.bccUsersOptions.find((u: any) => u.value === user.userId);
          this.bccUsersOptions.push(bccUserValue);
        });
      },
    },
  },
});
