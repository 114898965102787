
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Table from '../../../../Table/Table.vue';
import { Tabs } from '../../../../../../utilities/Types/navigation.types';
import PageControls from '../../../../Page-Controls/Page-Controls.vue';
import { ColumnDefinition, SortDefinition } from '../../../../../../utilities/Types/table.types';
import { IDropdownOption } from '../../../../../../utilities/Interfaces/form.interfaces';
import { IContact } from '../../../../../../../../../interfaces/src/v2';
import Modal from '../../../../../atoms/Modal/Modal.vue';
import Button from '../../../../../atoms/Button/Button.vue';
import TextArea from '../../../../../atoms/Text-Area/Text-Area.vue';
import axios from 'axios';
import { Status } from '@/enums/Status';

export default defineComponent({
  components: {
    'arc-table': Table,
    'arc-page-controls': PageControls,
    'arc-modal': Modal,
    'arc-button': Button,
    'arc-text-area': TextArea,
  },
  computed: {
    ...mapGetters([
      'modalData',
      'marketingCampaignReview',
      'loadingMarketingCampaignReview',
      'recruitmentCampaignReview',
      'loadingRecruitmentCampaignReview',
      'mallMemoReview',
      'loadingMallMemoReview',
      'storeMemoReview',
      'loadingStoreMemoReview',
      'declineReasons',
      'metaChannels',
      'contactChannels',
      'mallsChannels',
      'systemEmails',
      'user',
      'access',
      'permissions',
    ]),
    isLoading(): boolean {
      return (
        this.loadingMarketingCampaignReview || this.loadingRecruitmentCampaignReview || this.loadingMallMemoReview || this.loadingStoreMemoReview
      );
    },
    reasons(): any[] {
      return this.declineReasons?.data;
    },
    metaChannelsData(): any[] {
      return this.metaChannels?.data;
    },
    contactChannelsData(): any[] {
      return this.contactChannels?.data;
    },
    mallsChannelsData(): any[] {
      return this.mallsChannels?.data;
    },
    communicationType(): string {
      let type = '';
      if (this.modalData.row.commType === 'marketing-campaign') {
        type = `Marketing Campaign`;
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        type = `Recruitment Campaign`;
      }
      if (this.modalData.row.commType === 'store-memo') {
        type = `Store Memo`;
        if (this.modalData.row.commMemoSubTypeText === 'Retailer') {
          type = `Retailer Memo`;
        }
      }
      if (this.modalData.row.commType === 'mall-memo') {
        type = `Mall Memo`;
      }
      return type;
    },
    tabs(): Tabs {
      return [
        {
          title: 'SENT',
          activeTabFlag: 'sent',
        },
        {
          title: 'APPROVED',
          activeTabFlag: 'approved',
        },
        {
          title: 'PENDING',
          activeTabFlag: 'pending',
        },
        {
          title: 'DECLINED',
          activeTabFlag: 'declined',
        },
      ];
    },
    columnDefinitions(): ColumnDefinition[] {
      let coldefs: any = [];
      if (this.activeTab === 'sent') {
        coldefs = [
          {
            type: 'text',
            columnName: 'id',
            displayName: 'ID',
            sortable: true,
          },
          {
            type: 'text',
            columnName:
              this.modalData.row.commType === 'recruitment-campaign' || this.modalData.row.commType === 'marketing-campaign'
                ? 'campaignNumber'
                : 'memoNumber',
            displayName:
              this.modalData.row.commType === 'recruitment-campaign' || this.modalData.row.commType === 'marketing-campaign'
                ? 'Campaign Number'
                : 'Memo Number',
            sortable: true,
          },
          this.access?.superAdminAccess
            ? {
              type: 'text',
              columnName: 'companyDescription',
              displayName: 'Company',
            }
            : {}
          ,
          this.access?.superAdminAccess
            ? {
              type: 'text',
              columnName: 'brandDescription',
              displayName: 'Brand',
            }
            : {}
          ,
          {
            type: 'text',
            columnName: 'description',
            displayName: 'Name',
            headerStyle: 'min-width: 150px',
            sortable: true,
          },
          this.modalData.row.commType === 'store-memo'
            ? {
                type: 'text',
                columnName: 'commSubTypeText',
                displayName: 'Type',
                sortable: true,
              }
            : {},
          this.modalData.row.commType === 'store-memo'
            ? {
                type: 'text',
                columnName: 'commMemoSubTypeText',
                displayName: 'Subtype',
              }
            : {},
          {
            type: 'text',
            columnName: 'statusDesc',
            displayName: 'Status',
            sortable: true,
          },
          {
            type: 'date',
            columnName: this.modalData.row.commType === 'recruitment-campaign' ? 'publishByDate' : 'fromDate',
            displayName: this.modalData.row.commType === 'recruitment-campaign' ? 'Publish by Date' : 'Start Date',
            sortable: true,
          },
          {
            type: 'date',
            columnName: 'toDate',
            displayName: 'End Date',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'timezone',
            displayName: 'Timezone',
          },
          {
            type: 'date',
            columnName: 'sendDate',
            displayName: 'Date Campaign Sent',
            sortable: true,
          },
          {
            type: 'emails',
            columnName: 'systemEmailsCount',
            displayName: 'Number of Emails Sent',
          },
        ];
      }
      if (this.activeTab === 'approved') {
        coldefs = [
          {
            type: 'text',
            columnName: 'id',
            displayName: 'ID',
            sortable: true,
          },
          {
            type: 'text',
            columnName:
              this.modalData.row.commType === 'recruitment-campaign' || this.modalData.row.commType === 'marketing-campaign'
                ? 'campaignNumber'
                : 'memoNumber',
            displayName:
              this.modalData.row.commType === 'recruitment-campaign' || this.modalData.row.commType === 'marketing-campaign'
                ? 'Campaign Number'
                : 'Memo Number',
            sortable: true,
          },
          this.access?.superAdminAccess
            ? {
              type: 'text',
              columnName: 'companyDescription',
              displayName: 'Company',
            }
            : {}
          ,
          this.access?.superAdminAccess
            ? {
              type: 'text',
              columnName: 'brandDescription',
              displayName: 'Brand',
            }
            : {}
          ,
          {
            type: 'text',
            columnName: 'description',
            displayName: 'Name',
            headerStyle: 'min-width: 150px',
            sortable: true,
          },
          {
            type: 'date',
            columnName: this.modalData.row.commType === 'recruitment-campaign' ? 'publishByDate' : 'fromDate',
            displayName: this.modalData.row.commType === 'recruitment-campaign' ? 'Publish by Date' : 'Start Date',
            sortable: true,
          },
          {
            type: 'date',
            columnName: 'toDate',
            displayName: 'End Date',
            sortable: true,
          },
          {
            type: 'date',
            columnName: 'sendDate',
            displayName: 'Date Campaign Sent',
            sortable: true,
          },
          {
            type: 'date',
            columnName: 'actionDate',
            displayName: 'Date Accepted',
          },
          {
            type: 'text',
            columnName: 'responseTime',
            displayName: 'Response Time in Hours',
          },
          {
            type: 'text',
            columnName: 'timezone',
            displayName: 'Timezone',
          },
          {
            type: 'stringArray',
            columnName: 'channels',
            displayName: 'Channels',
          },
          {
            type: 'emails',
            columnName: 'systemEmailsCount',
            displayName: 'Number of Emails Sent',
          },
        ];
      }
      if (this.activeTab === 'pending') {
        coldefs = [
          {
            type: 'text',
            columnName: 'id',
            displayName: 'ID',
            sortable: true,
          },
          {
            type: 'text',
            columnName:
              this.modalData.row.commType === 'recruitment-campaign' || this.modalData.row.commType === 'marketing-campaign'
                ? 'campaignNumber'
                : 'memoNumber',
            displayName:
              this.modalData.row.commType === 'recruitment-campaign' || this.modalData.row.commType === 'marketing-campaign'
                ? 'Campaign Number'
                : 'Memo Number',
            sortable: true,
          },
          this.access?.superAdminAccess
            ? {
              type: 'text',
              columnName: 'companyDescription',
              displayName: 'Company',
            }
            : {}
          ,
          this.access?.superAdminAccess
            ? {
              type: 'text',
              columnName: 'brandDescription',
              displayName: 'Brand',
            }
            : {}
          ,
          {
            type: 'text',
            columnName: 'description',
            displayName: 'Name',
            headerStyle: 'min-width: 150px',
            sortable: true,
          },
          {
            type: 'date',
            columnName: this.modalData.row.commType === 'recruitment-campaign' ? 'publishByDate' : 'fromDate',
            displayName: this.modalData.row.commType === 'recruitment-campaign' ? 'Publish by Date' : 'Start Date',
            sortable: true,
          },
          {
            type: 'date',
            columnName: 'toDate',
            displayName: 'End Date',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'timezone',
            displayName: 'Timezone',
          },
          {
            type: 'date',
            columnName: 'sendDate',
            displayName: 'Date Campaign Sent',
            sortable: true,
          },
          {
            type: 'emails',
            columnName: 'systemEmailsCount',
            displayName: 'Number of Emails Sent',
          },
        ];
      }
      if (this.activeTab === 'declined') {
        coldefs = [
          {
            type: 'text',
            columnName: 'id',
            displayName: 'ID',
            sortable: true,
          },
          {
            type: 'text',
            columnName:
              this.modalData.row.commType === 'recruitment-campaign' || this.modalData.row.commType === 'marketing-campaign'
                ? 'campaignNumber'
                : 'memoNumber',
            displayName:
              this.modalData.row.commType === 'recruitment-campaign' || this.modalData.row.commType === 'marketing-campaign'
                ? 'Campaign Number'
                : 'Memo Number',
            sortable: true,
          },
          this.access?.superAdminAccess
            ? {
              type: 'text',
              columnName: 'companyDescription',
              displayName: 'Company',
            }
            : {}
          ,
          this.access?.superAdminAccess
            ? {
              type: 'text',
              columnName: 'brandDescription',
              displayName: 'Brand',
            }
            : {}
          ,
          {
            type: 'text',
            columnName: 'description',
            displayName: 'Name',
            headerStyle: 'min-width: 150px',
            sortable: true,
          },
          {
            type: 'date',
            columnName: this.modalData.row.commType === 'recruitment-campaign' ? 'publishByDate' : 'fromDate',
            displayName: this.modalData.row.commType === 'recruitment-campaign' ? 'Publish by Date' : 'Start Date',
            sortable: true,
          },
          {
            type: 'date',
            columnName: 'toDate',
            displayName: 'End Date',
            sortable: true,
          },
          {
            type: 'date',
            columnName: 'sendDate',
            displayName: 'Date Campaign Sent',
            sortable: true,
          },
          {
            type: 'date',
            columnName: 'actionDate',
            displayName: 'Action Date',
          },
          {
            type: 'text',
            columnName: 'reason',
            displayName: 'Reason',
          },
          {
            type: 'text',
            columnName: 'responseTime',
            displayName: 'Response Time in Hours',
          },
          {
            type: 'text',
            columnName: 'timezone',
            displayName: 'Timezone',
          },
          {
            type: 'emails',
            columnName: 'systemEmailsCount',
            displayName: 'Number of Emails Sent',
          },
        ];
      }
      return coldefs;
    },
    emailColumnDefinitions(): ColumnDefinition[] {
      return [
        {
          type: 'text',
          columnName: 'emailSubject',
          displayName: 'Subject',
        },
        {
          type: 'text',
          columnName: 'toEmailAddress',
          displayName: 'To',
        },
        {
          type: 'stringArray',
          columnName: 'ccEmailAddresses',
          displayName: 'CC',
        },
        {
          type: 'sendGridStatus',
          columnName: 'sendGridStatus',
          displayName: 'Status',
        },
        {
          type: 'text',
          columnName: 'sendGridMessageId',
          displayName: 'TrackingId',
        },
        {
          type: 'timestamp',
          columnName: 'deliveredTimestamp',
          displayName: 'Date Sent',
          headerStyle: 'min-width: 150px',
        },
        {
          type: 'timestamp',
          columnName: 'lastOpenedTimestamp',
          displayName: 'Last Date Opened',
          headerStyle: 'min-width: 150px',
        },
        {
          type: 'text',
          columnName: 'openCount',
          displayName: 'Total Number of Opens',
          headerStyle: 'min-width: 140px',
          position: 'center',
          tooltip: 'This might not be 100% accurate as some email clients block tracking pixels.',
          tooltipStyles: 'bottom: -6rem; width: 180px',
        },
        {
          type: 'timestamp',
          columnName: 'lastClickedTimestamp',
          displayName: 'Last Date Clicked',
          headerStyle: 'min-width: 150px',
        },
        {
          type: 'text',
          columnName: 'clickCount',
          displayName: 'Total Number of Clicks',
        },
      ];
    },
    tableData(): any {
      this.startLoading();
      let data: any = [];

      data = this.communications?.filter((comm: any) => comm.stores.length !== 0);
      if (data && data?.length > 0) {
        data?.forEach((item: any) => {
          let statusDesc = '';
          let store = item?.stores[0];
          if (this.modalData?.type === 'approval') {
            if (store?.statuses?.currentStatusId === Status.Modified) {
              statusDesc = 'Removed';
            } else if (store?.statuses?.currentStatusId === Status.Deleted) {
              statusDesc = 'Deleted';
            } else if (store?.statuses?.statusId === Status.Approved || store?.statuses?.socialStatusId === Status.Approved) {
              statusDesc = 'Approved';
            } else if (store?.statuses?.statusId === Status.Declined || store?.statuses?.socialStatusId === Status.Declined) {
              statusDesc = 'Declined';
            } else {
              statusDesc = 'Pending';
            }
          }
          item.companyDescription = item.company?.description;
          item.brandDescription = item.brand?.description;
          item.mallDesc = `${store.mall?.description}${store.storeIdentifier ? ` (${store.storeIdentifier})` : ''}`;
          item.mallUrl = store.mall?.url;
          item.statusDesc = statusDesc;
          item.sendDate = store?.statuses?.sendDate ?? '';
          item.actionDate = store?.statuses?.actionedDate ?? '';
          item.reason = store?.statuses?.reasonForDeclineDescription ?? '';
          item.responseTime = store?.responseTime ?? '';
          item.channels = this.metaChannelsData
            .filter((channel: { value: number; description: string }) => store?.statuses?.channels?.includes(channel.value))
            .map((channel: { value: number; description: string }) => channel.description);

          if (this.modalData.row.commType === 'marketing-campaign') {
            item.reviewUrl = `${window.location.protocol}//${window.location.host}/review/marketing-campaign?storeId=${store?.storeId}&marketingCampaignId=${store?.statuses?.marketingCampaignId}`;
          }
          if (this.modalData.row.commType === 'recruitment-campaign') {
            item.reviewUrl = `${window.location.protocol}//${window.location.host}/review/recruitment-campaign?storeId=${store?.storeId}&recruitmentCampaignId=${store?.statuses?.recruitmentCampaignId}`;
          }
          if (this.modalData.row.commType === 'mall-memo') {
            item.reviewUrl = `${window.location.protocol}//${window.location.host}/review/mall-memo?storeId=${store?.storeId}&memoId=${store?.statuses?.memoId}`;
          }
          if (this.modalData.row.commType === 'store-memo') {
            item.reviewUrl = `${window.location.protocol}//${window.location.host}/review/store-memo?storeId=${store?.storeId}&memoId=${store?.statuses?.memoId}`;
          }
          let primaryContact: any;
          const contactList: any[] = [];
          if (store?.mall?.contacts && store?.mall?.contacts?.length > 0) {
            store?.mall?.contacts.forEach((contact: IContact) => {
              if (contact?.emailType === 'to') {
                if (primaryContact) {
                  contactList.push(contact.email);
                } else {
                  primaryContact = contact;
                }
              } else {
                contactList.push(contact.email);
              }
            });
          }
          item.primaryContact = primaryContact;
          item.cc = contactList;
        });
      }

      // ADD FILTER BY TAB
      if (data && data?.length > 0) {
        if (this.activeTab === 'approved') {
          data = data?.filter(
            (communication: any) =>
              communication?.stores[0].statuses?.statusId === Status.Approved ||
              communication?.stores[0].statuses?.socialStatusId === Status.Approved,
          );
        }
        if (this.activeTab === 'pending') {
          data = data?.filter(
            (communication: any) =>
              !(
                communication?.stores[0].statuses?.statusId === Status.Approved ||
                communication?.stores[0].statuses?.socialStatusId === Status.Approved
              ) &&
              !(
                communication?.stores[0].statuses?.statusId === Status.Declined ||
                communication?.stores[0].statuses?.socialStatusId === Status.Declined
              ),
          );
        }
        if (this.activeTab === 'declined') {
          data = data?.filter(
            (communication: any) =>
              !(
                communication?.stores[0].statuses?.statusId === Status.Approved ||
                communication?.stores[0].statuses?.socialStatusId === Status.Approved
              ) &&
              (communication?.stores[0].statuses?.statusId === Status.Declined ||
                communication?.stores[0].statuses?.socialStatusId === Status.Declined),
          );
        }
      }

      // filter by search string
      if (data && data?.length > 0) {
        if (this.searchFilter !== '') {
          let searchResults: any[] = [];
          data.forEach((element: any) => {
            let matchFound = false;
            if (typeof Object.values(element) === 'string') {
              if (Object.values(element).includes(this.searchFilter)) {
                matchFound = true;
              }
            }
            if (typeof Object.values(element) === 'number') {
              if (
                Object.values(element)
                  .toString()
                  .includes(this.searchFilter)
              ) {
                matchFound = true;
              }
            }
            if (typeof Object.values(element) === 'object' && Object.values(element)) {
              Object.values(element)
                .filter((value) => value)
                .forEach((nestedElement: any) => {
                  if (typeof nestedElement === 'string') {
                    if (nestedElement.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                      matchFound = true;
                    }
                  }
                  if (typeof nestedElement === 'number') {
                    if (nestedElement.toString().includes(this.searchFilter)) {
                      matchFound = true;
                    }
                  }
                  if (typeof nestedElement === 'object' && nestedElement) {
                    Object.values(nestedElement)
                      .filter((value) => value)
                      .forEach((nestedElementL2: any) => {
                        if (typeof nestedElementL2 === 'string') {
                          if (nestedElementL2.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                            matchFound = true;
                          }
                        }
                        if (typeof nestedElementL2 === 'number') {
                          if (nestedElementL2.toString().includes(this.searchFilter)) {
                            matchFound = true;
                          }
                        }
                        if (typeof nestedElementL2 === 'object' && nestedElementL2) {
                          Object.values(nestedElementL2)
                            .filter((value) => value)
                            .forEach((nestedElementL3: any) => {
                              if (typeof nestedElementL3 === 'string') {
                                if (nestedElementL3.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                                  matchFound = true;
                                }
                              }
                              if (typeof nestedElementL3 === 'number') {
                                if (nestedElementL3.toString().includes(this.searchFilter)) {
                                  matchFound = true;
                                }
                              }
                            });
                        }
                      });
                  }
                });
            }
            if (matchFound) {
              searchResults.push(element);
            }
          });
          data = searchResults;
        }
      }
      // sorting
      if (data && data?.length > 0) {
        if (this.sortDefinition.direction === 'ASC') {
          if (this.sortDefinition.type === 'text') {
            data.sort((a: any, b: any) =>
              a[this.sortDefinition.column] > b[this.sortDefinition.column]
                ? 1
                : b[this.sortDefinition.column] > a[this.sortDefinition.column]
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'date') {
            data.sort((a: any, b: any) =>
              new Date(a[this.sortDefinition.column]) > new Date(b[this.sortDefinition.column])
                ? 1
                : new Date(b[this.sortDefinition.column]) > new Date(a[this.sortDefinition.column])
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'approval') {
            data.sort((a: any, b: any) =>
              a[this.sortDefinition.column].percentage > b[this.sortDefinition.column].percentage
                ? 1
                : b[this.sortDefinition.column].percentage > a[this.sortDefinition.column].percentage
                ? -1
                : 0,
            );
          }
        } else {
          if (this.sortDefinition.type === 'text') {
            data.sort((a: any, b: any) =>
              a[this.sortDefinition.column] < b[this.sortDefinition.column]
                ? 1
                : b[this.sortDefinition.column] < a[this.sortDefinition.column]
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'date') {
            data.sort((a: any, b: any) =>
              new Date(a[this.sortDefinition.column]) < new Date(b[this.sortDefinition.column])
                ? 1
                : new Date(b[this.sortDefinition.column]) < new Date(a[this.sortDefinition.column])
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'approval') {
            data.sort((a: any, b: any) =>
              a[this.sortDefinition.column].percentage < b[this.sortDefinition.column].percentage
                ? 1
                : b[this.sortDefinition.column].percentage < a[this.sortDefinition.column].percentage
                ? -1
                : 0,
            );
          }
        }
        // update all table data available for pagination to use
        this.updateAllTableData(data);
        // pagination
        let pageData: any[] = [];
        let startingPoint = 0;
        let pageValue: any = this.pageValue;
        let showValue: any = this.showValue;
        if (pageValue.value > 1) {
          startingPoint = showValue.value * (pageValue.value - 1);
        }
        for (let i = startingPoint; i < startingPoint + showValue.value && i < this.allTableData.length; i++) {
          pageData.push(data[i]);
        }
        data = pageData;
      }
      this.finishLoading();
      if (data && data.length > 0) {
        this.resetCheckboxes(data);
      }
      return data;
    },
    tableOptions(): any {
      return {
        selectable: false,
        actions: false,
      };
    },
    buttonOptions(): any {
      return {
        showCreate: false,
        showReports: false,
        showExport: true,
      };
    },
    pageOptions(): IDropdownOption[] {
      let tableData: any = this.allTableData;
      let showValue: any = this.showValue.value;
      let options = [];
      let numberOfPages = Math.ceil(tableData.length / showValue);
      for (let i = 0; i < numberOfPages; i++) {
        options.push({ description: `${i + 1}`, value: i + 1 });
      }
      return options;
    },
    totalPages(): number {
      let tableData: any = this.allTableData;
      let showValue: any = this.showValue.value;
      return Math.ceil(tableData.length / showValue);
    },
  },
  data(): {
    searchFilter: string;
    showValue: IDropdownOption;
    showOptions: IDropdownOption[];
    pageValue: IDropdownOption;
    allTableData: any;
    sortDefinition: SortDefinition;
    activeTab: string;
    checked: boolean[];
    allChecked: boolean;
    singleSelects: boolean[];
    selectedRows: any[];
    loading: boolean;
    loadingEmails: boolean;
    showSelected: boolean;
    showMasterCheckbox: boolean;
    promptOpen: boolean;
    promptType: string;
    selectedReason: string;
    reasonDescription: string;
    channels: [];
    declinedObject: any;
    approvedObject: any;
    questionObject: any;
    declining: boolean;
    approving: boolean;
    campaignId: string;
    memoId: string;
    communication: any;
    communications: any;
    submitting: boolean;
    buttonFeedback: {
      exportLoading: boolean;
      exportSubmitted: boolean;
    };
    mallLink: string;
  } {
    return {
      searchFilter: '',
      showValue: { description: '25', value: 25 },
      showOptions: [
        { description: '10', value: 10 },
        { description: '25', value: 25 },
        { description: '50', value: 50 },
        { description: '100', value: 100 },
      ],
      pageValue: { description: '1', value: 1 },
      allTableData: [],
      sortDefinition: { column: 'commId', type: 'text', direction: 'DESC' },
      activeTab: 'sent',
      checked: [],
      allChecked: false,
      singleSelects: [],
      selectedRows: [],
      loading: true,
      loadingEmails: true,
      showSelected: false,
      showMasterCheckbox: true,
      promptOpen: false,
      promptType: '',
      selectedReason: '',
      reasonDescription: '',
      channels: [],
      declinedObject: null,
      approvedObject: null,
      questionObject: null,
      declining: false,
      approving: false,
      campaignId: '',
      memoId: '',
      communication: null,
      communications: [],
      submitting: false,
      buttonFeedback: {
        exportLoading: false,
        exportSubmitted: false,
      },
      mallLink: '',
    };
  },
  async mounted(): Promise<void> {
    // this.fetchDeclineReasons();
    this.fetchChannels();
    // this.fetchContactChannels(this.user?.userId);
    this.activeTab = this.modalData.status;
    if (this.modalData?.row?.commType === 'marketing-campaign') {
      if (this.access?.marketingCampaignsAll || this.access?.marketingCampaignsMall) {
        this.communications = await this.fetchMarketingCampaignReview({
          type: 'report',
          mallId: this.modalData?.row?.mallId,
          userId: this.user?.userId,
        });
      }
      if (this.access?.marketingCampaignsCompany || this.access?.marketingCampaignsBrand || this.access?.marketingCampaignsStore) {
        this.communications = await this.fetchMarketingCampaignReview({
          type: 'report',
          id: this.modalData?.row?.storeId,
          userId: this.user?.userId,
        });
      }
    }
    if (this.modalData?.row?.commType === 'recruitment-campaign') {
      if (this.access?.recruitmentCampaignsAll || this.access?.recruitmentCampaignsMall) {
        this.communications = await this.fetchRecruitmentCampaignReview({
          type: 'report',
          mallId: this.modalData?.row?.mallId,
          userId: this.user?.userId,
        });
      }
      if (this.access?.recruitmentCampaignsCompany || this.access?.recruitmentCampaignsBrand) {
        this.communications = await this.fetchRecruitmentCampaignReview({
          type: 'report',
          id: this.modalData?.row?.storeId,
          userId: this.user?.userId,
        });
      }
    }
    if (this.modalData?.row?.commType === 'mall-memo') {
      if (this.access?.mallMemosAll || this.access?.mallMemosMall) {
        this.communications = await this.fetchMallMemoReview({
          type: 'report',
          mallId: this.modalData?.row?.mallId,
          userId: this.user?.userId,
        });
        this.communications = this.communications?.data;
      }
      if (this.access?.mallMemosCompany || this.access?.mallMemosBrand) {
        this.communications = await this.fetchMallMemoReview({
          type: 'report',
          id: this.modalData?.row?.storeId,
          userId: this.user?.userId,
        });
        this.communications = this.communications?.data;
      }
    }
    if (this.modalData?.row?.commType === 'store-memo') {
      this.communications = await this.fetchStoreMemoReview({
        type: 'report',
        id: this.modalData?.row?.storeId,
        userId: this.user?.userId,
      });
      this.communications = this.communications?.data;
    }
  },
  methods: {
    ...mapActions([
      'fetchMarketingCampaigns',
      'fetchRecruitmentCampaigns',
      'fetchMallMemos',
      'fetchStoreMemos',
      'fetchMarketingCampaignReview',
      'fetchRecruitmentCampaignReview',
      'fetchMallMemoReview',
      'fetchStoreMemoReview',
      'setMarketingCampaignReviewStoreStatus',
      'setRecruitmentCampaignReviewStoreStatus',
      'setMallMemoReviewStoreStatus',
      'setStoreMemoReviewStoreStatus',
      'fetchDeclineReasons',
      'fetchChannels',
      'fetchContactChannels',
      'fetchMallsChannels',
      'fetchSystemEmails',
    ]),
    startLoading(): void {
      this.loading = true;
    },
    finishLoading(): void {
      this.loading = false;
    },
    resetPageValue(): void {
      this.pageValue = { description: '1', value: 1 };
    },
    updateAllTableData(data: any): void {
      this.allTableData = data;
    },
    updateShow(filter: IDropdownOption) {
      this.resetPageValue();
      this.showValue = filter;
    },
    updatePage(filter: IDropdownOption) {
      this.pageValue = filter;
    },
    previousPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value - 1}`,
        value: pageValue.value - 1,
      };
    },
    nextPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value + 1}`,
        value: pageValue.value + 1,
      };
    },
    search(searchFilter: string): void {
      this.resetPageValue();
      this.searchFilter = searchFilter;
    },
    sort(payload: SortDefinition) {
      this.resetPageValue();
      this.sortDefinition = payload;
    },
    updateActiveTab(tab: string) {
      this.activeTab = tab;
      this.resetPageValue();
    },
    toggleAllCheckboxes(): void {
      this.allChecked = !this.allChecked;
      let i = 0;
      this.checked.forEach(() => {
        this.checked[i] = this.allChecked;
        this.singleSelects[i] = this.allChecked;
        this.selectedRows = this.allChecked ? this.tableData : [];
        i++;
      });
    },
    resetCheckboxes(data: any): void {
      this.checked = [];
      this.singleSelects = [];
      this.allChecked = false;
      data.forEach(() => {
        this.checked.push(false);
        this.singleSelects.push(false);
      });
      this.selectedRows = [];
      this.showMasterCheckbox = false;
      setTimeout(() => {
        this.showMasterCheckbox = true;
      }, 50);
    },
    checkboxClicked(index: any): void {
      this.singleSelects[index] = !this.singleSelects[index];
    },
    async submitQuestions(): Promise<void> {
      let allQuestionsAnswered = true;
      this.communication?.questions?.forEach((question: any) => {
        if (question?.answer === '') {
          allQuestionsAnswered = false;
        }
      });
      if (allQuestionsAnswered) {
        this.submitting = true;
        this.questionObject.answers = this.communication?.questions;
        if (this.modalData.row.commType === 'mall-memo') {
          await this.setMallMemoReviewStoreStatus(this.questionObject);
          await this.fetchMallMemoReview({
            memoId: this.memoId,
            type: 'user',
          });
        }
        if (this.modalData.row.commType === 'store-memo') {
          await this.setStoreMemoReviewStoreStatus(this.questionObject);
          await this.fetchStoreMemoReview({
            memoId: this.memoId,
            type: 'user',
          });
        }
        this.questionObject = null;
        this.submitting = false;
        this.closePrompt();
        this.fetchMarketingCampaigns();
        this.fetchRecruitmentCampaigns();
        this.fetchMallMemos();
        this.fetchStoreMemos();
      } else {
        this.$notify({
          title: 'WARNING',
          text: 'Please answer all questions to submit',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async actionClicked(event: any): Promise<void> {
      if (this.modalData.row.commType === 'marketing-campaign') {
        let obj: any = {
          campaignId: this.campaignId,
          storeIds: [event.row.storeId],
          reasonForDeclineDescription: '',
        };
        if (event.type === 'accepted') {
          obj.statusId = 8;
          this.approvedObject = obj;
          this.mallLink = `${window.location.protocol}//${window.location.host}/review/mall-details?mallId=${event.row.mallId}`;
          await this.fetchMallsChannelsHelper([event.row.storeId]);
          this.openPrompt('accept');
        }
        if (event.type === 'reset') {
          obj.statusId = 16;
          await this.setMarketingCampaignReviewStoreStatus(obj);
          await this.fetchMarketingCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
          });
        }
        if (event.type === 'declined') {
          obj.statusId = 9;
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        let obj: any = {
          campaignId: this.campaignId,
          storeIds: [event.row.storeId],
          reasonForDeclineDescription: '',
        };
        if (event.type === 'accepted') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 8;
          }
          this.approvedObject = obj;
          this.mallLink = `${window.location.protocol}//${window.location.host}/review/mall-details?mallId=${event.row.mallId}`;
          await this.fetchMallsChannelsHelper([event.row.storeId]);
          this.openPrompt('accept');
        }
        if (event.type === 'reset') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 16;
          }
          await this.setRecruitmentCampaignReviewStoreStatus(obj);
          await this.fetchRecruitmentCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
          });
        }
        if (event.type === 'declined') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 9;
          }
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'mall-memo') {
        let obj: any = {
          memoId: this.memoId,
          storeIds: [event.row.storeId],
          reasonForDeclineDescription: '',
        };
        if (event.type === 'accepted') {
          obj.statusId = 8;
          if (this.communication?.questions && this.communication?.questions?.length > 0) {
            obj.answers = [];
            this.communication?.questions?.forEach((question: any) => {
              question.answer = '';
              question.answers = [];
              question.answers[0] = {
                memoQuestionId: question?.memoQuestionId,
                description: '',
                userId: this.user?.userId,
              };
            });
            this.questionObject = obj;
            this.openPrompt('question');
          } else {
            await this.setMallMemoReviewStoreStatus(obj);
            await this.fetchMallMemoReview({
              memoId: this.memoId,
              type: 'user',
            });
          }
        }
        if (event.type === 'reset') {
          obj.statusId = 16;
          await this.setMallMemoReviewStoreStatus(obj);
          await this.fetchMallMemoReview({
            memoId: this.memoId,
            type: 'user',
          });
        }
        if (event.type === 'declined') {
          obj.statusId = 9;
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'store-memo') {
        let obj: any = {
          memoId: this.memoId,
          storeIds: [event.row.storeId],
          reasonForDeclineDescription: '',
        };
        if (event.type === 'accepted') {
          obj.statusId = 8;
          if (this.communication?.questions && this.communication?.questions?.length > 0) {
            obj.answers = [];
            this.communication?.questions?.forEach((question: any) => {
              question.answer = '';
              question.answers = [];
              question.answers[0] = {
                memoQuestionId: question?.memoQuestionId,
                description: '',
                userId: this.user?.userId,
              };
            });
            this.questionObject = obj;
            this.openPrompt('question');
          } else {
            await this.setStoreMemoReviewStoreStatus(obj);
            await this.fetchStoreMemoReview({
              memoId: this.memoId,
              type: 'user',
            });
          }
        }
        if (event.type === 'reset') {
          obj.statusId = 16;
          await this.setStoreMemoReviewStoreStatus(obj);
          await this.fetchStoreMemoReview({
            memoId: this.memoId,
            type: 'user',
          });
        }
        if (event.type === 'declined') {
          obj.statusId = 9;
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      this.fetchMarketingCampaigns();
      this.fetchRecruitmentCampaigns();
      this.fetchMallMemos();
      this.fetchStoreMemos();
    },
    async emailsClicked(event: any): Promise<void> {
      this.loadingEmails = true;
      this.openPrompt('emails');
      const communicationId = event.marketingCampaignId || event.recruitmentCampaignId || event.memoId;
      await this.fetchSystemEmails({ mallId: this.modalData?.row?.mallId, communicationId: communicationId });
      this.loadingEmails = false;
    },
    updateTabCounts(review: any): void {
      if (review.data) {
        review = review.data;
      }
      if (this.modalData?.type === 'approval') {
        const approvalCount = (review.stores as Array<any>).reduce((count, s) => (s.statuses.statusId === Status.Approved ? ++count : count), 0);
        const pendingCount = (review.stores as Array<any>).reduce((count, s) => (s.statuses.statusId === Status.Pending ? ++count : count), 0);
        const declineCount = (review.stores as Array<any>).reduce((count, s) => (s.statuses.statusId === Status.Declined ? ++count : count), 0);

        this.modalData.row.stats.approved = approvalCount;
        this.modalData.row.stats.pending = pendingCount;
        this.modalData.row.stats.declined = declineCount;
      }
    },
    async selectedActionClicked(event: string): Promise<void> {
      let selections: any = [];
      let mallIds: any = [];
      let index = 0;
      this.singleSelects.forEach((element) => {
        if (element) {
          selections.push(this.tableData[index].storeId);
          mallIds.push(this.tableData[index].mallId);
        }
        index++;
      });
      if (this.modalData.row.commType === 'marketing-campaign') {
        let obj: any = {
          campaignId: this.campaignId,
          storeIds: selections,
          reasonForDeclineDescription: '',
        };
        if (event === 'accepted') {
          obj.statusId = 8;
          this.approvedObject = obj;
          if (mallIds.length === 1) {
            this.mallLink = `${window.location.protocol}//${window.location.host}/review/mall-details?mallId=${mallIds[0]}`;
          }
          await this.fetchMallsChannelsHelper(selections);
          this.openPrompt('accept');
        }
        if (event === 'reset') {
          obj.statusId = 16;
          await this.setMarketingCampaignReviewStoreStatus(obj);
          await this.fetchMarketingCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
          });
        }
        if (event === 'declined') {
          obj.statusId = 9;
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        let obj: any = {
          campaignId: this.campaignId,
          storeIds: selections,
          reasonForDeclineDescription: '',
        };
        if (event === 'accepted') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 8;
          }
          this.approvedObject = obj;
          if (mallIds.length === 1) {
            this.mallLink = `${window.location.protocol}//${window.location.host}/review/mall-details?mallId=${mallIds[0]}`;
          }
          await this.fetchMallsChannelsHelper(selections);
          this.openPrompt('accept');
        }
        if (event === 'reset') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 16;
          }
          await this.setRecruitmentCampaignReviewStoreStatus(obj);
          await this.fetchRecruitmentCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
          });
        }
        if (event === 'declined') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 9;
          }
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'mall-memo') {
        let obj: any = {
          memoId: this.memoId,
          storeIds: selections,
          reasonForDeclineDescription: '',
        };
        if (event === 'accepted') {
          obj.statusId = 8;
          if (this.communication?.questions && this.communication?.questions?.length > 0) {
            obj.answers = [];
            this.communication?.questions?.forEach((question: any) => {
              question.answer = '';
              question.answers = [];
              question.answers[0] = {
                memoQuestionId: question?.memoQuestionId,
                description: '',
                userId: this.user?.userId,
              };
            });
            this.questionObject = obj;
            this.openPrompt('question');
          } else {
            await this.setMallMemoReviewStoreStatus(obj);
            await this.fetchMallMemoReview({
              memoId: this.memoId,
              type: 'user',
            });
          }
        }
        if (event === 'reset') {
          obj.statusId = 16;
          await this.setMallMemoReviewStoreStatus(obj);
          await this.fetchMallMemoReview({
            memoId: this.memoId,
            type: 'user',
          });
        }
        if (event === 'declined') {
          obj.statusId = 9;
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'store-memo') {
        let obj: any = {
          memoId: this.memoId,
          storeIds: selections,
          reasonForDeclineDescription: '',
        };
        if (event === 'accepted') {
          obj.statusId = 8;
          if (this.communication?.questions && this.communication?.questions?.length > 0) {
            obj.answers = [];
            this.communication?.questions?.forEach((question: any) => {
              question.answer = '';
              question.answers = [];
              question.answers[0] = {
                memoQuestionId: question?.memoQuestionId,
                description: '',
                userId: this.user?.userId,
              };
            });
            this.questionObject = obj;
            this.openPrompt('question');
          } else {
            await this.setStoreMemoReviewStoreStatus(obj);
            await this.fetchStoreMemoReview({
              memoId: this.memoId,
              type: 'user',
            });
          }
        }
        if (event === 'reset') {
          obj.statusId = 16;
          await this.setStoreMemoReviewStoreStatus(obj);
          await this.fetchStoreMemoReview({
            memoId: this.memoId,
            type: 'user',
          });
        }
        if (event === 'declined') {
          obj.statusId = 9;
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      this.fetchMarketingCampaigns();
      this.fetchRecruitmentCampaigns();
      this.fetchMallMemos();
      this.fetchStoreMemos();
    },
    async decline() {
      this.declining = true;
      this.declinedObject.reasonForDeclineId = 0;
      this.declinedObject.reasonForDeclineDescription = `${this.selectedReason}${this.reasonDescription ? ' - ' + this.reasonDescription : ''}`;
      if (this.modalData.row.commType === 'marketing-campaign') {
        await this.setMarketingCampaignReviewStoreStatus(this.declinedObject);
        await this.fetchMarketingCampaignReview({
          campaignId: this.campaignId,
          type: 'user',
        });
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        await this.setRecruitmentCampaignReviewStoreStatus(this.declinedObject);
        await this.fetchRecruitmentCampaignReview({
          campaignId: this.campaignId,
          type: 'user',
        });
      }
      if (this.modalData.row.commType === 'mall-memo') {
        await this.setMallMemoReviewStoreStatus(this.declinedObject);
        await this.fetchMallMemoReview({
          memoId: this.memoId,
          type: 'user',
        });
      }
      if (this.modalData.row.commType === 'store-memo') {
        await this.setStoreMemoReviewStoreStatus(this.declinedObject);
        await this.fetchStoreMemoReview({
          memoId: this.memoId,
          type: 'user',
        });
      }
      this.declining = false;
      this.closePrompt();
      this.fetchMarketingCampaigns();
      this.fetchRecruitmentCampaigns();
      this.fetchMallMemos();
      this.fetchStoreMemos();
    },
    async approve() {
      if (this.channels.length === 0) {
        this.$notify({
          title: 'CHANNELS REQUIRED',
          text: 'Please select at least one channel.',
          type: 'warn',
          duration: 5000,
        });
        return;
      }

      this.approving = true;
      this.approvedObject.channels = this.channels;
      this.approvedObject.adminApproval = true;
      if (this.modalData.row.commType === 'marketing-campaign') {
        await this.setMarketingCampaignReviewStoreStatus(this.approvedObject);
        let review = await this.fetchMarketingCampaignReview({
          campaignId: this.campaignId,
          type: 'user',
          userId: this.user?.userId,
        });
        this.updateTabCounts(review);
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        await this.setRecruitmentCampaignReviewStoreStatus(this.approvedObject);
        let review = await this.fetchRecruitmentCampaignReview({
          campaignId: this.campaignId,
          type: 'user',
          userId: this.user?.userId,
        });
        this.updateTabCounts(review);
      }
      this.approving = false;
      this.closePrompt();
      this.fetchMarketingCampaigns();
      this.fetchRecruitmentCampaigns();
      this.fetchMallMemos();
      this.fetchStoreMemos();
    },
    closePrompt(): void {
      this.promptOpen = false;
      this.selectedReason = '';
      this.reasonDescription = '';
      this.declinedObject = null;
      this.approvedObject = null;
      this.channels = [];
      this.mallLink = '';
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
      this.selectedReason = '';
      this.reasonDescription = '';
    },
    async export(): Promise<void> {
      const expStatus = {
        sent: 'all',
        pending: '16',
        approved: '8',
        declined: '9',
      };
      this.buttonFeedback.exportSubmitted = true;
      this.buttonFeedback.exportLoading = false;
      let URL = '';
      if (this.modalData?.row?.commType === 'marketing-campaign') {
        if (this.access?.marketingCampaignsAll || this.access?.marketingCampaignsMall) {
          URL = `${process.env.VUE_APP_URI}/exports/marketing-campaign-approvals?mallId=${this.modalData?.row?.mallId}&userId=${this.user?.userId}`;
        }
        if (this.access?.marketingCampaignsCompany || this.access?.marketingCampaignsBrand) {
          URL = `${process.env.VUE_APP_URI}/exports/marketing-campaign-approvals?storeId=${this.modalData?.row?.storeId}&userId=${this.user?.userId}`;
        }
      }
      if (this.modalData?.row?.commType === 'recruitment-campaign') {
        if (this.access?.recruitmentCampaignsAll || this.access?.recruitmentCampaignsMall) {
          URL = `${process.env.VUE_APP_URI}/exports/recruitment-campaign-approvals?mallId=${this.modalData?.row?.mallId}&userId=${this.user?.userId}`;
        }
        if (this.access?.recruitmentCampaignsCompany || this.access?.recruitmentCampaignsBrand) {
          URL = `${process.env.VUE_APP_URI}/exports/recruitment-campaign-approvals?storeId=${this.modalData?.row?.storeId}&userId=${this.user?.userId}`;
        }
      }
      if (this.modalData?.row?.commType === 'mall-memo') {
        if (this.access?.mallMemosAll || this.access?.mallMemosMall) {
          URL = `${process.env.VUE_APP_URI}/exports/mall-memo-approvals?mallId=${this.modalData?.row?.mallId}&userId=${this.user?.userId}`;
        }
        if (this.access?.mallMemosCompany || this.access?.mallMemosBrand) {
          URL = `${process.env.VUE_APP_URI}/exports/mall-memo-approvals?storeId=${this.modalData?.row?.storeId}&userId=${this.user?.userId}`;
        }
      }
      if (this.modalData?.row?.commType === 'store-memo') {
        URL = `${process.env.VUE_APP_URI}/exports/store-memo-approvals?storeId=${this.modalData?.row?.storeId}&userId=${this.user?.userId}`;
      }
      this.$notify({
        title: 'Export is in progress.',
        text: `You will receive an email with the required export file attached once it is ready. You can continue working in the app.`,
        type: 'info',
        duration: 12000,
      });
      setTimeout(() => {
        this.buttonFeedback.exportSubmitted = false;
      }, 12000);
      await axios.get(URL, {
        withCredentials: true,
      });
      // try {
      //   await axios.get(URL, {
      //     withCredentials: true,
      //   });

      //   this.buttonFeedback.exportSubmitted = true;
      //   this.$notify({
      //     title: 'Export is in progress.',
      //     text: `You will receive an email with a link to the export file once it is ready. You can continue working in the app.`,
      //     type: 'info',
      //     duration: 12000,
      //   });
      //   setTimeout(() => {
      //     this.buttonFeedback.exportSubmitted = false;
      //   }, 120000);
      // } catch (e) {
      //   this.buttonFeedback.exportSubmitted = true;
      //   this.buttonFeedback.exportLoading = false;
      //   this.$notify({
      //     text: "We couldn't process your export request, please try again.",
      //     type: 'error',
      //     duration: 3000,
      //   });
      //   setTimeout(() => {
      //     this.buttonFeedback.exportSubmitted = false;
      //   }, 3000);
      // }
    },
    async fetchMallsChannelsHelper(storeIds: number[]) {
      if (this.modalData.row.commType === 'marketing-campaign') {
        await this.fetchMallsChannels({ storeIds: storeIds, marketingCampaignId: this.campaignId });
      } else if (this.modalData.row.commType === 'recruitment-campaign') {
        await this.fetchMallsChannels({ storeIds: storeIds, recruitmentCampaignId: this.campaignId });
      } else {
        await this.fetchMallsChannels({ storeIds: storeIds });
      }
    },
  },
});
