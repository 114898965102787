/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosResponse } from 'axios';
import { AbortHandler } from './AbortHandler';

const abortController = AbortHandler.getInstance();

export default class HttpService {
  private apiUrl = process.env.VUE_APP_URI;

  // ███╗   ███╗███████╗████████╗ █████╗
  // ████╗ ████║██╔════╝╚══██╔══╝██╔══██╗
  // ██╔████╔██║█████╗     ██║   ███████║
  // ██║╚██╔╝██║██╔══╝     ██║   ██╔══██║
  // ██║ ╚═╝ ██║███████╗   ██║   ██║  ██║
  // ╚═╝     ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝

  public getCommunicationsByMonth(firstDay: string, lastDay: string): Promise<AxiosResponse> {
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(`${this.apiUrl}/meta/calendar?firstDay=${firstDay}&lastDay=${lastDay}`, {
      withCredentials: true,
      signal: controller.signal,
    });
  }

  public export(title: string, schema: any, data: any): Promise<AxiosResponse> {
    const body = { title, schema, data };

    return axios.post(`${this.apiUrl}/meta/export`, body, {
      withCredentials: true,
    });
  }

  public getMetaContactTypes(type?: string): Promise<AxiosResponse> {
    let qp = '';
    if (type) {
      qp = `?${type}=true`;
    }

    return axios.get(`${this.apiUrl}/meta/contact-types${qp}`, {
      withCredentials: true,
    });
  }

  public getGoogleInvites(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/google-invites`, {
      withCredentials: true,
    });
  }

  public acceptGoogleInvite({ inviteId }: { inviteId: string }) {
    return axios.post(`${this.apiUrl}/meta/google-invites/${inviteId}/accept`, null, {
      withCredentials: true,
    });
  }

  public declineGoogleInvite({ inviteId }: { inviteId: string }) {
    return axios.post(`${this.apiUrl}/meta/google-invites/${inviteId}/decline`, null, {
      withCredentials: true,
    });
  }

  public getMetaChannels(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/channels/`, {
      withCredentials: true,
    });
  }

  public getMetaContactGroups(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/meta-contact-groups/`, {
      withCredentials: true,
    });
  }

  public getMetaContactApprovals(id: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/contacts/${id}/approvals`, {
      withCredentials: true,
    });
  }

  public getMetaCommunicationTypes(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/communication-types/`, {
      withCredentials: true,
    });
  }

  public getMetaMarketingCampaignTypes(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/marketing-campaign-types/`, {
      withCredentials: true,
    });
  }

  public getMetaRecruitmentCampaignTypes(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/job-types/`, {
      withCredentials: true,
    });
  }

  public getMetaLanguages(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const pageParam = page ? `?page=${page}` : '';
    const limitParam = limit ? `&limit=${limit}` : '';
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '';

    return axios.get(`${this.apiUrl}/meta/languages${pageParam}${limitParam}${searchParam}${sortKeyParam}${sortDirectionParam}`, {
      withCredentials: true,
    });
  }

  public saveLanguage(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/meta/languages`, body, {
      withCredentials: true,
    });
  }

  public getMetaMallTypes(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/mall-types/`, {
      withCredentials: true,
    });
  }

  public getMetaStoreStatuses(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/store-statuses/`, {
      withCredentials: true,
    });
  }

  public getMetaUsers(companyId: string, mallId: string, brandId: string, userId: string, admins: boolean): Promise<AxiosResponse> {
    const companyParam = companyId ? `companyId=${companyId}` : '';
    const mallParam = mallId ? `mallId=${mallId}` : '';
    const brandParam = brandId ? `brandId=${brandId}` : '';
    const userParam = userId ? `userId=${userId}` : '';
    const adminParam = admins ? `admins=${admins}` : '';

    const param = companyParam || mallParam || brandParam || userParam || adminParam;

    return axios.get(`${this.apiUrl}/meta/users?${param}`, {
      withCredentials: true,
    });
  }

  public getMetaUsersByEmail(email: boolean): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/users?email=${email}`, {
      withCredentials: true,
    });
  }

  public getMetaUserRoles(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/user-roles/`, {
      withCredentials: true,
    });
  }

  public getMetaUserStatuses(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/user-statuses/`, {
      withCredentials: true,
    });
  }

  public getMetaCompanyStatuses(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/company-statuses/`, {
      withCredentials: true,
    });
  }

  public getMetaBrandStatuses(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/brand-statuses/`, {
      withCredentials: true,
    });
  }

  public getMetaBrandCategories(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/brand-categories/`, {
      withCredentials: true,
    });
  }

  public getMetaCountries(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const pageParam = page ? `?page=${page}` : '';
    const limitParam = limit ? `&limit=${limit}` : '';
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '';

    return axios.get(`${this.apiUrl}/meta/countries${pageParam}${limitParam}${searchParam}${sortKeyParam}${sortDirectionParam}`, {
      withCredentials: true,
    });
  }

  public saveCountry(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/meta/countries`, body, {
      withCredentials: true,
    });
  }

  public getMetaCompanies(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/companies/`, {
      withCredentials: true,
    });
  }

  public getMetaBrands(companyId: string): Promise<AxiosResponse> {
    const companyParam = companyId ? `?companyId=${companyId}` : '';

    return axios.get(`${this.apiUrl}/meta/brands${companyParam}`, {
      withCredentials: true,
    });
  }

  public getMetaMallOwners(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/mall-owners/`, {
      withCredentials: true,
    });
  }

  public getMetaProvinces(
    countryId?: number,
    page?: number,
    limit?: number,
    search?: string,
    sortKey?: string,
    sortDirection?: string,
  ): Promise<AxiosResponse> {
    const countryIdParam = countryId ? `?countryId=${countryId}` : '';
    const pageParam = page ? `?page=${page}` : '';
    const limitParam = limit ? `&limit=${limit}` : '';
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '';

    return axios.get(`${this.apiUrl}/meta/provinces${countryIdParam}${pageParam}${limitParam}${searchParam}${sortKeyParam}${sortDirectionParam}`, {
      withCredentials: true,
    });
  }

  public saveProvince(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/meta/provinces`, body, {
      withCredentials: true,
    });
  }

  public getTagOptions(brandIds: string[], type?: string): Promise<AxiosResponse> {
    const typeParam = type ? `&type=${type}` : '';

    return axios.get(`${this.apiUrl}/meta/tag-options?brandIds=${encodeURIComponent(brandIds.join(','))}${typeParam}`, {
      withCredentials: true,
    });
  }

  public getMetaCompanyTags(companyId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/available-company-tags?companyId=${companyId}`, {
      withCredentials: true,
    });
  }

  public getMetaDeclineReasons(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/reasons`, {
      withCredentials: true,
    });
  }

  public getMetaMallOptions(companyId?: string): Promise<AxiosResponse> {
    const companyIdParam = companyId ? `?companyId=${companyId}` : '';

    return axios.get(`${this.apiUrl}/meta/malls${companyIdParam}`, {
      withCredentials: true,
    });
  }

  public getMetaPermissionTemplateOptions(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/permission-templates`, {
      withCredentials: true,
    });
  }

  // public getContactGroups(): Promise<AxiosResponse> {
  //   return axios.get(`${this.apiUrl}/meta/contact-groups`, {
  //     withCredentials: true,
  //   });
  // }

  public getContact(id: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/contacts/${id}`, {
      withCredentials: true,
    });
  }

  public getContactGroups(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';

    return axios.get(`${this.apiUrl}/meta/contact-groups?page=${page ?? 1}&limit=${limit ?? 25}${searchParam}${sortKeyParam}${sortDirectionParam}`, {
      withCredentials: true,
    });
  }

  public getContactGroup(id: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/contact-groups/${id}`, {
      withCredentials: true,
    });
  }

  public saveContact(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/meta/contact-groups/`, body, {
      withCredentials: true,
    });
  }

  public updateContact(body: any, id: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/meta/contact-groups/${id}`, body, {
      withCredentials: true,
    });
  }

  public deleteContact(id: string): Promise<AxiosResponse> {
    return axios.delete(`${this.apiUrl}/meta/contact-groups/${id}`, {
      withCredentials: true,
    });
  }

  public exportContactApprovals(approvals: string): Promise<AxiosResponse> {
    return axios.post(
      `${this.apiUrl}/exports/contact-approvals`,
      { approvals },
      {
        withCredentials: true,
      },
    );
  }

  public getMetaStoreOptionsByMallId(mallId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/stores?mallId=${mallId}`, {
      withCredentials: true,
    });
  }

  public getMetaMemoSubtypeOptions(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/memo-subtypes`, {
      withCredentials: true,
    });
  }

  public getMetaAppleCategories(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/apple-categories`, {
      withCredentials: true,
    });
  }

  public getTags(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=tagId';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(`${this.apiUrl}/meta/tags?page=${page ?? 1}&limit=${limit ?? 25}${searchParam}${sortKeyParam}${sortDirectionParam}`, {
      withCredentials: true,
      signal: controller.signal,
    });
  }

  public getTagById(tagId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/tags/${tagId}`, {
      withCredentials: true,
    });
  }

  public saveTag(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/meta/tags`, body, {
      withCredentials: true,
    });
  }

  public updateTag(body: any, tagId: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/meta/tags/${tagId}`, body, {
      withCredentials: true,
    });
  }

  public deleteTag(tagId: string): Promise<AxiosResponse> {
    return axios.delete(`${this.apiUrl}/meta/tags/${tagId}`, {
      withCredentials: true,
    });
  }

  public getNotifications(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=notificationId';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';

    return axios.get(`${this.apiUrl}/meta/notifications?page=${page ?? 1}&limit=${limit ?? 25}${searchParam}${sortKeyParam}${sortDirectionParam}`, {
      withCredentials: true,
    });
  }

  public saveNotification(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/meta/notifications`, body, {
      withCredentials: true,
    });
  }

  public getNotification(id: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/notifications/${id}`, {
      withCredentials: true,
    });
  }

  public updateNotification(body: any, id: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/meta/notifications/${id}`, body, {
      withCredentials: true,
    });
  }

  public deleteNotification(id: string): Promise<AxiosResponse> {
    return axios.delete(`${this.apiUrl}/meta/notifications/${id}`, {
      withCredentials: true,
    });
  }

  public getUserNotifications(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/user-notifications`, {
      withCredentials: true,
    });
  }

  public dismissUserNotification(notificationId: string): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/meta/user-notifications/${notificationId}`, null, {
      withCredentials: true,
    });
  }

  public getEmailTemplates(type: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/email-templates?type=${type}`, {
      withCredentials: true,
    });
  }

  public saveSystemEvent(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/meta/system-events`, body, {
      withCredentials: true,
    });
  }

  public getLinkInformationViaPublicId(publicId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/links/${publicId}`, {
      withCredentials: false,
    });
  }

  public saveFollowUpEmailReminder(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/meta/follow-up-email`, body, {
      withCredentials: true,
    });
  }

  public getEmailCommunication(id: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/meta/email-communications/${id}`, {
      withCredentials: true,
    });
  }

  public getEmailCommunications(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '';

    return axios.get(`${this.apiUrl}/meta/email-communications?page=${page ?? 1}&limit=${limit ?? 25}${searchParam}${sortKeyParam}${sortDirectionParam}`, {
      withCredentials: true,
    });
  }

  public saveEmailCommunication(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/meta/email-communications`, body, {
      withCredentials: true,
    });
  }

  public getCcComponent(marketingCampaignId: string, mallId?: string, storeIds?: string[]): Promise<AxiosResponse> {
    const mallIdParam = mallId ? `&mallId=${mallId}` : '';
    const storeIdsParam = storeIds ? `&storeIds=${storeIds.join(',')}` : '';

    return axios.get(`${this.apiUrl}/meta/cc-component?marketingCampaignId=${marketingCampaignId}${mallIdParam}${storeIdsParam}`, {
      withCredentials: true,
    });
  }

  public getContextVariables(context: string, subContext?: string): Promise<AxiosResponse> {
    const subContextParam = subContext ? `&subContext=${subContext}` : '';

    return axios.get(`${this.apiUrl}/meta/context-variables?context=${context}${subContextParam}`, {
      withCredentials: true,
    });
  }

  // public generateRetailerActivityReport(): Promise<AxiosResponse> {
  //   return axios.get(`${this.apiUrl}/admin/retailer-activity-report`, {
  //     withCredentials: true,
  //     responseType: 'blob'
  //   });
  // }

  //  █████╗ ██╗   ██╗████████╗██╗  ██╗
  // ██╔══██╗██║   ██║╚══██╔══╝██║  ██║
  // ███████║██║   ██║   ██║   ███████║
  // ██╔══██║██║   ██║   ██║   ██╔══██║
  // ██║  ██║╚██████╔╝   ██║   ██║  ██║
  // ╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚═╝  ╚═╝

  public login(payload: { email: string; password: string }): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/auth/login`, payload, {
      withCredentials: true,
    });
  }

  public requestResetPassword(payload: { email: string }): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/auth/reset`, payload, {
      withCredentials: true,
    });
  }

  public verifyVerificationCode(payload: { email: string; code: string }): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/auth/verify-code`, payload, {
      withCredentials: true,
    });
  }

  public setPassword(payload: { email: string; password: string }): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/auth/set-password`, payload, {
      withCredentials: true,
    });
  }

  //  ██████╗ ██████╗ ███╗   ███╗███╗   ███╗██╗   ██╗███╗   ██╗██╗ ██████╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
  // ██╔════╝██╔═══██╗████╗ ████║████╗ ████║██║   ██║████╗  ██║██║██╔════╝██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
  // ██║     ██║   ██║██╔████╔██║██╔████╔██║██║   ██║██╔██╗ ██║██║██║     ███████║   ██║   ██║██║   ██║██╔██╗ ██║
  // ██║     ██║   ██║██║╚██╔╝██║██║╚██╔╝██║██║   ██║██║╚██╗██║██║██║     ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
  // ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚═╝ ██║╚██████╔╝██║ ╚████║██║╚██████╗██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
  //  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝     ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝ ╚═════╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

  public getMarketingCampaigns(
    page?: number,
    limit?: number,
    search?: string,
    sortKey?: string,
    sortDirection?: string,
    status?: number,
    tags?: number[],
  ): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const statusParam = status ? `&statusId=${status}` : '';
    const tagsParam = tags ? `&tagIds=${tags.join(',')}` : '';

    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/marketing-campaigns/v2?page=${page ?? 1}&limit=${limit ??
        25}${searchParam}${sortKeyParam}${sortDirectionParam}${statusParam}${tagsParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public getMarketingCampaignApprovals(marketingCampaignId: string): Promise<AxiosResponse> {
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(`${this.apiUrl}/marketing-campaigns/v2/${marketingCampaignId}`, {
      withCredentials: true,
      signal: controller.signal,
    });
  }

  public getMarketingCampaign(campaignId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/marketing-campaigns/${campaignId}`, {
      withCredentials: true,
    });
  }

  public saveMarketingCampaign(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/marketing-campaigns`, body, {
      withCredentials: true,
    });
  }

  public updateMarketingCampaign(body: any, campaignId: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/marketing-campaigns/${campaignId}`, body, {
      withCredentials: true,
    });
  }

  public deleteMarketingCampaign(campaignId: string): Promise<AxiosResponse> {
    return axios.delete(`${this.apiUrl}/marketing-campaigns/${campaignId}`, {
      withCredentials: true,
    });
  }

  public getMallMarketingCampaigns(
    contactGroupId: string,
    page?: number,
    limit?: number,
    search?: string,
    sortKey?: string,
    sortDirection?: string,
  ): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';

    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/marketing-campaigns/mall?contactGroupId=${contactGroupId}&page=${page ?? 1}&limit=${limit ??
        25}${searchParam}${sortKeyParam}${sortDirectionParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public getMallMarketingCampaignApprovals(contactGroupId: string, marketingCampaignId: string): Promise<AxiosResponse> {
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(`${this.apiUrl}/marketing-campaigns/mall/${marketingCampaignId}?contactGroupId=${contactGroupId}`, {
      withCredentials: true,
      signal: controller.signal,
    });
  }

  public getRecruitmentCampaigns(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/recruitment-campaigns?page=${page ?? 1}&limit=${limit ?? 25}${searchParam}${sortKeyParam}${sortDirectionParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public getMallRecruitmentCampaigns(
    contactGroupId: string,
    page?: number,
    limit?: number,
    search?: string,
    sortKey?: string,
    sortDirection?: string,
  ): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';

    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/recruitment-campaigns/mall?contactGroupId=${contactGroupId}&page=${page ?? 1}&limit=${limit ??
        25}${searchParam}${sortKeyParam}${sortDirectionParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public getRecruitmentCampaign(campaignId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/recruitment-campaigns/${campaignId}`, {
      withCredentials: true,
    });
  }

  public saveRecruitmentCampaign(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/recruitment-campaigns`, body, {
      withCredentials: true,
    });
  }

  public updateRecruitmentCampaign(body: any, campaignId: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/recruitment-campaigns/${campaignId}`, body, {
      withCredentials: true,
    });
  }

  public deleteRecruitmentCampaign(campaignId: string): Promise<AxiosResponse> {
    return axios.delete(`${this.apiUrl}/recruitment-campaigns/${campaignId}`, {
      withCredentials: true,
    });
  }

  public getMallMemos(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(`${this.apiUrl}/memos/malls?page=${page ?? 1}&limit=${limit ?? 25}${searchParam}${sortKeyParam}${sortDirectionParam}`, {
      withCredentials: true,
      signal: controller.signal,
    });
  }

  public getMallMallMemos(
    contactGroupId: string,
    page?: number,
    limit?: number,
    search?: string,
    sortKey?: string,
    sortDirection?: string,
  ): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';

    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/memos/malls-mall?contactGroupId=${contactGroupId}&page=${page ?? 1}&limit=${limit ??
        25}${searchParam}${sortKeyParam}${sortDirectionParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public getMallMemo(memoId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/memos/malls/${memoId}`, {
      withCredentials: true,
    });
  }

  public saveMallMemo(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/memos/malls`, body, {
      withCredentials: true,
    });
  }

  public updateMallMemo(body: any, memoId: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/memos/malls/${memoId}`, body, {
      withCredentials: true,
    });
  }

  public deleteMallMemo(memoId: string): Promise<AxiosResponse> {
    return axios.delete(`${this.apiUrl}/memos/malls/${memoId}`, {
      withCredentials: true,
    });
  }

  public getStoreMemos(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(`${this.apiUrl}/memos/stores?page=${page ?? 1}&limit=${limit ?? 25}${searchParam}${sortKeyParam}${sortDirectionParam}`, {
      withCredentials: true,
      signal: controller.signal,
    });
  }

  public getStoreMemo(memoId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/memos/stores/${memoId}`, {
      withCredentials: true,
    });
  }

  public saveStoreMemo(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/memos/stores`, body, {
      withCredentials: true,
    });
  }

  public updateStoreMemo(body: any, memoId: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/memos/stores/${memoId}`, body, {
      withCredentials: true,
    });
  }

  public deleteStoreMemo(memoId: string): Promise<AxiosResponse> {
    return axios.delete(`${this.apiUrl}/memos/stores/${memoId}`, {
      withCredentials: true,
    });
  }

  // ██╗   ██╗███████╗███████╗██████╗
  // ██║   ██║██╔════╝██╔════╝██╔══██╗
  // ██║   ██║███████╗█████╗  ██████╔╝
  // ██║   ██║╚════██║██╔══╝  ██╔══██╗
  // ╚██████╔╝███████║███████╗██║  ██║
  //  ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝

  public getUsers(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(`${this.apiUrl}/users?page=${page ?? 1}&limit=${limit ?? 25}${searchParam}${sortKeyParam}${sortDirectionParam}`, {
      withCredentials: true,
      signal: controller.signal,
    });
  }

  public getUser(userId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/users/${userId}`, {
      withCredentials: true,
    });
  }

  public getPermissionTemplates(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/users/permission-templates`, {
      withCredentials: true,
    });
  }

  public saveUser(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/users/`, body, {
      withCredentials: true,
    });
  }

  public updateUser(body: any, userId: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/users/${userId}`, body, {
      withCredentials: true,
    });
  }

  public deleteUser(userId: string): Promise<AxiosResponse> {
    return axios.delete(`${this.apiUrl}/users/${userId}`, {
      withCredentials: true,
    });
  }

  public exportUsers(data: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/users/export`, data, {
      withCredentials: true,
    });
  }

  public saveUserEmailTemplate(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/users/email-templates`, body, {
      withCredentials: true,
    });
  }

  public saveUserEmailPreference(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/users/email-preferences`, body, {
      withCredentials: true,
    });
  }

  // ██████╗ ██████╗  █████╗ ███╗   ██╗██████╗
  // ██╔══██╗██╔══██╗██╔══██╗████╗  ██║██╔══██╗
  // ██████╔╝██████╔╝███████║██╔██╗ ██║██║  ██║
  // ██╔══██╗██╔══██╗██╔══██║██║╚██╗██║██║  ██║
  // ██████╔╝██║  ██║██║  ██║██║ ╚████║██████╔╝
  // ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝

  public getBrands(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(`${this.apiUrl}/brands?page=${page ?? 1}&limit=${limit ?? 25}${searchParam}${sortKeyParam}${sortDirectionParam}`, {
      withCredentials: true,
      signal: controller.signal,
    });
  }

  public getBrandById(brandId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/brands/${brandId}`, {
      withCredentials: true,
    });
  }

  public saveBrand(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/brands`, body, {
      withCredentials: true,
    });
  }

  public updateBrand(body: any, brandId: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/brands/${brandId}`, body, {
      withCredentials: true,
    });
  }

  public deleteBrand(brandId: string): Promise<AxiosResponse> {
    return axios.delete(`${this.apiUrl}/brands/${brandId}`, {
      withCredentials: true,
    });
  }

  public saveBrandEmailTemplate(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/brands/email-templates`, body, {
      withCredentials: true,
    });
  }

  //  ██████╗ ██████╗ ███╗   ███╗██████╗  █████╗ ███╗   ██╗██╗   ██╗
  // ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔══██╗████╗  ██║╚██╗ ██╔╝
  // ██║     ██║   ██║██╔████╔██║██████╔╝███████║██╔██╗ ██║ ╚████╔╝
  // ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██╔══██║██║╚██╗██║  ╚██╔╝
  // ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ██║  ██║██║ ╚████║   ██║
  //  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝     ╚═╝  ╚═╝╚═╝  ╚═══╝   ╚═╝

  public getCompanies(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(`${this.apiUrl}/companies?page=${page ?? 1}&limit=${limit ?? 25}${searchParam}${sortKeyParam}${sortDirectionParam}`, {
      withCredentials: true,
      signal: controller.signal,
    });
  }

  public getCompany(companyId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/companies/${companyId}`, {
      withCredentials: true,
    });
  }

  public saveCompany(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/companies`, body, {
      withCredentials: true,
    });
  }

  public updateCompany(body: any, companyId: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/companies/${companyId}`, body, {
      withCredentials: true,
    });
  }

  public deleteCompany(companyId: string): Promise<AxiosResponse> {
    return axios.delete(`${this.apiUrl}/companies/${companyId}`, {
      withCredentials: true,
    });
  }

  // ███╗   ███╗ █████╗ ██╗     ██╗
  // ████╗ ████║██╔══██╗██║     ██║
  // ██╔████╔██║███████║██║     ██║
  // ██║╚██╔╝██║██╔══██║██║     ██║
  // ██║ ╚═╝ ██║██║  ██║███████╗███████╗
  // ╚═╝     ╚═╝╚═╝  ╚═╝╚══════╝╚══════╝

  public getMalls(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(`${this.apiUrl}/malls?page=${page ?? 1}&limit=${limit ?? 25}${searchParam}${sortKeyParam}${sortDirectionParam}`, {
      withCredentials: true,
      signal: controller.signal,
    });
  }

  public getMallById(mallId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/malls/${mallId}`, {
      withCredentials: true,
    });
  }

  public saveMall(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/malls/`, body, {
      withCredentials: true,
    });
  }

  public updateMall(body: any, mallId: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/malls/${mallId}`, body, {
      withCredentials: true,
    });
  }

  public updateChangeRequest(body: any, requestId: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/malls/change-requests/${requestId}`, body, {
      withCredentials: true,
    });
  }

  public updateMallRequest(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/reviews/mall-details`, body, {
      withCredentials: false,
    });
  }

  public getReviewPageInformationViaPublicId(publicId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/reviews/links/${publicId}`, {
      withCredentials: false,
    });
  }

  public getMallChangeRequests(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=changeRequestId';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/malls/change-requests?page=${page ?? 1}&limit=${limit ?? 25}${searchParam}${sortKeyParam}${sortDirectionParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public deleteMall(mallId: string): Promise<AxiosResponse> {
    return axios.delete(`${this.apiUrl}/malls/${mallId}`, {
      withCredentials: true,
    });
  }

  public addStoreMemoToMalls(): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/malls/add-store-memo-to-malls`, {
      withCredentials: true,
    });
  }

  // ███████╗████████╗ ██████╗ ██████╗ ███████╗
  // ██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗██╔════╝
  // ███████╗   ██║   ██║   ██║██████╔╝█████╗
  // ╚════██║   ██║   ██║   ██║██╔══██╗██╔══╝
  // ███████║   ██║   ╚██████╔╝██║  ██║███████╗
  // ╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝

  public getStores(
    page?: number,
    limit?: number,
    search?: string,
    sortKey?: string,
    sortDirection?: string,
    status?: number,
    tags?: number[],
    supportsMarketing?: string,
  ): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const statusParam = status ? `&statusId=${status}` : '';
    const tagsParam = tags ? `&tagIds=${tags.join(',')}` : '';
    const supportsMarketingParam = supportsMarketing ? `&supportsMarketing=${supportsMarketing}` : '';

    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/stores/v2?page=${page ?? 1}&limit=${limit ??
        25}${searchParam}${sortKeyParam}${sortDirectionParam}${statusParam}${tagsParam}${supportsMarketingParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public getStoreById(storeId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/stores/${storeId}`, {
      withCredentials: true,
    });
  }

  public getStoresByBrandId(brandId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/stores?brandIds=${brandId}`, {
      withCredentials: true,
    });
  }

  public getStoresByBrandIds(brandIds: string[]): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/stores?brandIds=${encodeURIComponent(brandIds.join(','))}`, {
      withCredentials: true,
    });
  }

  public getStoresByCommunicationType(communicationType: string, brandId: string, mallId?: string): Promise<AxiosResponse> {
    const mallParam = mallId ? `&mallId=${mallId}` : '';

    return axios.get(`${this.apiUrl}/stores?communicationType=${communicationType}&brandIds=${brandId}${mallParam}`, {
      withCredentials: true,
    });
  }

  public saveStore(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/stores`, body, {
      withCredentials: true,
    });
  }

  public updateStore(body: any, storeId: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/stores/${storeId}`, body, {
      withCredentials: true,
    });
  }

  public deleteStore(storeId: string): Promise<AxiosResponse> {
    return axios.delete(`${this.apiUrl}/stores/${storeId}`, {
      withCredentials: true,
    });
  }

  public undeleteStore(storeId: string): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/stores/${storeId}/undelete`, null, {
      withCredentials: true,
    });
  }

  // ██████╗ ███████╗██████╗  ██████╗ ██████╗ ████████╗
  // ██╔══██╗██╔════╝██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝
  // ██████╔╝█████╗  ██████╔╝██║   ██║██████╔╝   ██║
  // ██╔══██╗██╔══╝  ██╔═══╝ ██║   ██║██╔══██╗   ██║
  // ██║  ██║███████╗██║     ╚██████╔╝██║  ██║   ██║
  // ╚═╝  ╚═╝╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝

  public getMarketingCampaignsReport(
    page?: number,
    limit?: number,
    search?: string,
    sortKey?: string,
    sortDirection?: string,
    closedStores?: boolean,
    tags?: number[],
  ): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const closedStoresParam = closedStores ? `&closedStores=${closedStores}` : '';
    const tagsParam = tags ? `&tagIds=${tags.join(',')}` : '';

    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/reports/marketing-campaigns?page=${page ?? 1}&limit=${limit ??
        25}${searchParam}${sortKeyParam}${sortDirectionParam}${closedStoresParam}${tagsParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public getRecruitmentCampaignsReport(
    page?: number,
    limit?: number,
    search?: string,
    sortKey?: string,
    sortDirection?: string,
    closedStores?: boolean,
  ): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const closedStoresParam = closedStores ? `&closedStores=${closedStores}` : '';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/reports/recruitment-campaigns?page=${page ?? 1}&limit=${limit ??
        25}${searchParam}${sortKeyParam}${sortDirectionParam}${closedStoresParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public getMallMemosReport(
    page?: number,
    limit?: number,
    search?: string,
    sortKey?: string,
    sortDirection?: string,
    closedStores?: boolean,
  ): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const closedStoresParam = closedStores ? `&closedStores=${closedStores}` : '';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/reports/mall-memos?page=${page ?? 1}&limit=${limit ??
        25}${searchParam}${sortKeyParam}${sortDirectionParam}${closedStoresParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public getStoreMemosReport(
    page?: number,
    limit?: number,
    search?: string,
    sortKey?: string,
    sortDirection?: string,
    closedStores?: boolean,
  ): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const closedStoresParam = closedStores ? `&closedStores=${closedStores}` : '';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/reports/store-memos?page=${page ?? 1}&limit=${limit ??
        25}${searchParam}${sortKeyParam}${sortDirectionParam}${closedStoresParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public getMallAdminMarketingCampaignsReport(
    page?: number,
    limit?: number,
    search?: string,
    sortKey?: string,
    sortDirection?: string,
    closedStores?: boolean,
  ): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const closedStoresParam = closedStores ? `&closedStores=${closedStores}` : '';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/reports/mall-admin-marketing-campaigns?page=${page ?? 1}&limit=${limit ??
        25}${searchParam}${sortKeyParam}${sortDirectionParam}${closedStoresParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public getMallAdminRecruitmentCampaignsReport(
    page?: number,
    limit?: number,
    search?: string,
    sortKey?: string,
    sortDirection?: string,
    closedStores?: boolean,
  ): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const closedStoresParam = closedStores ? `&closedStores=${closedStores}` : '';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/reports/mall-admin-recruitment-campaigns?page=${page ?? 1}&limit=${limit ??
        25}${searchParam}${sortKeyParam}${sortDirectionParam}${closedStoresParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public getOpportunitiesReport(
    page?: number,
    limit?: number,
    search?: string,
    sortKey?: string,
    sortDirection?: string,
    closedStores?: boolean,
  ): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const closedStoresParam = closedStores ? `&closedStores=${closedStores}` : '';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/reports/opportunities?page=${page ?? 1}&limit=${limit ??
        25}${searchParam}${sortKeyParam}${sortDirectionParam}${closedStoresParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public getOverallMarketingCampaignApprovalReport(
    page?: number,
    limit?: number,
    search?: string,
    sortKey?: string,
    sortDirection?: string,
    closedStores?: boolean,
    tags?: number[],
  ): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const closedStoresParam = closedStores ? `&closedStores=${closedStores}` : '';
    const tagsParam = tags ? `&tagIds=${tags.join(',')}` : '';

    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/reports/overall-marketing-campaigns-approval?page=${page ?? 1}&limit=${limit ??
        25}${searchParam}${sortKeyParam}${sortDirectionParam}${closedStoresParam}${tagsParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  // ██████╗ ███████╗██╗   ██╗██╗███████╗██╗    ██╗
  // ██╔══██╗██╔════╝██║   ██║██║██╔════╝██║    ██║
  // ██████╔╝█████╗  ██║   ██║██║█████╗  ██║ █╗ ██║
  // ██╔══██╗██╔══╝  ╚██╗ ██╔╝██║██╔══╝  ██║███╗██║
  // ██║  ██║███████╗ ╚████╔╝ ██║███████╗╚███╔███╔╝
  // ╚═╝  ╚═╝╚══════╝  ╚═══╝  ╚═╝╚══════╝ ╚══╝╚══╝

  public getMarketingCampaignReview(type: string, campaignId?: string, id?: string, userId?: string, mallId?: string): Promise<AxiosResponse> {
    let url = '';
    if (type === 'contact') {
      url = `${this.apiUrl}/reviews/marketing-campaigns?contactId=${id}&marketingCampaignId=${campaignId}`;
    }
    if (type === 'store') {
      url = `${this.apiUrl}/reviews/marketing-campaigns?storeId=${id}&marketingCampaignId=${campaignId}`;
    }
    if (type === 'user') {
      url = `${this.apiUrl}/reviews/marketing-campaigns?userId=${userId}&marketingCampaignId=${campaignId}`;
    }
    if (type === 'report') {
      const param = mallId ? `mallId=${mallId}` : `storeId=${id}`;
      url = `${this.apiUrl}/reviews/marketing-campaigns?userId=${userId}&${param}`;
    }

    return axios.get(url, {
      withCredentials: true,
    });
  }

  public setMarketingCampaignReviewStoreStatus(
    campaignId: string,
    storeIds: number[],
    statusId: number,
    reasonForDeclineDescription: string,
    route?: string,
    channels?: number[],
    currentStatusId?: number,
    adminApproval?: boolean,
  ): Promise<AxiosResponse> {
    const store = statusId ? `&statusId=${statusId}` : '';

    return axios.post(
      `${this.apiUrl}/reviews/marketing-campaigns?marketingCampaignId=${campaignId}${store}`,
      {
        storeIds,
        reasonForDeclineDescription,
        route,
        channels,
        currentStatusId,
        adminApproval,
      },
      { withCredentials: true },
    );
  }

  public getRecruitmentCampaignReview(type: string, campaignId?: string, id?: string, userId?: string, mallId?: string): Promise<AxiosResponse> {
    let url = '';
    if (type === 'contact') {
      url = `${this.apiUrl}/reviews/recruitment-campaigns?contactId=${id}&recruitmentCampaignId=${campaignId}`;
    }
    if (type === 'store') {
      url = `${this.apiUrl}/reviews/recruitment-campaigns?storeId=${id}&recruitmentCampaignId=${campaignId}`;
    }
    if (type === 'user') {
      url = `${this.apiUrl}/reviews/recruitment-campaigns?userId=${userId}&recruitmentCampaignId=${campaignId}`;
    }
    if (type === 'report') {
      const param = mallId ? `mallId=${mallId}` : `storeId=${id}`;
      url = `${this.apiUrl}/reviews/recruitment-campaigns?userId=${userId}&${param}`;
    }

    return axios.get(url, {
      withCredentials: true,
    });
  }

  public setRecruitmentCampaignReviewStoreStatus(
    campaignId: string,
    storeIds: number[],
    statusId: number,
    reasonForDeclineDescription: string,
    channels?: number[],
    currentStatusId?: number,
    adminApproval?: boolean,
    route?: string | undefined,
  ): Promise<AxiosResponse> {
    const store = statusId ? `&statusId=${statusId}` : '';

    return axios.post(
      `${this.apiUrl}/reviews/recruitment-campaigns?recruitmentCampaignId=${campaignId}${store}`,
      {
        storeIds,
        reasonForDeclineDescription,
        channels,
        currentStatusId,
        adminApproval,
        route,
      },
      {
        withCredentials: true,
      },
    );
  }

  public getMallMemoReview(type: string, memoId?: string, id?: string, userId?: string, mallId?: string): Promise<AxiosResponse> {
    let url = '';
    if (type === 'contact') {
      url = `${this.apiUrl}/reviews/mall-memos?contactId=${id}&memoId=${memoId}`;
    }
    if (type === 'store') {
      url = `${this.apiUrl}/reviews/mall-memos?storeId=${id}&memoId=${memoId}`;
    }
    if (type === 'user') {
      url = `${this.apiUrl}/reviews/mall-memos?memoId=${memoId}&userId=${userId}`;
    }
    if (type === 'report') {
      const param = mallId ? `mallId=${mallId}` : `storeId=${id}`;
      url = `${this.apiUrl}/reviews/mall-memos?userId=${userId}&${param}`;
    }

    return axios.get(url, {
      withCredentials: true,
    });
  }

  public setMallMemoReviewStoreStatus(
    memoId: string,
    storeIds: number[],
    statusId: number,
    reasonForDeclineDescription: string,
    answers: any[] = [],
    currentStatusId?: number,
    route?: string | undefined,
  ): Promise<AxiosResponse> {
    const store = statusId ? `&statusId=${statusId}` : '';

    return axios.post(
      `${this.apiUrl}/reviews/mall-memos?memoId=${memoId}${store}`,
      {
        storeIds,
        reasonForDeclineDescription,
        answers,
        route,
        currentStatusId,
      },
      {
        withCredentials: true,
      },
    );
  }

  public getStoreMemoReview(type: string, memoId?: string, id?: string, userId?: string): Promise<AxiosResponse> {
    let url = '';
    if (type === 'contact') {
      url = `${this.apiUrl}/reviews/store-memos?contactId=${id}&memoId=${memoId}`;
    }
    if (type === 'store') {
      url = `${this.apiUrl}/reviews/store-memos?storeId=${id}&memoId=${memoId}`;
    }
    if (type === 'user') {
      url = `${this.apiUrl}/reviews/store-memos?memoId=${memoId}&userId=${userId}`;
    }
    if (type === 'report') {
      url = `${this.apiUrl}/reviews/store-memos?userId=${userId}&storeId=${id}`;
    }

    return axios.get(url, {
      withCredentials: true,
    });
  }

  public setStoreMemoReviewStoreStatus(
    memoId: string,
    storeIds: number[],
    statusId: number,
    reasonForDeclineDescription: string,
    answers: any[] = [],
    currentStatusId?: number,
    route?: string | undefined,
  ): Promise<AxiosResponse> {
    const store = statusId ? `&statusId=${statusId}` : '';

    return axios.post(
      `${this.apiUrl}/reviews/store-memos?memoId=${memoId}${store}`,
      {
        storeIds,
        reasonForDeclineDescription,
        answers,
        route,
        currentStatusId,
      },
      {
        withCredentials: true,
      },
    );
  }

  public getContactChannels(contactId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/reviews/channels?contactId=${contactId}`, {
      withCredentials: true,
    });
  }

  public getMallsChannels(storeIds: string[], marketingCampaignId?: string, recruitmentCampaignId?: string): Promise<AxiosResponse> {
    const marketingCampaignIdParam = marketingCampaignId ? `&marketingCampaignId=${marketingCampaignId}` : '';
    const recruitmentCampaignIdParam = recruitmentCampaignId ? `&recruitmentCampaignId=${recruitmentCampaignId}` : '';

    return axios.get(`${this.apiUrl}/reviews/channels?storeIds=${storeIds}${marketingCampaignIdParam}${recruitmentCampaignIdParam}`, {
      withCredentials: true,
    });
  }

  public getSystemEmails(communicationId: string, mallId?: string): Promise<AxiosResponse> {
    const mallIdParam = mallId ? `&mallId=${mallId}` : '';

    return axios.get(`${this.apiUrl}/reviews/system-emails?communicationId=${communicationId}${mallIdParam}`, {
      withCredentials: true,
    });
  }

  // EMAIL
  public getEmails(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';

    return axios.get(`${this.apiUrl}/v2/emails?page=${page ?? 1}&limit=${limit ?? 25}${searchParam}${sortKeyParam}${sortDirectionParam}`, {
      withCredentials: true,
    });
  }

  public getEmailById(emailId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/v2/emails/${emailId}`, {
      withCredentials: true,
    });
  }

  public saveEmail(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/v2/emails`, body, {
      withCredentials: true,
    });
  }

  public updateEmail(body: any, emailId: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/v2/emails/${emailId}`, body, {
      withCredentials: true,
    });
  }

  public disableEmail(emailId: string): Promise<AxiosResponse> {
    return axios.delete(`${this.apiUrl}/v2/emails/${emailId}`, {
      withCredentials: true,
    });
  }

  public getMarketingOpportunityReview(emailId?: string, opportunityId?: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/v2/emails/marketing-opportunities?emailId=${emailId}&opportunityId=${opportunityId}`, {
      withCredentials: true,
    });
  }

  public setMarketingOpportunityMallId(emailId: string, opportunityId: string, mallId: string): Promise<AxiosResponse> {
    return axios.post(
      `${this.apiUrl}/v2/emails/marketing-opportunities?emailId=${emailId}&opportunityId=${opportunityId}`,
      { mallId },
      {
        withCredentials: true,
      },
    );
  }

  public postSentryException(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/v2/emails/sentry-exception`, body, {
      withCredentials: true,
    });
  }

  // OPPORTUNITY
  public getOpportunities(page?: number, limit?: number, search?: string, sortKey?: string, sortDirection?: string): Promise<AxiosResponse> {
    const searchParam = search ? `&search=${search}` : '';
    const sortKeyParam = sortKey ? `&sortKey=${sortKey}` : '&sortKey=id';
    const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '&sortDirection=DESC';
    const controller = new AbortController();
    abortController.addController(controller);

    return axios.get(
      `${this.apiUrl}/v2/emails/opportunities?page=${page ?? 1}&limit=${limit ?? 25}${searchParam}${sortKeyParam}${sortDirectionParam}`,
      {
        withCredentials: true,
        signal: controller.signal,
      },
    );
  }

  public getOpportunityById(opportunityId: string): Promise<AxiosResponse> {
    return axios.get(`${this.apiUrl}/v2/emails/opportunities/${opportunityId}`, {
      withCredentials: true,
    });
  }

  public saveOpportunity(body: any): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/v2/emails/opportunities`, body, {
      withCredentials: true,
    });
  }

  public updateOpportunity(body: any, opportunityId: string): Promise<AxiosResponse> {
    return axios.put(`${this.apiUrl}/v2/emails/opportunities/${opportunityId}`, body, {
      withCredentials: true,
    });
  }

  public disableOpportunity(opportunityId: string): Promise<AxiosResponse> {
    return axios.delete(`${this.apiUrl}/v2/emails/opportunities/${opportunityId}`, {
      withCredentials: true,
    });
  }

  // ██╗   ██╗██████╗ ██╗      ██████╗  █████╗ ██████╗
  // ██║   ██║██╔══██╗██║     ██╔═══██╗██╔══██╗██╔══██╗
  // ██║   ██║██████╔╝██║     ██║   ██║███████║██║  ██║
  // ██║   ██║██╔═══╝ ██║     ██║   ██║██╔══██║██║  ██║
  // ╚██████╔╝██║     ███████╗╚██████╔╝██║  ██║██████╔╝
  //  ╚═════╝ ╚═╝     ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝

  public uploadImage(image: any): Promise<AxiosResponse> {
    const formData: FormData = new FormData();
    formData.append('file', image);

    return axios.post(`${this.apiUrl}/images/image-upload`, formData, {
      withCredentials: true,
    });
  }

  public uploadImageToApple(image: any, appleBusinessBusinessId: string, appleBusinessLocationId: string): Promise<AxiosResponse> {
    const formData: FormData = new FormData();
    formData.append('file', image);

    let appleBusiness = '';
    if (appleBusinessBusinessId) {
      appleBusiness = `?appleBusinessBusinessId=${appleBusinessBusinessId}`;
    } else if (appleBusinessLocationId) {
      appleBusiness = `?appleBusinessLocationId=${appleBusinessLocationId}`;
    }

    return axios.post(`${this.apiUrl}/images/apple-image-upload${appleBusiness}`, formData, {
      withCredentials: true,
    });
  }

  public generateImages(formData: FormData): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/images/images`, formData, {
      withCredentials: true,
    });
  }

  public generateRecruitmentImages(formData: FormData): Promise<AxiosResponse> {
    return axios.post(`${this.apiUrl}/images/recruitment-images`, formData, {
      withCredentials: true,
    });
  }
}
