
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../../atoms/Input/Input.vue';
import Dropdown from '../../../../atoms/Dropdown/Dropdown.vue';
import Button from '../../../../atoms/Button/Button.vue';
import Tooltip from '../../../../atoms/Tooltip/Tooltip.vue';
import Modal from '../../../../atoms/Modal/Modal.vue';
import { FormBuilder, FormControl } from '../../../../../utilities/Forms';
import { Validators } from '../../../../../utilities/Validators/Validators';

export default defineComponent({
  changesMade: {
    deep: true,
    handler() {
      this.$emit('hasChangesMade', this.changesMade);
    },
  },
  components: {
    'arc-input': Input,
    'arc-dropdown': Dropdown,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
  },

  computed: {
    ...mapGetters(['modalData', 'user', 'access', 'permissions']),
    updateDisabled(): boolean {
      return false;
    },
    deleteDisabled(): boolean {
      return true;
    },
  },

  watch: {
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
  },

  // async beforeUnmount(): Promise<void> {
  //   this.loadCountries();
  // },

  async mounted(): Promise<void> {
    // if (this.modalData?.countryId) {
    //   this.modalData.row = await this.fetchCountry({ countryId: this.modalData?.countryId });
    // }
    this.setForm();
    this.loaded = true;
  },
  data(): {
    status: string;
    changesMade: boolean;
    formValid: boolean;
    promptOpen: boolean;
    promptType: string;
    submitting: boolean;
    updating: boolean;
    saving: boolean;
    expiring: boolean;
    deleting: boolean;
    formData: any;
    loaded: boolean;
  } {
    return {
      status: 'Draft',
      changesMade: false,
      formValid: true,
      promptOpen: false,
      promptType: 'save',
      submitting: false,
      updating: false,
      saving: false,
      expiring: false,
      deleting: false,
      formData: null,
      loaded: false,
    };
  },

  methods: {
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateClosingModal',
      'saveCountry',
      'updateCountry',
      'deleteCountry',
      'fetchCountry',
      'loadCountries',
    ]),
    setForm(): void {
      let description = '';
      // set values if editing existing country
      if (this.modalData?.row) {
        description = this.modalData?.row?.description;
      }

      this.formData = new FormBuilder({
        description: new FormControl(description, [Validators.required]),
      });
    },
    setChangesMade() {
      this.changesMade = true;
    },
    validateControl(controlName: string): void {
      setTimeout(() => {
        this.formData.controls[controlName].validate();
        if (!this.changesMade) {
          this.setChangesMade();
        }
      }, 0);
    },
    closePrompt(): void {
      this.promptOpen = false;
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
    },
    deletePrompt(): void {
      this.openPrompt('delete');
    },
    // async delete(): Promise<void> {
    //   this.deleting = true;
    //   this.promptOpen = false;
    //
    //   try {
    //     await this.deleteCountry(this.modalData?.row?.countryId);
    //     this.deleting = false;
    //     this.promptOpen = false;
    //     this.updateClosingModal(true);
    //     setTimeout(() => {
    //       this.closeModal();
    //       this.clearMetadata();
    //       this.loadCountries();
    //       this.updateClosingModal(false);
    //       this.$notify({
    //         title: 'SUCCESS',
    //         text: 'Country deleted successfully!',
    //         type: 'success',
    //         duration: 5000,
    //       });
    //     }, 500);
    //   } catch (err) {
    //     this.deleting = false;
    //     this.promptOpen = false;
    //     this.$notify({
    //       title: 'ERROR',
    //       text: 'Unable to delete country.',
    //       type: 'error',
    //       duration: 5000,
    //     });
    //   }
    // },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.loadCountries();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.loadCountries();
        this.updateClosingModal(false);
      }, 500);
    },
    async save(): Promise<void> {
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors) {
        let payload = {
          description: this.formData?.controls?.description?.value,
        };

        this.saving = true;

        try {
          await this.saveCountry(payload);
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: 'Country saved successfully!',
            type: 'success',
            duration: 5000,
          });
          this.changesMade = false;
          this.closeModal();
          this.clearMetadata();
          this.loadCountries();
        } catch (err) {
          this.saving = false;
          const error: any = err;
          this.$notify({
            title: 'ERROR',
            text: error === 'There is already a Country associated with that name.' ? error : 'Unable to create Country',
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        this.saving = false;
        this.$notify({
          title: 'INVALID FORM',
          text: 'Country form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    // async update(): Promise<void> {
    //   this.formData.markAllAsTouched();
    //   if (!this.formData.hasErrors) {
    //     let payload = {
    //       description: this.formData?.controls?.description?.value,
    //       statusId: 2,
    //       appleBusinessCountryId: this.formData?.controls?.appleBusinessCountryId?.value,
    //     };
    //
    //     this.saving = true;
    //
    //     try {
    //       await this.updateCountry({
    //         payload,
    //         countryId: this.modalData?.row?.countryId,
    //       });
    //       this.saving = false;
    //       this.$notify({
    //         title: 'SUCCESS',
    //         text: 'Country updated successfully!',
    //         type: 'success',
    //         duration: 5000,
    //       });
    //       this.closeModal();
    //       this.clearMetadata();
    //     } catch (err) {
    //       this.saving = false;
    //       const error: any = err;
    //       this.$notify({
    //         title: 'ERROR',
    //         text: error === 'There is already a Country associated with that name.' ? error : 'Unable to update Country',
    //         type: 'error',
    //         duration: 5000,
    //       });
    //     }
    //   } else {
    //     this.$notify({
    //       title: 'INVALID FORM',
    //       text: 'Country form is incomplete or invalid!',
    //       type: 'warn',
    //       duration: 5000,
    //     });
    //   }
    // },
  },
});
