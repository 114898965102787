
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { IBrand, ILanguage, IStore } from '../../../../../../interfaces/src/v2';
import Button from '../../../atomic/atoms/Button/Button.vue';
import Loader from '../../../atomic/atoms/Loader/Loader.vue';
import Tooltip from '../../../atomic/atoms/Tooltip/Tooltip.vue';
import { dateFormatter, dateFormatterLocalized, timeFormatterLocalized } from '../../../utilities/Functions/formatting';
import Modal from '../../../atomic/atoms/Modal/Modal.vue';
import TextArea from '../../../atomic/atoms/Text-Area/Text-Area.vue';
import Checkbox from '../../../atomic/atoms/Checkbox/Checkbox.vue';
import Jimp from 'jimp';
import JSZip from 'jszip';
import { SocketUtility } from '../../../utilities/Sockets/SocketUtility';
import SocketService from '../../../services/SocketService';
import { SocketEvent } from '../../../enums/SocketEvent';
import { Status } from '@/enums/Status';

interface IUploadedImage {
  type: 'primary' | 'secondary';
  orientation: 'portrait' | 'landscape' | 'square';
  master: boolean;
  generated: boolean;
  language: string;
  height: number;
  width: number;
  aspectRatio: string;
  url: string;
}

export default defineComponent({
  components: {
    'arc-button': Button,
    'arc-loader': Loader,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
    'arc-text-area': TextArea,
    'arc-checkbox': Checkbox,
  },
  async mounted() {
    const customUrl = new Map<string, string>();
    const url = window.location.href;
    let requestId: string | null = null;

    if (new URL(url).pathname.includes('/r/rc/')) {
      const urlToken = new URL(url).pathname.split('/').pop();
      if (urlToken) {
        requestId = urlToken;
      }
    }

    if (requestId) {
      const urlInformation = await this.fetchReviewPageInformationViaPublicId(requestId);

      if (!urlInformation.valid) {
        this.urlInformation = {
          recruitmentCampaignId: '',
          storeId: '',
          contactId: '',
          contacts: '',
          showChanges: false,
          valid: false,
          route: '',
        };

        this.loading = false;
        return;
      }

      const queryParameters = urlInformation.data.queryParams;

      for (const query of queryParameters.replace('?', '').split('&')) {
        const [key, value] = query.split('=');
        customUrl.set(key, value);
      }

      const url = new URL(urlInformation.data.originalUrl);

      this.urlInformation = {
        recruitmentCampaignId: customUrl.get('recruitmentCampaignId') ?? '',
        storeId: customUrl.get('storeId') ?? '',
        contactId: customUrl.get('contactId') ?? '',
        contacts: customUrl.get('contacts') ?? '',
        showChanges: customUrl.get('showChanges') === 'true',
        valid: urlInformation.valid,
        route: `${url.pathname}${url.search}`,
      };
    } else {
      this.urlInformation = {
        recruitmentCampaignId: `${this.$route.query.recruitmentCampaignId}`,
        storeId: !!this.$route.query.storeId && this.$route.query.storeId !== 'undefined' ? `${this.$route.query.storeId}` : '',
        contactId: !!this.$route.query.contactId && this.$route.query.contactId !== 'undefined' ? `${this.$route.query.contactId}` : '',
        contacts: !!this.$route.query.contacts && this.$route.query.contacts !== 'undefined' ? `${this.$route.query.contacts}` : '',
        showChanges: this.$route.query.showChanges === 'true',
        valid: true,
        route: this.$route.fullPath,
      };
    }

    if (!SocketUtility.getRecruitmentCampaignSocket()) {
      SocketUtility.setRecruitmentCampaignSocket(new SocketService().recruitmentCampaignSocket);
    }

    SocketUtility.getRecruitmentCampaignSocket().on(SocketEvent.RECRUITMENT_CAMPAIGN_UPDATED, (recruitmentCampaignId: string) => {
      if (recruitmentCampaignId === this.urlInformation.recruitmentCampaignId) {
        this.showChangeWarning = true;
      }
    });

    this.response = await this.fetchRecruitmentCampaignReview({
      campaignId: this.urlInformation.recruitmentCampaignId,
      type: this.urlInformation.storeId ? 'store' : 'contact',
      id: this.urlInformation.storeId || this.urlInformation.contactId,
    });

    this.campaignId = this.urlInformation.recruitmentCampaignId as string;
    this.fetchDeclineReasons();
  },
  watch: {
    recruitmentCampaignReview: {
      deep: true,
      handler() {
        this.extractDataFromLocations(this.recruitmentCampaignReview?.recruitmentCampaign?.stores);
        this.campaign = this.recruitmentCampaignReview?.recruitmentCampaign;
        if (this.campaign?.stores && this.campaign?.stores?.length > 0 && this.initialLoad) {
          this.campaign.stores = this.campaign?.stores?.sort((a: any, b: any) => {
            // Removed stores go first
            if (a?.statuses?.currentStatusId === Status.Modified) {
              return -1;
            }
            if (b?.statuses?.currentStatusId === Status.Modified) {
              return 1;
            }
            // Deleted stores go last
            if (a?.statuses?.currentStatusId === Status.Deleted) {
              return 1;
            }
            if (b?.statuses?.currentStatusId === Status.Deleted) {
              return -1;
            }
            // Order middle by status if both active
            return b?.statuses?.statusId - a?.statuses?.statusId;
          });
          this.initialLoad = false;
        }
        this.uploadedImages = this.campaign?.images;
      },
    },
    uploadedImages: {
      deep: true,
      handler() {
        this.filterImages(this.uploadedImages);
      },
    },
    activeLanguage: {
      deep: true,
      handler() {
        this.filterImages(this.uploadedImages);
      },
    },
    loadingRecruitmentCampaignReview: {
      handler() {
        this.loading = this.loadingRecruitmentCampaignReview;
      },
    },
    stores: {
      handler() {
        this.total = this.stores?.length;
        this.approved = 0;
        this.pending = 0;
        this.declined = 0;
        let count = 0;
        this.stores?.forEach((store: any) => {
          if (store?.statuses?.statusId === Status.Approved || store?.statuses?.socialStatusId === Status.Approved) {
            this.approved++;
          } else if (store?.statuses?.statusId === Status.Declined || store?.statuses?.socialStatusId === Status.Declined) {
            this.declined++;
          } else {
            this.pending++;
          }
          this.checked[count] = false;
          this.singleSelects[count] = false;
          count++;
        });
      },
    },
  },
  computed: {
    Status() {
      return Status;
    },
    ...mapGetters([
      'modalData',
      'recruitmentCampaignReview',
      'loadingRecruitmentCampaignReview',
      'loadingStoreStatus',
      'declineReasons',
      'contactChannels',
      'mallsChannels',
    ]),
    mallLink(): string {
      const contactId = this.urlInformation.contactId;
      if (this.campaign?.stores?.length === 1) {
        const mallId = this.campaign?.stores[0]?.mallId;
        return `${window.location.protocol}//${window.location.host}/review/mall-details?mallId=${mallId}&contactId=${contactId}`;
      } else if (this.selectedStoreIds.length === 1) {
        const mallId = this.campaign?.stores.find((el: any) => el.storeId === this.selectedStoreIds[0])?.mallId;
        return `${window.location.protocol}//${window.location.host}/review/mall-details?mallId=${mallId}&contactId=${contactId}`;
      }
      return '';
    },
    mailto(): string {
      return `mailto:support@engagementagents.com?subject=Request%20Contact%20Change&body=I%20would%20like%20to%20request%20the%20following%20contact%20changes:`;
    },
    fromStyle(): string {
      return this.urlInformation.storeId ? 'width: 100%;' : '';
    },
    selected(): boolean {
      const selected = this.singleSelects.filter((item) => item === true);
      return selected.length > 0;
    },
    filteredLanguages() {
      const filteredLanguages: any[] = [];
      this.languages?.forEach((language: ILanguage) => {
        if (this.languageHasContent(language)) {
          filteredLanguages.push(language);
        }
      });
      this.languageSelected(filteredLanguages[0]);
      return filteredLanguages;
    },
    contacts(): any[] {
      let array: any[] = [];
      if (this.recruitmentCampaignReview?.fromEmail) {
        array.push({
          ...this.recruitmentCampaignReview?.contactInformation,
        });
      }
      return array;
    },
    stores(): any[] {
      return this.campaign?.stores;
    },
    images(): any[] {
      return this.campaign.images;
    },
    notes(): string {
      return this.campaign?.notes?.includes(':::') || this.campaign?.notes?.includes('en=') || this.campaign?.notes?.includes('fr=')
        ? this.getLanguageSpecificValue(this.languageParser(this.campaign?.notes), this.activeLanguage?.isoCode)
        : this.campaign?.notes;
    },
    headline(): string {
      return this.getLanguageSpecificValue(this.languageParser(this.campaign?.headline), this.activeLanguage?.isoCode);
    },
    description(): string {
      return this.getLanguageSpecificValue(this.languageParser(this.campaign?.longDescription), this.activeLanguage?.isoCode);
    },
    facebookDescription(): string {
      return this.getLanguageSpecificValue(this.languageParser(this.campaign?.facebookDescription), this.activeLanguage?.isoCode);
    },
    twitterDescription(): string {
      return this.getLanguageSpecificValue(this.languageParser(this.campaign?.twitterDescription), this.activeLanguage?.isoCode);
    },
    instagramDescription(): string {
      return this.getLanguageSpecificValue(this.languageParser(this.campaign?.instagramDescription), this.activeLanguage?.isoCode);
    },
    brand(): IBrand {
      return this.campaign?.brand;
    },
    toList(): string {
      const contacts = `${this.urlInformation.contacts}`;
      const array = contacts.split(', ');
      return array[0];
    },
    ccList(): string {
      let cc = 'Click below to add one or more CC contacts';
      const contacts = `${this.urlInformation.contacts}`;
      const array = contacts.split(',');
      if (array?.length > 1) {
        cc = '';
        array?.shift();
        array?.forEach((contact: string) => {
          cc += contact.replace(' ', ', ');
        });
      }
      return cc === 'Click below to add one or more CC contacts' ? cc : cc.slice(2, cc.length);
    },
    reasons(): any[] {
      return this.declineReasons?.data;
    },
    detailContainerStyle(): string {
      let style = '';
      if (!this.campaign || (!this.headline && !this.description && (!this.filteredImages || this.filteredImages?.length <= 0))) {
        style = 'border: none;';
      }
      return style;
    },
  },

  data(): {
    loading: boolean;
    total: number;
    approved: number;
    pending: number;
    declined: number;
    campaign: any;
    languages: ILanguage[];
    activeLanguage: ILanguage;
    uploadedImages: IUploadedImage[];
    primaryImagesUploading: boolean;
    secondaryImagesUploading: boolean;
    imageUrls: any[];
    filteredImages: any[];
    filteredImagesV2: any;
    declining: boolean;
    approving: boolean;
    promptOpen: boolean;
    promptType: string;
    selectedStoreIds: number[];
    selectedReason: string;
    reasonDescription: string;
    filteredChannelOptions: any[];
    selectedChannelIds: number[];
    checked: boolean[];
    allChecked: boolean;
    singleSelects: boolean[];
    showMasterCheckbox: boolean;
    tooltipText: string;
    campaignId: string;
    response: any;
    initialLoad: boolean;
    requirements: string[];
    hadApproved: boolean;
    reasonMessage: string;
    showChangeWarning: boolean;
    randomTime: number;
    approvedObject: any;
    urlInformation: {
      recruitmentCampaignId: string;
      storeId: string;
      contactId: string;
      contacts: string;
      showChanges: boolean;
      valid: boolean | undefined;
      route: string;
    };
  } {
    return {
      showChangeWarning: false,
      loading: true,
      total: 0,
      approved: 0,
      pending: 0,
      declined: 0,
      campaign: null,
      languages: [
        {
          languageId: 37,
          description: 'English',
          isoCode: 'en',
          updatedAt: '',
          createdAt: '',
        },
        {
          languageId: 47,
          description: 'French',
          isoCode: 'fr',
          updatedAt: '',
          createdAt: '',
        },
      ],
      activeLanguage: {
        languageId: 37,
        description: 'English',
        isoCode: 'en',
        updatedAt: '',
        createdAt: '',
      },
      uploadedImages: [],
      primaryImagesUploading: false,
      secondaryImagesUploading: false,
      imageUrls: [],
      filteredImages: [],
      filteredImagesV2: [],
      declining: false,
      approving: false,
      promptOpen: false,
      promptType: '',
      selectedStoreIds: [],
      selectedReason: '',
      reasonDescription: '',
      filteredChannelOptions: [],
      selectedChannelIds: [],
      checked: [],
      allChecked: false,
      singleSelects: [],
      showMasterCheckbox: true,
      tooltipText: 'click to copy',
      campaignId: '',
      response: null,
      initialLoad: true,
      requirements: [],
      hadApproved: false,
      reasonMessage: '',
      randomTime: new Date(Date.now()).getTime(),
      approvedObject: null,
      urlInformation: {
        recruitmentCampaignId: '',
        storeId: '',
        contactId: '',
        contacts: '',
        showChanges: false,
        valid: true,
        route: '',
      },
    };
  },

  methods: {
    ...mapActions([
      'fetchRecruitmentCampaignReview',
      'setRecruitmentCampaignReviewStoreStatus',
      'openModal',
      'updateModalType',
      'updateModalComponent',
      'updateModalData',
      'fetchDeclineReasons',
      'fetchContactChannels',
      'fetchMallsChannels',
      'fetchReviewPageInformationViaPublicId',
    ]),
    showChanges(): boolean {
      return this.urlInformation.showChanges;
    },
    hasChanges() {
      const hasLanguageChange = this.response?.changes?.some((item: any) => {
        if (item?.previousValue?.includes(':::') || item?.previousValue.includes('en=') || item?.previousValue.includes('fr=')) {
          this.filteredLanguages?.forEach((language: any) => {
            if (this.getValueByField(item?.currentValue, language) !== this.getValueByField(item?.previousValue, language)) {
              return item;
            }
          });
        }
      });

      const validChangedFields = this.response?.changes
        ?.map((el: any) => el.field)
        .filter((el: any) => !['headline', 'longDescription', 'twitterDescription', 'facebookDescription', 'instagramDescription'].includes(el));

      return hasLanguageChange || validChangedFields;
    },
    hasStoreNonPending() {
      return this.stores?.some((store: any) => store?.statuses?.statusId !== Status.Pending || store?.statuses?.socialStatusId !== Status.Pending);
    },
    hasStoreApproved() {
      if (this.initialLoad) {
        this.hadApproved = this.stores?.some((store: any) => store?.statuses?.statusId === Status.Approved || store?.statuses?.socialStatusId === Status.Approved);
      }
      return this.stores?.some((store: any) => store?.statuses?.statusId === Status.Approved || store?.statuses?.socialStatusId === Status.Approved);
    },
    checkedSameCurrentStatusId(): number | null {
      let checkedSameCurrentStatusId = true;
      let i = 0;
      let currentStatusId: any = undefined;
      this.checked.forEach(() => {
        if (this.checked[i] === true) {
          if (!currentStatusId) {
            currentStatusId = this.stores[i].statuses.currentStatusId;
          } else {
            if (this.stores[i].statuses.currentStatusId !== currentStatusId) {
              checkedSameCurrentStatusId = false;
            }
          }
        }
        i++;
      });
      if (checkedSameCurrentStatusId) {
        return currentStatusId;
      }
      return 0;
    },
    capitalize(value: string): string {
      return value[0].toUpperCase() + value.substring(1);
    },
    getValueByField(field: string, language: any): string {
      return this.getLanguageSpecificValue(this.languageParser(field), language?.isoCode);
    },
    getFieldDescription(field: string): string {
      let description = field;
      if (field === 'description') {
        description = 'Campaign Name';
      }
      if (field === 'longDescription') {
        description = 'Description';
      }
      if (field === 'fromDate') {
        description = 'Start Date';
      }
      if (field === 'toDate') {
        description = 'End Date';
      }
      if (field === 'publishByDate') {
        description = 'Post no later than';
      }
      if (field === 'notes') {
        description = 'Notes to mall';
      }
      if (field === 'facebookDescription') {
        description = 'Facebook';
      }
      if (field === 'instagramDescription') {
        description = 'Instagram';
      }
      if (field === 'twitterDescription') {
        description = 'twitter';
      }
      return this.capitalize(description);
    },
    getHeadline(language: any): string {
      return this.getLanguageSpecificValue(this.languageParser(this.campaign?.headline), language?.isoCode);
    },
    getDescription(language: any): string {
      return this.getLanguageSpecificValue(this.languageParser(this.campaign?.longDescription), language?.isoCode);
    },
    getFacebookDescription(language: any): string {
      return this.getLanguageSpecificValue(this.languageParser(this.campaign?.facebookDescription), language?.isoCode);
    },
    getTwitterDescription(language: any): string {
      return this.getLanguageSpecificValue(this.languageParser(this.campaign?.twitterDescription), language?.isoCode);
    },
    getInstagramDescription(language: any): string {
      return this.getLanguageSpecificValue(this.languageParser(this.campaign?.instagramDescription), language?.isoCode);
    },
    previewImage(image: any) {
      image.downloadLink = this.filterString(
        `${this.recruitmentCampaignReview?.recruitmentCampaign?.brand?.description} - Campaign #${
          this.recruitmentCampaignReview?.recruitmentCampaign?.campaignNumber
        } - ${this.recruitmentCampaignReview?.recruitmentCampaign?.description} - ${this.activeLanguage?.isoCode?.toUpperCase()} - ${image?.width}x${
          image?.height
        }`,
      );
      this.updateModalComponent('image-preview');
      this.updateModalType('image-preview');
      this.updateModalData(image);
      this.openModal();
    },
    filterString(value: string): string {
      return value.replace(/[%&#+=(){}|:;"'<,>?/\\~`@$^*[\]]/g, '');
    },
    closePrompt(): void {
      this.promptOpen = false;
      this.selectedStoreIds = [];
      this.selectedReason = '';
      this.reasonDescription = '';
      this.selectedChannelIds = [];
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
      this.selectedReason = '';
      this.reasonDescription = '';
    },
    approveStatusStyle(statusId: number): string {
      let style = '';
      if (statusId === Status.Approved) {
        style = 'background-color: #a7a7a7; border-color: #a7a7a7; cursor: default;';
      }
      return style;
    },
    declineStatusStyle(statusId: number): string {
      let style = '';
      if (statusId === Status.Declined) {
        style = 'background-color: #a7a7a7; border-color: #a7a7a7; cursor: default';
      }
      return style;
    },
    formatDate(date: string, showTime = false): string {
      return dateFormatter(date, showTime);
    },
    formatDateLocalized(date: string, showTime = false): string {
      if (showTime) {
        return `${dateFormatterLocalized(date)} at ${timeFormatterLocalized(date)}`;
      }
      return dateFormatterLocalized(date);
    },
    async approve() {
      if (this.selectedChannelIds.length === 0) {
        this.$notify({
          title: 'CHANNELS REQUIRED',
          text: 'Please select at least one channel.',
          type: 'warn',
          duration: 5000,
        });
        return;
      }

      this.approving = true;

      let obj: any = {
        campaignId: this.campaignId,
        storeIds: this.selectedStoreIds,
        reasonForDeclineDescription: '',
        route: this.urlInformation.route,
        statusId: Status.Approved,
        channels: this.selectedChannelIds,
      };

      const response = await this.setRecruitmentCampaignReviewStoreStatus(obj);
      if (response?.status === 200) {
        this.selectedStoreIds?.forEach((id: number) => {
          this.stores?.forEach((store: any) => {
            if (store?.storeId === `${id}`) {
              const r = response?.data.data.find((r: any) => r.storeId === `${id}`);

              if (store?.statuses?.statusId === Status.Declined || store?.statuses?.socialStatusId === Status.Declined) {
                this.declined--;
                this.approved++;
              }
              if (
                store?.statuses?.statusId !== Status.Approved &&
                store?.statuses?.socialStatusId !== Status.Approved &&
                store?.statuses?.statusId !== Status.Declined &&
                store?.statuses?.socialStatusId !== Status.Declined
              ) {
                this.pending--;
                this.approved++;
              }
              store.statuses.statusId = r.statusId;
              store.statuses.socialStatusId = r.socialStatusId;
              store.statuses.updatedAt = r.updatedAt;
              store.statuses.actionedDate = r.actionedDate;
              store.statuses.channels = r.channels;
              store.statuses.parsedChannels = r.parsedChannels;
            }
          });
        });
      }
      this.approving = false;
      this.resetCheckboxes(this.stores);
      this.closePrompt();
    },
    reset(storeIds: number[]) {
      let obj: any = {
        campaignId: this.campaignId,
        storeIds: storeIds,
        reasonForDeclineDescription: '',
        route: this.urlInformation.route,
        statusId: Status.Pending,
      };

      this.setRecruitmentCampaignReviewStoreStatus(obj);
      this.resetCheckboxes(this.stores);
    },
    async resetMulti() {
      let count = 0;
      let ids: any[] = [];
      this.singleSelects.forEach((item: boolean) => {
        if (item === true) {
          ids.push(this.stores[count].storeId);
        }
        count++;
      });
      let obj: any = {
        campaignId: this.campaignId,
        storeIds: ids,
        reasonForDeclineDescription: '',
        route: this.urlInformation.route,
        statusId: Status.Pending,
      };

      await this.setRecruitmentCampaignReviewStoreStatus(obj);
      this.resetCheckboxes(this.stores);
    },
    async confirmDelete(storeIds: number[]): Promise<void> {
      let obj: any = {
        campaignId: this.campaignId,
        storeIds: storeIds,
        currentStatusId: Status.Deleted,
        route: this.urlInformation.route,
      };

      const response = await this.setRecruitmentCampaignReviewStoreStatus(obj);
      if (response?.status === 200) {
        storeIds?.forEach((id: any) => {
          this.stores?.forEach((store: any) => {
            if (store?.storeId === id) {
              const today = new Date();
              store.statuses.currentStatusId = Status.Deleted;
              store.statuses.updatedAt = today.toISOString();
            }
          });
        });
      }
      this.resetCheckboxes(this.stores);
    },
    async confirmDeleteMulti() {
      let count = 0;
      let ids: any[] = [];

      this.singleSelects.forEach((item: boolean) => {
        if (item) {
          ids.push(this.stores[count].storeId);
        }
        count++;
      });

      let obj: any = {
        campaignId: this.campaignId,
        storeIds: ids,
        currentStatusId: Status.Deleted,
        route: this.urlInformation.route,
      };

      const response = await this.setRecruitmentCampaignReviewStoreStatus(obj);
      if (response?.status === 200) {
        ids?.forEach((id: any) => {
          this.stores?.forEach((store: any) => {
            if (store?.storeId === id) {
              const today = new Date();
              store.statuses.currentStatusId = Status.Deleted;
              store.statuses.updatedAt = today.toISOString();
            }
          });
        });
      }
      this.resetCheckboxes(this.stores);
    },
    openDeclinePrompt(storeIds: number[]) {
      this.selectedStoreIds = storeIds;
      this.openPrompt('decline');
    },
    openMultiDeclinePrompt() {
      this.selectedStoreIds = [];
      let count = 0;
      this.singleSelects.forEach((item: boolean) => {
        if (item) {
          this.selectedStoreIds.push(this.stores[count].storeId);
        }
        count++;
      });
      this.openPrompt('decline');
    },
    async openAcceptPrompt(storeIds: number[]) {
      this.selectedStoreIds = storeIds;
      await this.fetchMallsChannels({ storeIds: storeIds, recruitmentCampaignId: this.campaignId });
      if (this.urlInformation.contactId) {
        await this.fetchContactChannels({ contactId: this.urlInformation.contactId });
      }
      const campaignChannelIds = this.campaign?.channels;
      const contactChannelIds = this.contactChannels?.data?.map((el: any) => el.value);
      const mallsChannelIds = this.mallsChannels?.data?.map((el: any) => el.value);
      let filteredChannelIds = mallsChannelIds;
      if (campaignChannelIds) {
        filteredChannelIds = filteredChannelIds.filter((el: any) => campaignChannelIds.includes(el));
      }
      if (contactChannelIds) {
        filteredChannelIds = filteredChannelIds.filter((el: any) => contactChannelIds.includes(el));
      }
      this.filteredChannelOptions = this.mallsChannels?.data?.filter((el: any) => filteredChannelIds.includes(el.value));

      const store = this.stores.find((store: any) => store.storeId === storeIds[0]);
      if (store?.statuses?.statusId === Status.Approved || store?.statuses?.socialStatusId === Status.Approved) {
        let channels = store?.statuses?.channels ?? [];
        let lastArray = Array.isArray(channels) && channels.length > 0 ? channels[channels.length - 1] : [];
        this.selectedChannelIds = lastArray.map((el: any) => el.channelId);
      } else {
        this.selectedChannelIds = [];
      }

      this.openPrompt('accept');
    },
    async openMultiAcceptPrompt() {
      this.selectedStoreIds = [];
      let count = 0;
      this.singleSelects.forEach((item: boolean) => {
        if (item) {
          this.selectedStoreIds.push(this.stores[count].storeId);
        }
        count++;
      });
      await this.fetchMallsChannels({ storeIds: this.selectedStoreIds, recruitmentCampaignId: this.campaignId });
      if (this.urlInformation.contactId) {
        await this.fetchContactChannels({ contactId: this.urlInformation.contactId });
      }
      const campaignChannelIds = this.campaign?.channels;
      const contactChannelIds = this.contactChannels?.data?.map((el: any) => el.value);
      const mallsChannelIds = this.mallsChannels?.data?.map((el: any) => el.value);
      let filteredChannelIds = mallsChannelIds;
      if (campaignChannelIds) {
        filteredChannelIds = filteredChannelIds.filter((el: any) => campaignChannelIds.includes(el));
      }
      if (contactChannelIds) {
        filteredChannelIds = filteredChannelIds.filter((el: any) => contactChannelIds.includes(el));
      }
      this.filteredChannelOptions = this.mallsChannels?.data?.filter((el: any) => filteredChannelIds.includes(el.value));

      this.openPrompt('accept');
    },
    closeModalAndRefreshPage() {
      window.document.location.reload();
    },
    async decline() {
      this.declining = true;

      const sIds: any = [];
      this.selectedStoreIds?.forEach((id: number) => {
        this.stores?.forEach((store: any) => {
          if (store?.storeId === `${id}`) {
            if (store?.statuses?.statusId === Status.Pending) {
              sIds.push(store.storeId);
            }
          }
        });
      });

      let obj: any = {
        campaignId: this.campaignId,
        storeIds: sIds,
        reasonForDeclineId: 0,
        reasonForDeclineDescription: `${this.selectedReason}${this.reasonDescription ? ' - ' + this.reasonDescription : ''}`,
        route: this.urlInformation.route,
        statusId: Status.Declined,
      };

      const response = await this.setRecruitmentCampaignReviewStoreStatus(obj);
      if (response?.status === 200) {
        const today = new Date();
        sIds?.forEach((id: number) => {
          this.stores?.forEach((store: any) => {
            if (store?.storeId === `${id}`) {
              this.pending--;
              this.declined++;
              store.statuses.statusId = Status.Declined;
              store.statuses.updatedAt = today.toISOString();
              store.statuses.actionedDate = today.toISOString();
            }
          });
        });
      }
      this.declining = false;
      this.resetCheckboxes(this.stores);
      this.closePrompt();
    },
    toggleAllCheckboxes(): void {
      this.allChecked = !this.allChecked;
      let i = 0;
      this.checked.forEach(() => {
        if (this.stores[i].statuses.currentStatusId !== Status.Deleted) {
          this.checked[i] = this.allChecked;
          this.singleSelects[i] = this.allChecked;
        }
        i++;
      });
    },
    resetCheckboxes(data: any): void {
      this.checked = [];
      this.singleSelects = [];
      this.allChecked = false;
      data.forEach(() => {
        this.checked.push(false);
        this.singleSelects.push(false);
      });
      this.showMasterCheckbox = false;
      setTimeout(() => {
        this.showMasterCheckbox = true;
      }, 50);
    },
    checkboxClicked(index: any): void {
      this.singleSelects[index] = !this.singleSelects[index];
      this.checked[index] = !this.checked[index];

      this.allChecked = this.singleSelects.every((checkbox: boolean) => checkbox === true);
    },
    async downloadImg(type: string, language: ILanguage, image?: any): Promise<void> {
      if (image) {
        if (type === 'png') {
          const jimpImage = await Jimp.read(image?.url);
          const base64url = await jimpImage.getBase64Async('image/png');
          const a = document.createElement('a');
          a.href = base64url; //Image Base64 Goes here
          a.download = this.filterString(
            `${this.recruitmentCampaignReview?.recruitmentCampaign?.brand?.description} - Campaign #${
              this.recruitmentCampaignReview?.recruitmentCampaign?.campaignNumber
            } - ${this.recruitmentCampaignReview?.recruitmentCampaign?.description} - ${language?.isoCode?.toUpperCase()} - ${image?.width}x${
              image?.height
            }.${type}`,
          );
          a.click(); //Downloaded file
        } else if (type === 'gif') {
          const jimpImage = await Jimp.read(image?.url);
          const base64url = await jimpImage.getBase64Async('image/gif');
          const a = document.createElement('a');
          a.href = base64url; //Image Base64 Goes here
          a.download = this.filterString(
            `${this.recruitmentCampaignReview?.recruitmentCampaign?.brand?.description} - Campaign #${
              this.recruitmentCampaignReview?.recruitmentCampaign?.campaignNumber
            } - ${this.recruitmentCampaignReview?.recruitmentCampaign?.description} - ${language?.isoCode?.toUpperCase()} - ${image?.width}x${
              image?.height
            }.${type}`,
          );
          a.click(); //Downloaded file
        } else {
          const jimpImage = await Jimp.read(image?.url);
          const base64url = await jimpImage.getBase64Async('image/jpeg');
          const a = document.createElement('a');
          a.href = base64url; //Image Base64 Goes here
          a.download = this.filterString(
            `${this.recruitmentCampaignReview?.recruitmentCampaign?.brand?.description} - Campaign #${
              this.recruitmentCampaignReview?.recruitmentCampaign?.campaignNumber
            } - ${this.recruitmentCampaignReview?.recruitmentCampaign?.description} - ${language?.isoCode?.toUpperCase()} - ${image?.width}x${
              image?.height
            }.${type}`,
          );
          a.click(); //Downloaded file
        }
      } else {
        const zip = new JSZip();

        for (const [index, referencedImage] of this.filteredImages?.entries()) {
          const jimpImage = await Jimp.read(referencedImage?.url as string);
          const base64url = await jimpImage.getBase64Async(type === 'png' ? 'image/png' : type === 'gif' ? 'image/gif' : 'image/jpeg');
          const { height, width } = jimpImage.bitmap;

          zip.file(
            this.filterString(
              `${index} - ${this.recruitmentCampaignReview?.recruitmentCampaign?.brand?.description} - Campaign #${
                this.recruitmentCampaignReview?.recruitmentCampaign?.campaignNumber
              } - ${
                this.recruitmentCampaignReview?.recruitmentCampaign?.description
              } - ${language?.isoCode?.toUpperCase()} - ${width}x${height}.${type}`,
            ),
            base64url.replace(/data:.*?;base64,/, ''),
            { base64: true },
          );

          if (index === this.filteredImages.length - 1) {
            zip.generateAsync({ type: 'base64' }).then((content: any) => {
              const a = document.createElement('a');
              a.setAttribute('href', `data:application/zip;base64,${content}`);
              a.setAttribute('download', 'images.zip');
              a.click();
            });
          }
        }
      }
    },
    languageParser(value: string) {
      const parsedData: any = [];
      const languages = value?.split('::: ');
      languages?.forEach((language) => {
        parsedData.push({
          [language.substring(0, language.indexOf('='))]: language.substring(language.indexOf('=') + 1, language.length),
        });
      });
      return parsedData;
    },
    filterImages(images: any[]): void {
      this.filteredImages = this.uniqueImages(this.requirements, this.languageSpecificImages(this.activeLanguage, images));
      this.filteredLanguages?.forEach((language: any) => {
        this.filteredImagesV2[language?.isoCode] = this.uniqueImages(this.requirements, this.languageSpecificImages(language, images));
      });
    },
    uniqueImages(requirements: string[], imgs: any[]) {
      return imgs.filter((el: any) => {
        const size = `${el.width}x${el.height}`;

        if (this.requirements.includes(size) && el?.generated === 1) {
          return el;
        }
      });
    },
    languageSpecificImages(language: ILanguage, imgs: any[]) {
      return imgs.filter((i: any) => i?.language === language?.isoCode || i?.languageId === language?.languageId);
    },
    languageHasContent(language: ILanguage): boolean {
      let languageHasContent = true;
      let headlineValue = this.getLanguageSpecificValue(this.languageParser(this.campaign?.headline), language?.isoCode);
      let descriptionValue = this.getLanguageSpecificValue(this.languageParser(this.campaign?.longDescription), language?.isoCode);
      let twitterValue = this.getLanguageSpecificValue(this.languageParser(this.campaign?.twitterDescription), language?.isoCode);
      let instagramValue = this.getLanguageSpecificValue(this.languageParser(this.campaign?.instagramDescription), language?.isoCode);
      let facebookValue = this.getLanguageSpecificValue(this.languageParser(this.campaign?.facebookDescription), language?.isoCode);
      if (headlineValue === '' && descriptionValue === '' && twitterValue === '' && instagramValue === '' && facebookValue === '') {
        languageHasContent = false;
      }
      return languageHasContent;
    },
    getLanguageSpecificValue(item: any, isoCode: string) {
      let value: any = '';
      item.forEach((i: any) => {
        if (i[isoCode]) {
          value = i[isoCode];
        }
      });
      return value;
    },
    languageSelected(value: ILanguage) {
      this.activeLanguage = value;
    },
    languageButtonStyle(language: ILanguage) {
      let style = '';
      if (language === this.activeLanguage) {
        style = 'color: hsl(0, 0%, 100%); background-color: hsl(138, 50%, 61%);';
      } else {
        style = 'color: hsl(0, 0%, 44%)';
      }
      return style;
    },
    extractDataFromLocations(locations: IStore[]) {
      if (locations?.length > 0) {
        const languageExtraction: any = [...new Set(locations.map((el: IStore) => el?.mall?.languages))];
        const mergedLanguages: ILanguage[] = [].concat.apply([], languageExtraction);
        const uniqueLanguages = [];
        const map = new Map();
        for (const item of mergedLanguages) {
          if (!map.has(item.isoCode)) {
            map.set(item.isoCode, true);
            uniqueLanguages.push(item);
          }
        }
        this.languages = uniqueLanguages.sort((a, b) => (a.languageId > b.languageId && 1) || -1);
        const mallRequirements: any = locations.map((el: IStore) => el.mall?.requirements);
        const mergedRequirements = [].concat.apply([], mallRequirements).map((el: any) => `${el.width}x${el.height}`);
        const uniqueMallSizes = [...new Set(mergedRequirements)];

        this.requirements = uniqueMallSizes;
      } else {
        this.languages = [
          {
            languageId: 37,
            description: 'English',
            isoCode: 'en',
            updatedAt: '',
            createdAt: '',
          },
        ];
        this.activeLanguage = {
          languageId: 37,
          description: 'English',
          isoCode: 'en',
          updatedAt: '',
          createdAt: '',
        };
      }
    },
    copy(value: string) {
      navigator.clipboard.writeText(value);
      this.tooltipText = 'copied';
      setTimeout(() => {
        this.tooltipText = 'click to copy';
      }, 1000);
    },
    declineReason() {
      switch (this.selectedReason) {
        case 'Incorrect Details':
          this.reasonMessage = 'Please help us understand what information is incorrect or missing.';
          break;
        case "Store Doesn't Exist":
          this.reasonMessage = '';
          break;
        case 'Other':
          this.reasonMessage = 'Please help us understand by providing more details.';
          break;
      }
    },
    campaignActive(): boolean {
      return ![Status.Expired, Status.Deleted, Status['Manually Expired']].includes(this.response?.recruitmentCampaign?.statusId);
    },
    formatParsedChannel(channel: any): string {
      return `${channel.contactName ? `${channel.contactName} approved` : 'Approved'} for ${channel.channelDescription}${
        channel.createdAt ? ` on ${this.formatDateLocalized(channel.createdAt, true)}` : ''
      }`;
    },
  },
});
