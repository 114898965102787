export enum ReminderType {
  'Campaign Created' = 1,
  'NEW Email' = 2,
  'Reminder NEW Email' = 3,
  'Change Email' = 4,
  'REMINDER Change Email' = 5,
  'REMOVE Email' = 6,
  'REMINDER REMOVE EMAIL' = 7,
  'Mid-Campaign Report' = 8,
  'End-Campaign Report' = 9,
  'DECLINE Email' = 10,
  '48 Hour Report' = 11,
  'Change Report' = 12,
  'Expire Email' = 13,
  'New Mall Added' = 14,
  'Mall Contact Change' = 15,
  'Store Contact Change' = 16,
  'Mall Removed' = 17,
  'Draft Reminder' = 18,
  'New Store Added' = 19,
  'Approval Required' = 20,
  'Approval Required Reminder' = 21,
  'Channel Compliance' = 22,
  'Approval Declined' = 23,
  'Brand Onboarding' = 24,
  'Follow Up' = 25,
  'New Contact Group' = 26,
  'Update Contact Group' = 27,
  'Mall Added Communication Type' = 28,
  'Mall Removed Communication Type' = 29,
  'Email Communication' = 30,
  'Campaign Channels Added' = 31,
  'Mall Image Resize' = 32,
  'Extended Email' = 33,
  'Extended Reminder Email' = 34,
  'Updated Email' = 35,
}
