
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { IBrand, ILanguage, IStore } from '../../../../../../interfaces/src/v2';
import Button from '../../../atomic/atoms/Button/Button.vue';
import Loader from '../../../atomic/atoms/Loader/Loader.vue';
import Tooltip from '../../../atomic/atoms/Tooltip/Tooltip.vue';
import { dateFormatter, dateFormatterLocalized } from '../../../utilities/Functions/formatting';
import Modal from '../../../atomic/atoms/Modal/Modal.vue';
import TextArea from '../../../atomic/atoms/Text-Area/Text-Area.vue';
import Checkbox from '../../../atomic/atoms/Checkbox/Checkbox.vue';
import Chip from '../../../atomic/atoms/Chip/Chip.vue';
import AttachmentDownloader from '../../../atomic/atoms/Attachment-Downloader/Attachment-Downloader.vue';
import { SocketUtility } from '../../../utilities/Sockets/SocketUtility';
import SocketService from '../../../services/SocketService';
import { SocketEvent } from '../../../enums/SocketEvent';
import { Status } from '@/enums/Status';

export default defineComponent({
  components: {
    'arc-button': Button,
    'arc-loader': Loader,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
    'arc-text-area': TextArea,
    'arc-checkbox': Checkbox,
    'arc-chip': Chip,
    'arc-attachment': AttachmentDownloader,
  },
  async mounted() {
    const customUrl = new Map<string, string>();
    const url = window.location.href;
    let requestId: string | null = null;

    if (new URL(url).pathname.includes('/r/sm/')) {
      const urlToken = new URL(url).pathname.split('/').pop();
      if (urlToken) {
        requestId = urlToken;
      }
    }

    if (requestId) {
      const urlInformation = await this.fetchReviewPageInformationViaPublicId(requestId);

      if (!urlInformation.valid) {
        this.urlInformation = {
          memoId: '',
          storeId: '',
          contactId: '',
          contacts: '',
          showChanges: false,
          valid: false,
          route: '',
        };

        this.loading = false;
        return;
      }

      const queryParameters = urlInformation.data.queryParams;

      for (const query of queryParameters.replace('?', '').split('&')) {
        const [key, value] = query.split('=');
        customUrl.set(key, value);
      }

      const url = new URL(urlInformation.data.originalUrl);

      this.urlInformation = {
        memoId: customUrl.get('memoId') ?? '',
        storeId: customUrl.get('storeId') ?? '',
        contactId: customUrl.get('contactId') ?? '',
        contacts: customUrl.get('contacts') ?? '',
        showChanges: customUrl.get('showChanges') === 'true',
        valid: urlInformation.valid,
        route: `${url.pathname}${url.search}`,
      };
    } else {
      this.urlInformation = {
        memoId: `${this.$route.query.memoId}`,
        storeId: !!this.$route.query.storeId && this.$route.query.storeId !== 'undefined' ? `${this.$route.query.storeId}` : '',
        contactId: !!this.$route.query.contactId && this.$route.query.contactId !== 'undefined' ? `${this.$route.query.contactId}` : '',
        contacts: !!this.$route.query.contacts && this.$route.query.contacts !== 'undefined' ? `${this.$route.query.contacts}` : '',
        showChanges: this.$route.query.showChanges === 'true',
        valid: true,
        route: this.$route.fullPath,
      };
    }

    if (!SocketUtility.getMemosSocket()) {
      SocketUtility.setMemosSocket(new SocketService().memosSocket);
    }

    SocketUtility.getMemosSocket().on(SocketEvent.STORE_MEMO_UPDATED, (memoId: string) => {
      if (memoId === this.urlInformation.memoId) {
        this.showChangeWarning = true;
      }
    });

    this.memo = await this.fetchStoreMemoReview({
      memoId: this.urlInformation.memoId,
      type: this.urlInformation.storeId ? 'store' : 'contact',
      id: this.urlInformation.storeId || this.urlInformation.contactId,
    });

    this.response = this.memo?.data;
    this.fetchDeclineReasons();
  },
  watch: {
    storeMemoReview: {
      deep: true,
      handler() {
        this.extractDataFromLocations(this.storeMemoReview?.data?.storeMemo?.stores);
        this.campaign = this.storeMemoReview?.data?.storeMemo;
        if (this.campaign?.questions?.length > 0) {
          this.campaign?.questions?.forEach((question: any) => {
            if (question?.options?.length > 0) {
              question.options = question?.options?.sort((a: any, b: any) => a?.memoQuestionOptionId - b?.memoQuestionOptionId);
            }
          });
        }
        if (this.campaign?.stores && this.campaign?.stores?.length > 0 && this.initialLoad) {
          this.campaign.stores = this.campaign?.stores?.sort((a: any, b: any) => {
            // Removed stores go first
            if (a?.statuses?.currentStatusId === Status.Modified) {
              return -1;
            }
            if (b?.statuses?.currentStatusId === Status.Modified) {
              return 1;
            }
            // Deleted stores go last
            if (a?.statuses?.currentStatusId === Status.Deleted) {
              return 1;
            }
            if (b?.statuses?.currentStatusId === Status.Deleted) {
              return -1;
            }
            // Order middle by status if both active
            return b?.statuses?.statusId - a?.statuses?.statusId;
          });
          this.initialLoad = false;
        }
      },
    },
    loadingstoreMemoReview: {
      handler() {
        this.loading = this.loadingStoreMemoReview;
      },
    },
    stores: {
      handler() {
        this.total = this.stores?.length;
        this.approved = 0;
        this.pending = 0;
        this.declined = 0;
        let count = 0;
        this.stores?.forEach((store: any) => {
          if (store?.statuses?.statusId === Status.Approved) {
            this.approved++;
          } else if (store?.statuses?.statusId === Status.Declined) {
            this.declined++;
          } else {
            this.pending++;
          }
          this.checked[count] = false;
          this.singleSelects[count] = false;
          count++;
        });
      },
    },
  },
  computed: {
    Status() {
      return Status;
    },
    ...mapGetters(['modalData', 'storeMemoReview', 'loadingStoreMemoReview', 'loadingStoreStatus', 'declineReasons']),
    mailto(): string {
      return `mailto:support@engagementagents.com?subject=Request%20Contact%20Change&body=I%20would%20like%20to%20request%20the%20following%20contact%20changes:`;
    },
    fromStyle(): string {
      return this.urlInformation.storeId ? 'width: 100%;' : '';
    },
    selected(): boolean {
      const selected = this.singleSelects.filter((item) => item === true);
      return selected.length > 0;
    },
    filteredLanguages() {
      const filteredLanguages: any[] = [];
      this.languages?.forEach((language: ILanguage) => {
        if (this.languageHasContent(language)) {
          filteredLanguages.push(language);
        }
      });
      this.languageSelected(filteredLanguages[0]);
      return filteredLanguages;
    },
    contacts(): any[] {
      let array: any[] = [];
      if (this.storeMemoReview?.data?.fromEmail) {
        array.push({
          ...this.storeMemoReview?.data?.contactInformation,
        });
      }
      return array;
    },
    stores(): any[] {
      return this.campaign?.stores;
    },
    headline(): string {
      return this.getLanguageSpecificValue(this.languageParser(this.campaign?.headline), this.activeLanguage?.isoCode);
    },
    description(): string {
      return this.getLanguageSpecificValue(this.languageParser(this.campaign?.longDescription), this.activeLanguage?.isoCode);
    },
    brand(): IBrand {
      return this.campaign?.brand;
    },
    toList(): string {
      const contacts = `${this.urlInformation.contacts}`;
      const array = contacts.split(', ');
      return array[0];
    },
    ccList(): string {
      let cc = 'Click below to add one or more CC contacts';
      const contacts = `${this.urlInformation.contacts}`;
      const array = contacts.split(',');
      if (array?.length > 1) {
        cc = '';
        array?.shift();
        array?.forEach((contact: string) => {
          cc += contact.replace(' ', ', ');
        });
      }
      return cc === 'Click below to add one or more CC contacts' ? cc : cc.slice(2, cc.length);
    },
    reasons(): any[] {
      return this.declineReasons?.data;
    },
    detailContainerStyle(): string {
      let style = '';
      if (!this.campaign || (!this.headline && !this.description)) {
        style = 'border: none;';
      }
      return style;
    },
  },

  data(): {
    loading: boolean;
    total: number;
    approved: number;
    pending: number;
    declined: number;
    campaign: any;
    languages: ILanguage[];
    activeLanguage: ILanguage;
    declining: boolean;
    promptOpen: boolean;
    promptType: string;
    selectedStoreIds: number[];
    selectedReason: string;
    reasonDescription: string;
    checked: boolean[];
    allChecked: boolean;
    singleSelects: boolean[];
    showMasterCheckbox: boolean;
    tooltipText: string;
    memo: any;
    questionObject: any;
    submitting: boolean;
    response: any;
    initialLoad: boolean;
    hadApproved: boolean;
    reasonMessage: string;
    showChangeWarning: boolean;
    questions: any[];
    urlInformation: {
      memoId: string;
      storeId: string;
      contactId: string;
      contacts: string;
      showChanges: boolean;
      valid: boolean | undefined;
      route: string;
    };
  } {
    return {
      showChangeWarning: false,
      loading: true,
      total: 0,
      approved: 0,
      pending: 0,
      declined: 0,
      campaign: null,
      languages: [
        {
          languageId: 37,
          description: 'English',
          isoCode: 'en',
          updatedAt: '',
          createdAt: '',
        },
        {
          languageId: 47,
          description: 'French',
          isoCode: 'fr',
          updatedAt: '',
          createdAt: '',
        },
      ],
      activeLanguage: {
        languageId: 37,
        description: 'English',
        isoCode: 'en',
        updatedAt: '',
        createdAt: '',
      },
      declining: false,
      promptOpen: false,
      promptType: '',
      selectedStoreIds: [],
      selectedReason: '',
      reasonDescription: '',
      checked: [],
      allChecked: false,
      singleSelects: [],
      showMasterCheckbox: true,
      tooltipText: 'click to copy',
      memo: null,
      questionObject: null,
      submitting: false,
      response: null,
      initialLoad: true,
      hadApproved: false,
      reasonMessage: '',
      questions: [],
      urlInformation: {
        memoId: '',
        storeId: '',
        contactId: '',
        contacts: '',
        showChanges: false,
        valid: true,
        route: '',
      },
    };
  },

  methods: {
    ...mapActions([
      'fetchStoreMemoReview',
      'setStoreMemoReviewStoreStatus',
      'openModal',
      'updateModalType',
      'updateModalComponent',
      'updateModalData',
      'fetchDeclineReasons',
      'fetchReviewPageInformationViaPublicId',
    ]),
    showChanges(): boolean {
      return this.urlInformation.showChanges;
    },
    hasChanges() {
      const hasLanguageChange = this.response?.changes?.some((item: any) => {
        if (item?.previousValue?.includes(':::') || item?.previousValue.includes('en=') || item?.previousValue.includes('fr=')) {
          this.filteredLanguages?.forEach((language: any) => {
            if (this.getValueByField(item?.currentValue, language) !== this.getValueByField(item?.previousValue, language)) {
              return item;
            }
          });
        }
      });

      const validChangedFields = this.response?.changes
        ?.map((el: any) => el.field)
        .filter((el: any) => !['headline', 'longDescription'].includes(el));

      return hasLanguageChange || validChangedFields;
    },
    hasStoreNonPending() {
      return this.stores?.some((store: any) => store?.statuses?.statusId !== Status.Pending);
    },
    hasStoreApproved() {
      if (this.initialLoad) {
        this.hadApproved = this.stores?.some((store: any) => store?.statuses?.statusId === Status.Approved);
      }
      return this.stores?.some((store: any) => store?.statuses?.statusId === Status.Approved);
    },
    checkedSameCurrentStatusId(): number | null {
      let checkedSameCurrentStatusId = true;
      let i = 0;
      let currentStatusId: any = undefined;
      this.checked.forEach(() => {
        if (this.checked[i] === true) {
          if (!currentStatusId) {
            currentStatusId = this.stores[i].statuses.currentStatusId;
          } else {
            if (this.stores[i].statuses.currentStatusId !== currentStatusId) {
              checkedSameCurrentStatusId = false;
            }
          }
        }
        i++;
      });
      if (checkedSameCurrentStatusId) {
        return currentStatusId;
      }
      return 0;
    },
    capitalize(value: string): string {
      return value[0].toUpperCase() + value.substring(1);
    },
    getValueByField(field: string, language: any): string {
      return this.getLanguageSpecificValue(this.languageParser(field), language?.isoCode);
    },
    getFieldDescription(field: string): string {
      let description = field;
      if (field === 'description') {
        description = 'Campaign Name';
      }
      if (field === 'longDescription') {
        description = 'Description';
      }
      if (field === 'fromDate') {
        description = 'Start Date';
      }
      if (field === 'toDate') {
        description = 'End Date';
      }
      if (field === 'publishByDate') {
        description = 'Post no later than';
      }
      if (field === 'notes') {
        description = 'Notes to mall';
      }
      if (field === 'facebookDescription') {
        description = 'Facebook';
      }
      if (field === 'instagramDescription') {
        description = 'Instagram';
      }
      if (field === 'twitterDescription') {
        description = 'twitter';
      }
      return this.capitalize(description);
    },
    getHeadline(language: any): string {
      return this.getLanguageSpecificValue(this.languageParser(this.campaign?.headline), language?.isoCode);
    },
    getDescription(language: any): string {
      return this.getLanguageSpecificValue(this.languageParser(this.campaign?.longDescription), language?.isoCode);
    },
    closePrompt(): void {
      this.promptOpen = false;
      this.selectedStoreIds = [];
      this.selectedReason = '';
      this.reasonDescription = '';
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
      this.selectedReason = '';
      this.reasonDescription = '';
    },
    approveStatusStyle(statusId: number): string {
      let style = '';
      if (statusId === Status.Approved) {
        style = 'background-color: #a7a7a7; border-color: #a7a7a7; cursor: default;';
      }
      return style;
    },
    declineStatusStyle(statusId: number): string {
      let style = '';
      if (statusId === Status.Declined) {
        style = 'background-color: #a7a7a7; border-color: #a7a7a7; cursor: default';
      }
      return style;
    },
    formatDate(date: string, showTime = false): string {
      return dateFormatter(date, showTime);
    },
    formatDateLocalized(date: string, showTime = false): string {
      return dateFormatterLocalized(date);
    },
    async downloadAttachment(attachment: any) {
      const response = await fetch(attachment.url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        cache: 'no-store',
      });

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.download = this.filterString(attachment.name);
      link.setAttribute('crossorigin', 'anonymous');
      link.setAttribute('target', '_blank');

      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    filterString(value: string): string {
      return value.replace(/[%&#+=(){}|:;"'<,>?/\\~`@$^*[\]]/g, '');
    },
    async submitQuestions(): Promise<void> {
      let allQuestionsAnswered = true;
      this.campaign?.questions?.forEach((question: any) => {
        if (question?.answer === '') {
          allQuestionsAnswered = false;
        }
      });
      if (allQuestionsAnswered) {
        this.submitting = true;
        this.questionObject.answers = this.campaign?.questions;
        this.questionObject.route = this.$route.fullPath;
        const response = await this.setStoreMemoReviewStoreStatus(this.questionObject);
        if (response?.status === 200) {
          this.questionObject?.storeIds?.forEach((id: string) => {
            this.stores?.forEach((store: any) => {
              if (store?.storeId === id) {
                const today = new Date();
                store.statuses.statusId = Status.Approved;
                store.statuses.updatedAt = today.toISOString();
                store.statuses.actionedDate = today.toISOString();
              }
            });
          });
        }
        this.questionObject = null;
        this.submitting = false;
        this.closePrompt();
        this.resetCheckboxes(this.stores);
      } else {
        this.$notify({
          title: 'WARNING',
          text: 'Please answer all questions to submit',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async approve(storeIds: string[], currentStatus: number) {
      if (currentStatus === Status.Declined) {
        this.declined--;
        this.approved++;
      }
      if (currentStatus === Status.Pending) {
        this.pending--;
        this.approved++;
      }
      let obj: any = {
        memoId: this.campaign?.memoId,
        storeIds: storeIds,
        reasonForDeclineDescription: '',
        statusId: Status.Approved,
        route: this.urlInformation.route,
      };
      if (this.campaign?.questions && this.campaign?.questions?.length > 0) {
        obj.answers = [];
        this.campaign?.questions?.forEach((question: any) => {
          question.answer = '';
          question.answers = [];
          question.answers[0] = {
            memoQuestionId: question?.memoQuestionId,
            description: '',
            userId: '0',
          };
        });
        this.questionObject = obj;
        this.openPrompt('question');
      } else {
        const response = await this.setStoreMemoReviewStoreStatus(obj);
        if (response?.status === 200) {
          storeIds?.forEach((id: string) => {
            this.stores?.forEach((store: any) => {
              if (store?.storeId === id) {
                const today = new Date();
                store.statuses.statusId = Status.Approved;
                store.statuses.updatedAt = today.toISOString();
                store.statuses.actionedDate = today.toISOString();
              }
            });
          });
        }
        this.resetCheckboxes(this.stores);
      }
    },
    async approveMulti() {
      let count = 0;
      let ids: any[] = [];
      this.singleSelects.forEach((item: boolean) => {
        if (item === true) {
          ids.push(this.stores[count].storeId);
          if (this.stores[count]?.statuses?.statusId === Status.Declined) {
            this.declined--;
            this.approved++;
          }
          if (this.stores[count]?.statuses?.statusId === Status.Pending) {
            this.pending--;
            this.approved++;
          }
        }
        count++;
      });
      let obj: any = {
        memoId: this.campaign?.memoId,
        storeIds: ids,
        reasonForDeclineDescription: '',
        statusId: Status.Approved,
        route: this.urlInformation.route,
      };
      if (this.campaign?.questions && this.campaign?.questions?.length > 0) {
        obj.answers = [];
        this.campaign?.questions?.forEach((question: any) => {
          question.answer = '';
          question.answers = [];
          question.answers[0] = {
            memoQuestionId: question?.memoQuestionId,
            description: '',
            userId: '0',
          };
        });
        this.questionObject = obj;
        this.openPrompt('question');
      } else {
        const response = await this.setStoreMemoReviewStoreStatus(obj);
        if (response?.status === 200) {
          ids?.forEach((id: string) => {
            this.stores?.forEach((store: any) => {
              if (store?.storeId === id) {
                const today = new Date();
                store.statuses.statusId = Status.Approved;
                store.statuses.updatedAt = today.toISOString();
                store.statuses.actionedDate = today.toISOString();
              }
            });
          });
        }
        this.resetCheckboxes(this.stores);
      }
    },
    reset(storeIds: number[]) {
      let obj: any = {
        memoId: this.campaign?.memoId,
        storeIds: storeIds,
        reasonForDeclineDescription: '',
        statusId: Status.Pending,
        route: this.urlInformation.route,
      };
      this.setStoreMemoReviewStoreStatus(obj);
      this.resetCheckboxes(this.stores);
    },
    async resetMulti() {
      let count = 0;
      let ids: any[] = [];
      this.singleSelects.forEach((item: boolean) => {
        if (item === true) {
          ids.push(this.stores[count].storeId);
        }
        count++;
      });
      let obj: any = {
        memoId: this.campaign?.memoId,
        storeIds: ids,
        reasonForDeclineDescription: '',
        statusId: Status.Pending,
        route: this.urlInformation.route,
      };
      await this.setStoreMemoReviewStoreStatus(obj);
      this.resetCheckboxes(this.stores);
    },
    async confirmDelete(storeIds: number[]): Promise<void> {
      let obj: any = {
        memoId: this.campaign?.memoId,
        storeIds: storeIds,
        currentStatusId: Status.Deleted,
        route: this.urlInformation.route,
      };

      if (this.campaign?.questions && this.campaign?.questions?.length > 0) {
        obj.answers = [];
        this.campaign?.questions?.forEach((question: any) => {
          question.answer = '';
          question.answers = [];
          question.answers[0] = {
            memoQuestionId: question?.memoQuestionId,
            description: '',
            userId: '0',
          };
        });
        this.questionObject = obj;
        this.openPrompt('question');
      } else {
        const response = await this.setStoreMemoReviewStoreStatus(obj);
        if (response?.status === 200) {
          storeIds?.forEach((id: any) => {
            this.stores?.forEach((store: any) => {
              if (store?.storeId === id) {
                const today = new Date();
                store.statuses.currentStatusId = Status.Deleted;
                store.statuses.updatedAt = today.toISOString();
              }
            });
          });
        }
        this.resetCheckboxes(this.stores);
      }
    },
    async confirmDeleteMulti() {
      let count = 0;
      let ids: any[] = [];

      this.singleSelects.forEach((item: boolean) => {
        if (item) {
          ids.push(this.stores[count].storeId);
        }
        count++;
      });

      let obj: any = {
        memoId: this.campaign?.memoId,
        storeIds: ids,
        currentStatusId: Status.Deleted,
        route: this.urlInformation.route,
      };

      if (this.campaign?.questions && this.campaign?.questions?.length > 0) {
        obj.answers = [];
        this.campaign?.questions?.forEach((question: any) => {
          question.answer = '';
          question.answers = [];
          question.answers[0] = {
            memoQuestionId: question?.memoQuestionId,
            description: '',
            userId: '0',
          };
        });
        this.questionObject = obj;
        this.openPrompt('question');
      } else {
        const response = await this.setStoreMemoReviewStoreStatus(obj);
        if (response?.status === 200) {
          ids?.forEach((id: any) => {
            this.stores?.forEach((store: any) => {
              if (store?.storeId === id) {
                const today = new Date();
                store.statuses.currentStatusId = Status.Deleted;
                store.statuses.updatedAt = today.toISOString();
              }
            });
          });
        }
        this.resetCheckboxes(this.stores);
      }
    },
    openDeclinePrompt(storeIds: number[]) {
      this.selectedStoreIds = storeIds;
      this.openPrompt('decline');
    },
    openMultiDeclinePrompt() {
      this.selectedStoreIds = [];
      let count = 0;
      this.singleSelects.forEach((item: boolean) => {
        if (item === true) {
          this.selectedStoreIds.push(this.stores[count].storeId);
        }
        count++;
      });
      this.openPrompt('decline');
    },
    closeModalAndRefreshPage() {
      window.document.location.reload();
    },
    async decline() {
      this.declining = true;

      const sIds: any = [];
      this.selectedStoreIds?.forEach((id: number) => {
        this.stores?.forEach((store: any) => {
          if (store?.storeId === `${id}`) {
            if (store?.statuses?.statusId === Status.Pending) {
              sIds.push(store.storeId);
            }
          }
        });
      });

      let obj: any = {
        memoId: this.campaign?.memoId,
        storeIds: sIds,
        reasonForDeclineId: 0,
        reasonForDeclineDescription: `${this.selectedReason}${this.reasonDescription ? ' - ' + this.reasonDescription : ''}`,
        statusId: Status.Declined,
        route: this.urlInformation.route,
      };
      const response = await this.setStoreMemoReviewStoreStatus(obj);
      if (response?.status === 200) {
        const today = new Date();
        sIds?.forEach((id: number) => {
          this.stores?.forEach((store: any) => {
            if (store?.storeId === `${id}`) {
              this.pending--;
              this.declined++;
              store.statuses.statusId = Status.Declined;
              store.statuses.updatedAt = today.toISOString();
              store.statuses.actionedDate = today.toISOString();
            }
          });
        });
      }
      this.declining = false;
      this.resetCheckboxes(this.stores);
      this.closePrompt();
    },
    toggleAllCheckboxes(): void {
      this.allChecked = !this.allChecked;
      let i = 0;
      this.checked.forEach(() => {
        if (this.stores[i].statuses.currentStatusId !== Status.Deleted) {
          this.checked[i] = this.allChecked;
          this.singleSelects[i] = this.allChecked;
        }
        i++;
      });
    },
    resetCheckboxes(data: any): void {
      this.checked = [];
      this.singleSelects = [];
      this.allChecked = false;
      data.forEach(() => {
        this.checked.push(false);
        this.singleSelects.push(false);
      });
      this.showMasterCheckbox = false;
      setTimeout(() => {
        this.showMasterCheckbox = true;
      }, 50);
    },
    checkboxClicked(index: any): void {
      this.singleSelects[index] = !this.singleSelects[index];
      this.checked[index] = !this.checked[index];

      this.allChecked = this.singleSelects.every((checkbox: boolean) => checkbox === true);
    },
    languageParser(value: string) {
      const parsedData: any = [];
      const languages = value?.split('::: ');
      languages?.forEach((language) => {
        parsedData.push({
          [language.substring(0, language.indexOf('='))]: language.substring(language.indexOf('=') + 1, language.length),
        });
      });
      return parsedData;
    },
    languageHasContent(language: ILanguage): boolean {
      let languageHasContent = true;
      let headlineValue = this.getLanguageSpecificValue(this.languageParser(this.memo?.data?.storeMemo?.headline), language?.isoCode);
      let descriptionValue = this.getLanguageSpecificValue(this.languageParser(this.memo?.data?.storeMemo?.longDescription), language?.isoCode);
      if (headlineValue === '' && descriptionValue === '') {
        languageHasContent = false;
      }
      return languageHasContent;
    },
    getLanguageSpecificValue(item: any, isoCode: string) {
      let value: any = '';
      item?.forEach((i: any) => {
        if (i[isoCode]) {
          value = i[isoCode];
        }
      });
      return value;
    },
    languageSelected(value: ILanguage) {
      this.activeLanguage = value;
    },
    languageButtonStyle(language: ILanguage) {
      let style = '';
      if (language === this.activeLanguage) {
        style = 'color: hsl(0, 0%, 100%); background-color: hsl(138, 50%, 61%);';
      } else {
        style = 'color: hsl(0, 0%, 44%)';
      }
      return style;
    },
    extractDataFromLocations(locations: IStore[]) {
      if (locations?.length > 0) {
        const languageExtraction: any = [...new Set(locations.map((el: IStore) => el?.mall?.languages))];
        const mergedLanguages: ILanguage[] = [].concat.apply([], languageExtraction);
        const uniqueLanguages = [];
        const map = new Map();
        for (const item of mergedLanguages) {
          if (!map.has(item.isoCode)) {
            map.set(item.isoCode, true);
            uniqueLanguages.push(item);
          }
        }
        this.languages = uniqueLanguages.sort((a, b) => (a.languageId > b.languageId && 1) || -1);
      } else {
        this.languages = [
          {
            languageId: 37,
            description: 'English',
            isoCode: 'en',
            updatedAt: '',
            createdAt: '',
          },
        ];
        this.activeLanguage = {
          languageId: 37,
          description: 'English',
          isoCode: 'en',
          updatedAt: '',
          createdAt: '',
        };
      }
    },
    copy(value: string) {
      navigator.clipboard.writeText(value);
      this.tooltipText = 'copied';
      setTimeout(() => {
        this.tooltipText = 'click to copy';
      }, 1000);
    },
    declineReason() {
      switch (this.selectedReason) {
        case 'Incorrect Details':
          this.reasonMessage = 'Please help us understand what information is incorrect or missing.';
          break;
        case "Store Doesn't Exist":
          this.reasonMessage = '';
          break;
        case 'Other':
          this.reasonMessage = 'Please help us understand by providing more details.';
          break;
      }
    },
    campaignActive(): boolean {
      return ![Status.Expired, Status.Deleted, Status['Manually Expired']].includes(this.response?.storeMemo?.statusId);
    },
  },
});
